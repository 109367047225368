import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

interface DateRangeFilterProps {
  dateRange: { startDate: string; endDate: string };
  setDateRange: (selectedDates: { startDate: string; endDate: string }) => void;
}
export function DateRangeFilter({
  dateRange,
  setDateRange,
}: DateRangeFilterProps) {
  const [startDateError, setStartDateError] = useState<string>();
  const [endDateError, setEndDateError] = useState<string>();

  function handleStartDateChange(startDate: string) {
    const firstChar = startDate.charAt(0);
    if (Number(firstChar) > 0) {
      if (new Date(startDate) <= new Date(dateRange.endDate)) {
        setDateRange({
          ...dateRange,
          startDate,
        });
        setEndDateError(undefined);
      } else {
        setStartDateError('Start date must be before end date');
      }
    }
  }

  function handleEndDateChange(endDate: string) {
    const firstChar = endDate.charAt(0);

    if (Number(firstChar) > 0) {
      if (new Date(endDate) >= new Date(dateRange.startDate)) {
        setDateRange({
          ...dateRange,
          endDate,
        });
        setEndDateError(undefined);
      } else {
        setEndDateError('End date must be after start date');
      }
    }
  }

  return (
    <div className="flex flex-col px-4">
      <div className="flex flex-col gap-1">
        <label htmlFor="startDate-input" className="ml-2">
          Start Date
        </label>
        <InputText
          type="date"
          name="startDate"
          defaultValue={dateRange.startDate}
          onChange={(e) => handleStartDateChange(e.currentTarget.value)}
        />
        <div className="mx-2 mb-1 flex h-4 w-full items-baseline justify-end pr-4">
          {startDateError && (
            <small className="p-error p-d-block">{startDateError}</small>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <label htmlFor="startDate-input" className="ml-2">
          End Date
        </label>
        <InputText
          type="date"
          name="startDate"
          defaultValue={dateRange.endDate}
          onChange={(e) => handleEndDateChange(e.currentTarget.value)}
        />
        <div className="mx-2 mb-1 flex h-4 w-full items-baseline justify-end pr-4">
          {endDateError && (
            <small className="p-error p-d-block">{endDateError}</small>
          )}
        </div>
      </div>
    </div>
  );
}

import {
  getDay,
  differenceInWeeks,
  differenceInMinutes,
  differenceInMonths,
  addWeeks,
  addDays,
  startOfDay,
  differenceInDays,
  addMonths,
  setDate,
  setDay,
  isSameDay,
} from 'date-fns';
import { RoutineWithDueDate } from '../../types/shared-types';

export function dateTimeIsPastInDay(dateInput: string | Date): boolean {
  const inputTime = getDayMinutesElapsed(dateInput);
  const currentTime = getDayMinutesElapsed(new Date());
  return currentTime > inputTime;
}

export function getDayMinutesElapsed(dateInput: string | Date): number {
  return getMinutesElapsedData(dateInput).days;
}

export function getWeekMinutesElapsed(dateInput: string | Date) {
  return getMinutesElapsedData(dateInput).weeks;
}

export function getMonthMinutesElapsed(dateInput: string | Date) {
  return getMinutesElapsedData(dateInput).months;
}

export function getMinutesElapsedData(dateInput: string | Date) {
  const targetDate = new Date(dateInput);
  const startOfTargetDate = startOfDay(targetDate);
  const today = new Date();
  const startOfToday = startOfDay(today);

  const daysElapsed = differenceInDays(startOfToday, startOfTargetDate);
  const weeksElapsed = differenceInWeeks(startOfToday, startOfTargetDate);
  const monthsElapsed = differenceInMonths(startOfToday, startOfTargetDate);

  const loopedDay = addDays(targetDate, daysElapsed);
  const loopedWeek = addWeeks(targetDate, weeksElapsed);
  const loopedMonth = addMonths(targetDate, monthsElapsed);

  return {
    days: differenceInMinutes(loopedDay, today),
    weeks: differenceInMinutes(loopedWeek, today),
    months: differenceInMinutes(loopedMonth, today),
  };
}

export function getLoopedInternal(dateInput: string | Date) {
  const targetDate = new Date(dateInput);
  const startOfTargetDate = startOfDay(targetDate);
  const today = new Date();
  const startOfToday = startOfDay(today);

  const daysElapsed = differenceInDays(startOfToday, startOfTargetDate);
  const weeksElapsed = differenceInWeeks(startOfToday, startOfTargetDate);
  const monthsElapsed = differenceInMonths(startOfToday, startOfTargetDate);

  const loopedDay = addDays(targetDate, daysElapsed);
  const loopedWeek = addWeeks(targetDate, weeksElapsed);
  const loopedMonth = addMonths(targetDate, monthsElapsed);

  return {
    days: loopedDay,
    weeks: loopedWeek,
    months: loopedMonth,
  };
}

export function sortRoutinesByMinutesElapsed(routines: RoutineWithDueDate[]) {
  const today = new Date();
  const sortedRoutines = routines
    .map((routine) => {
      let dueDate: Date = new Date(routine.startDate);

      // Swap out repeatInterval is repeats is false
      const interval = routine.repeats ? routine.repeatInterval : null;

      switch (interval) {
        case 'day':
          dueDate = getLoopedInternal(routine.startDate).days;
          break;

        case 'week':
          let weekStartDate = new Date(routine.startDate);
          // Find out which of the selected day options is next then set the day
          // of the week for the original startDate to that
          if (routine.repeatOptions) {
            const routineStartDay = getDay(new Date(routine.startDate));
            const selectedDays = routine.repeatOptions.split(','); // Array of selected week days
            let targetDay = Number(routineStartDay);
            const currentDayNumber = getDay(today);
            /**
             * iterate over this array in reverse, because we want to see when the next day that this
             * happens prior to today.  So if today is Thursday (4), and the selected days are 1, 3, 5,
             * we want to see when the last time this happened was, which was on Wednesday (3).
             * We could go the other the other direction if we chose to check to see if the next day this happens
             * prior to assigning it to target day, but this works as written.
             */
            for (let i = selectedDays.length - 1; i >= 0; i--) {
              const day = Number(selectedDays[i]);
              // this is needed so routines don't show up prior to the "start day" of a routine.
              const targetDate = setDay(weekStartDate, day);
              if (targetDate < new Date(routine.startDate)) {
                break;
              }

              targetDay = day;
              if (targetDay <= currentDayNumber) {
                break;
              }
            }
            weekStartDate = setDay(weekStartDate, targetDay);
          }

          dueDate = getLoopedInternal(weekStartDate).weeks;
          break;

        case 'month':
          // Set the day of the startDate to match the date selected in
          // repeat options, preserving the time of day

          let monthStartDate = new Date(routine.startDate);
          const monthDateSelect = Number(routine.repeatOptions);
          if (monthDateSelect) {
            monthStartDate = setDate(monthStartDate, monthDateSelect);
          }
          // we don't want a routine to be due prior to when it was created
          if (monthStartDate < new Date(routine.startDate)) {
            monthStartDate = addMonths(monthStartDate, 1);
          }

          dueDate = getLoopedInternal(monthStartDate).months;
          break;

        default:
          break;
      }
      const modifiedDate = routine.modifiedDates.find((date: any) =>
        isSameDay(new Date(date.modifiedDate), dueDate),
      );
      if (modifiedDate) {
        dueDate = new Date((modifiedDate as any).modifiedDate);
      }

      const routineWithDueDate: RoutineWithDueDate = {
        ...routine,
        dueDate,
      };

      return {
        routineWithDueDate,
        minutesElapsed: differenceInMinutes(dueDate, today),
      };
    })
    .sort((a, b) => {
      return a.minutesElapsed - b.minutesElapsed;
    })
    .map(
      (routineWithMinutesElapsed) =>
        routineWithMinutesElapsed.routineWithDueDate,
    );

  return sortedRoutines;
}

import { motion } from 'framer-motion';

import { UserCircleIcon } from '@heroicons/react/24/solid';
import { fadeIn } from '../../../../../shared/helpers/animation';
import Button from '../../../../../shared/components/Button';
import useProfile from '../../../../../shared/hooks/useProfile';
import Image from '../../../../../shared/components/Image';
import { getCaretakerTitle } from '../../../../../shared/helpers/relationship-title';

const RegisterDependentStep1 = ({ handleNext }: { handleNext: () => void }) => {
  const { profile } = useProfile();
  const caretakerTitle = getCaretakerTitle(
    (profile as any).dependent.caretakers[0].relationshipTypeId,
  );
  return (
    <motion.main
      className="mt-8 flex h-full flex-col justify-between"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <section className="mt-12 flex flex-col items-center">
        <h2 className="text-primary-700 text-2xl font-bold">
          Is this your {caretakerTitle}?
        </h2>
        {profile.dependent?.caretakers?.map(({ caretaker }, index) => {
          const caretakerProfile = caretaker.profile;
          if (!caretakerProfile) return <></>;
          return (
            <div
              className="mt-6 flex items-center justify-center gap-x-6 rounded-md border border-gray-300 px-8 py-4 shadow-lg"
              key={index}
            >
              <div>
                {caretaker?.profile?.photo ? (
                  <Image
                    src={caretaker?.profile?.photo || ''}
                    className="w-16 rounded-full text-gray-300 shadow-md drop-shadow-lg"
                    alt="Caretaker profile photo"
                  />
                ) : (
                  <UserCircleIcon className="w-16 text-gray-400" />
                )}
              </div>
              <div>
                <p className="text-xl font-semibold text-gray-800">
                  {caretakerProfile.firstName} {caretakerProfile.lastName}
                </p>
                {/* Replace with relationship type */}
                <p className="text-sm font-light">{caretakerTitle}</p>
              </div>
            </div>
          );
        })}
      </section>
      <section className="mt-8 flex gap-x-4">
        <Button className="grow" onClick={() => handleNext()} label="Yes" />
      </section>
    </motion.main>
  );
};

export default RegisterDependentStep1;

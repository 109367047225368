import { useAuth0 } from '@auth0/auth0-react';
import { IonContent, IonGrid, IonPage } from '@ionic/react';
import { motion } from 'framer-motion';
import React from 'react';
import Button from '../../../shared/components/Button';
import DividerWithLabel from '../../../shared/components/DividerWithLabel';
import { slideUpFromBottom } from '../../../shared/helpers/animation';
import useAuth0Lock from '../../../shared/hooks/useAuth0Lock';
import './GetStarted.css';

const GetStarted: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const { showPasswordlessLogin } = useAuth0Lock();
  const handleLogin = () => {
    loginWithRedirect();
  };

  const handleDependentLogin = () => {
    showPasswordlessLogin();
  };

  return (
    <IonPage className="brand-gradient">
      <IonContent>
        <IonGrid fixed>
          <motion.div
            className="mx-12 mt-[30vh] flex flex-col items-center justify-center"
            initial="hidden"
            animate="visible"
            variants={slideUpFromBottom}
          >
            <img
              src={'./assets/RoutineSuccessLogo.png'}
              className="mx-auto w-32 px-4"
              alt={'Logo'}
            />
            <motion.h1
              className="mb-12 mt-4 text-lg font-bold text-cyan-900"
              initial="hidden"
              animate="visible"
              variants={slideUpFromBottom}
            >
              Routine Success
            </motion.h1>
            <Button
              label="I am a mentor or parent"
              className="w-full"
              onClick={() => {
                handleLogin();
              }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
              className="w-full"
            >
              <DividerWithLabel label="or" className="my-6" />
            </motion.div>
            <Button
              label="I am a mentee or young person"
              className="w-full"
              onClick={() => {
                handleDependentLogin();
              }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            />
            <div className="h-12 w-full" />
            <p>
              Read our{' '}
              <a
                href="https://routine-success.com/privacy"
                className="underline"
              >
                {' '}
                privacy policy{' '}
              </a>{' '}
              and{' '}
              <a href="https://routine-success.com/terms" className="underline">
                {' '}
                terms{' '}
              </a>
            </p>
          </motion.div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default GetStarted;

import { fadeIn } from '../../../../../shared/helpers/animation';
import { motion } from 'framer-motion';
import { SelectButton } from 'primereact/selectbutton';
import { RoutineForm } from '../../create-routine-modal.types';

const WeekOptions: React.FC<{
  routineForm: RoutineForm;
  handleRoutineChange: (val: any, key: string) => void;
  disabled?: boolean;
}> = ({ routineForm, handleRoutineChange, disabled }) => {
  const { repeatOptions } = routineForm;

  const selectOptions = [
    { name: 'S', value: '0' },
    { name: 'M', value: '1' },
    { name: 'T', value: '2' },
    { name: 'W', value: '3' },
    { name: 'T', value: '4' },
    { name: 'F', value: '5' },
    { name: 'S', value: '6' },
  ];

  return (
    <motion.div
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="flex flex-col py-4"
      layout
    >
      <p className="mb-1">Days of occurrence</p>
      <SelectButton
        disabled={disabled}
        options={selectOptions}
        value={repeatOptions}
        onChange={(e) => handleRoutineChange(e.value, 'repeatOptions')}
        optionLabel="name"
        className="flex"
        multiple
      />
    </motion.div>
  );
};

export default WeekOptions;

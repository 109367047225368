import { ListBulletIcon, PhotoIcon } from '@heroicons/react/24/solid';
import { Routine } from '../../../../../shared/store/api/endpoints';
import { useAppSelector } from '../../../../../shared/store/store';
import { formattedTimeFrame } from '../../../../../shared/utility/string-formatting';

export function RoutineDetailHeader({ routine }: { routine: Routine }) {
  const dateRange = useAppSelector((state) => state.dependentProgress);
  const name =
    routine.modifiedRoutines?.find((r) => r.endDate === null)?.name ||
    routine.name;
  return (
    <div className="flex flex-col items-center gap-4">
      <div className="flex flex-col items-center gap-2">
        <span className="text-center text-lg font-bold">{name}</span>
        <span className="text-center">{formattedTimeFrame(dateRange)}</span>
      </div>
      <div className="flex justify-center gap-2 pb-2">
        <div className="flex items-center gap-1 rounded-lg bg-[#00317b] bg-opacity-20 px-2 py-[3px]">
          <ListBulletIcon className="h-6 w-6" />
          {routine.tasks.length} tasks
        </div>
        {routine.requirePhoto && (
          <div className="flex items-center gap-1 rounded-lg bg-[#00317b] bg-opacity-20 px-2 py-[3px]">
            <PhotoIcon className="h-4 w-4" />
            Picture required
          </div>
        )}
      </div>
    </div>
  );
}

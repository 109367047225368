import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  useIonActionSheet,
} from '@ionic/react';
import useProfile from '../../../shared/hooks/useProfile';
import ProfileExists from './ProfileExists';
import CreateProfile from './CreateProfile';
import { ellipsisVertical } from 'ionicons/icons';

export interface DependentFormType {
  firstName?: string;
  lastName?: string;
  photo?: string;
  smsNotifications: boolean;
}

const RegisterDependent: React.FC = () => {
  const { profile } = useProfile();
  const { logout } = useAuth0();
  const [presentAction] = useIonActionSheet();
  // methods
  const handleActionSheet = () => {
    presentAction([
      {
        text: 'Log out',
        handler: () => {
          logout({ returnTo: window.location.origin });
        },
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };
  return (
    <IonPage>
      <IonContent>
        <div className="absolute top-0 right-0">
          <IonButton onClick={handleActionSheet} fill="clear">
            <IonIcon icon={ellipsisVertical} />
          </IonButton>
        </div>
        <IonGrid fixed>
          <main className="mx-auto flex h-screen flex-col justify-between overflow-y-scroll px-6 pb-8">
            {profile ? <ProfileExists /> : <CreateProfile />}
          </main>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default RegisterDependent;

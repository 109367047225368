import { motion } from 'framer-motion';
import { FC } from 'react';
import { slideUpFromBottom } from '../../../shared/helpers/animation';
import {
  CaretakerDependent,
  Profile,
} from '../../../shared/store/api/endpoints';
import ProfileCard from '../ProfileCard';

interface CaretakerOrDependentTabProps {
  caretakerDependents: CaretakerDependent[];
  caretakerOrDependent: 'caretaker' | 'dependent';
}

const CaretakerOrDependentTab: FC<CaretakerOrDependentTabProps> = ({
  caretakerDependents,
  caretakerOrDependent,
}) => (
  <motion.div
    variants={slideUpFromBottom}
    initial="hidden"
    animate="visible"
    exit="exit"
    className="px-6 py-4"
  >
    {caretakerDependents.map((c) => (
      <ProfileCard
        profile={
          caretakerOrDependent === 'caretaker'
            ? (c.dependent.profile as Profile)
            : (c.caretaker.profile as Profile)
        }
        relationship={c.relationshipType.name}
        key={
          caretakerOrDependent === 'caretaker' ? c.dependent.id : c.caretaker.id
        }
      />
    ))}
  </motion.div>
);

export default CaretakerOrDependentTab;

import { isArray } from 'lodash';
import { useState } from 'react';
import useStorage from '../../../../shared/hooks/useStorage';
import {
  useRoutineControllerCreateRoutineMutation,
  useRoutineControllerUpdateRoutineMutation,
} from '../../../../shared/store/api/endpoint-with-tags';
import { Routine } from '../../../../shared/store/api/endpoints';
import useProfile from '../../../../shared/hooks/useProfile';
import { RoutineForm } from '../../../_components/CreateRoutineModal/create-routine-modal.types';
import { getHours, getMinutes, setHours, setMinutes, subDays } from 'date-fns';

const useDependentCreateRoutine = () => {
  const { profile } = useProfile();
  const [fileUploading, setFileUploading] = useState(false);

  const [
    createRoutine,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
    },
  ] = useRoutineControllerCreateRoutineMutation();
  const [
    updateRoutine,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useRoutineControllerUpdateRoutineMutation();
  const { uploadFile } = useStorage();

  const saveRoutine = async (form: RoutineForm, routine?: Routine) => {
    if (!profile) return;
    const { dependents, googleSync, ...routineForm } = form;
    try {
      let path;
      if (
        (routine &&
          routineForm.examplePhoto &&
          routine.examplePhoto !== routineForm.examplePhoto) ||
        (!routine && routineForm.examplePhoto)
      ) {
        // uploading file state just in case things are slow.
        setFileUploading(true);
        const date = new Date().getTime();
        path = await uploadFile(
          routineForm.examplePhoto,
          `${date}.jpeg`,
          'image/jpeg',
        );
        setFileUploading(false);
        // todo add cleanup for old files
      }
      let body: any = {
        ...routineForm,
        startDate: routineForm.startDate.toISOString(),
        repeatOptions: routineForm.repeatOptions
          ? isArray(routineForm.repeatOptions)
            ? routineForm.repeatOptions.join(',')
            : routineForm.repeatOptions
          : null,
      };
      let newRoutine: Routine;
      // handle update/editing
      if (routine) {
        // end date would be today with the same hours/minutes as the start date
        let endDate = new Date();
        endDate = setHours(endDate, getHours(new Date(routine.startDate)));
        endDate = setMinutes(endDate, getMinutes(new Date(routine.startDate)));
        endDate = subDays(endDate, 1);
        body = {
          ...routine,
          ...body,
          examplePhoto: path || routine.examplePhoto,
          endDate, // passing end date as a precaution in case recurrence has changed
        };
        // stop "syncing" if googleSync is false
        if (!googleSync) {
          body = {
            ...body,
            googleCalendarEventId: null,
          };
        }
        newRoutine = await updateRoutine({
          updateRoutinePayload: body,
          id: routine.id,
        }).unwrap();
      } else {
        // create new routine
        body = {
          ...body,
          dependentId: profile?.dependent?.id as string,
          examplePhoto: path || null,
          repeatInterval: body.repeats ? body.repeatInterval : null,
          private: body.private,
        };
        newRoutine = await createRoutine({
          createRoutinePayload: body,
        }).unwrap();
      }
      return newRoutine;
    } finally {
      setFileUploading(false);
    }
  };

  return {
    isLoading: createLoading || fileUploading || updateLoading,
    createSuccess,
    createError,
    updateSuccess,
    updateError,
    saveRoutine,
    updateRoutine,
  };
};

export default useDependentCreateRoutine;

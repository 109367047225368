import { motion } from 'framer-motion';
import { fadeIn } from '../../../../shared/helpers/animation';
import Button from '../../../../shared/components/Button';
import { DependentFormType } from '../RegisterCaretaker';

const RegisterCaretakerStep3 = ({
  setActiveIndex,
  handleSubmit,
  isLoading,
  dependentFormState,
}: {
  setActiveIndex: (index: number) => void;
  handleSubmit: () => void;
  isLoading: boolean;
  dependentFormState: DependentFormType;
}) => {
  const handleFinish = () => {
    handleSubmit();
  };

  return (
    <motion.main
      className="mt-4 flex h-full flex-col justify-between"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <section>
        <img
          src={'./assets/LinkUsersIcon.png'}
          alt="Link Users Icon"
          className="mx-auto w-2/4 opacity-50"
        />
        <div className="mt-2 flex flex-col gap-y-4">
          <p>
            By using the link provided in the text invite you’ve sent,{' '}
            {dependentFormState?.firstName || 'your mentee'}’s profile will
            automatically be connected with yours.
          </p>
          <p>
            If your mentee does not receive an invite to the app via SMS text
            message, they can log on automatically at {document.location.host}.
          </p>
          <p>
            You will be notified once{' '}
            {dependentFormState?.firstName || 'your mentee'} has joined the app.
          </p>
        </div>
      </section>
      <section className="mt-8 flex gap-x-4">
        <Button
          className="grow"
          onClick={() => handleFinish()}
          label="Continue"
          loading={isLoading}
          disabled={isLoading}
        />
      </section>
    </motion.main>
  );
};

export default RegisterCaretakerStep3;

export function readableFrequency(frequency: 'day' | 'week' | 'month') {
  switch (frequency) {
    case 'day':
      return 'Daily';
    case 'week':
      return 'Weekly';
    case 'month':
      return 'Monthly';
    default:
      return 'One time';
  }
}

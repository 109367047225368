import { useIonToast } from '@ionic/react';
import { AnimatePresence, LayoutGroup } from 'framer-motion';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { useState, useEffect, useRef, useCallback } from 'react';
import ModalWrapper from '../../../../shared/components/ModalWrapper';
import {
  CaretakerDependent,
  Dependent,
  Routine,
} from '../../../../shared/store/api/endpoints';
import AddCompletionPhoto from '../../../_components/CreateRoutineModal/AddCompletionPhoto';
import Details from '../../../_components/CreateRoutineModal/Details';
import ReminderOptions from '../../../_components/CreateRoutineModal/ReminderOptions';
import RepeatRoutine from '../../../_components/CreateRoutineModal/RepeatRoutine';
import Tasks from '../../../_components/CreateRoutineModal/Tasks';
import useCreateRoutineModal from './useCaretakerCreateRoutineModal';
import { RoutineForm } from '../../../_components/CreateRoutineModal/create-routine-modal.types';
import { ShareWithMentors } from '../../../_components/CreateRoutineModal/ShareWithMentors/ShareWithMentors';
import { useParams } from 'react-router-dom';
import useDependent from '../../../../shared/hooks/useDependent';
import useProfile from '../../../../shared/hooks/useProfile';
import useAllRoutines from '../../../../shared/hooks/useRoutines';

interface CreateRoutineModalProps {
  onDismiss: () => void;
  routine?: Routine;
  parentModalRef: any;
  multipleDependents?: Dependent[];
}

export function CaretakerCreateRoutineModal({
  onDismiss,
  routine,
  parentModalRef,
  multipleDependents,
}: CreateRoutineModalProps) {
  // hooks
  const [present] = useIonToast();
  const {
    isLoading,
    createSuccess,
    createError,
    saveRoutine,
    updateSuccess,
    updateError,
  } = useCreateRoutineModal();

  const { id } = useParams() as { id: string };
  const dependent = useDependent(id);
  const { profile } = useProfile();
  const { lastCompleted } = useAllRoutines((dependent as Dependent)?.id);

  // state
  const [routineForm, setRoutineForm] = useState<RoutineForm>({
    name: routine?.name || '',
    repeats: routine?.repeats === false ? false : true,
    startDate: routine?.startDate ? new Date(routine.startDate) : new Date(),
    repeatInterval: routine?.repeatInterval || 'day',
    repeatOptions: routine?.repeatOptions
      ? routine.repeatInterval === 'week'
        ? routine.repeatOptions.split(',')
        : routine.repeatOptions
      : null,
    reminder: routine?.reminder || 300,
    requirePhoto: routine?.requirePhoto || false,
    tasks: routine?.tasks || [],
    examplePhoto: routine?.examplePhoto || null,
    dependents: [],
  });

  const [isShared, setIsShared] = useState<boolean>(
    routine ? !!routine.sharedCaretakers.length : false,
  );

  const firstRenderRef = useRef(false);

  const caretakers = useCallback(
    (caretakers: CaretakerDependent[] | undefined) => {
      return caretakers
        ? caretakers
            .filter(({ caretaker }) => caretaker.profileId !== profile.id)
            .filter(({ caretaker }) => routine?.caretakerId !== caretaker.id)
            .map(({ caretaker }) => caretaker)
        : [];
    },
    [profile, routine],
  );

  const caretakerIds = useCallback(
    (caretakerDependents: CaretakerDependent[] | undefined) => {
      return caretakers(caretakerDependents).map((caretaker) => caretaker.id);
    },
    [caretakers],
  );

  const [sharedCaretakers, setSharedCaretakers] = useState<string[]>(
    routine ? routine.sharedCaretakers.map((caretaker) => caretaker.id) : [],
  );

  useEffect(() => {
    if (firstRenderRef.current) {
      if (isShared) {
        setSharedCaretakers(caretakerIds(dependent?.caretakers));
      } else {
        setSharedCaretakers([]);
      }
    }
    if (!firstRenderRef.current) {
      firstRenderRef.current = true;
    }
  }, [isShared, dependent, profile, routine, caretakerIds]);

  // methods
  const handleRoutineChange = (value: any, key: string) => {
    const newRoutineForm: any = { ...routineForm };
    newRoutineForm[key] = value;
    // reset options if interval is changed
    if (key === 'repeatInterval') {
      if (value === 'month') {
        // month needs a default value
        newRoutineForm['repeatOptions'] = '1';
      } else {
        newRoutineForm['repeatOptions'] = null;
      }
    }
    setRoutineForm(newRoutineForm);
  };

  async function handleSaveRoutine(
    saveBody: RoutineForm,
    routine: Routine | undefined,
  ) {
    try {
      await saveRoutine(saveBody, routine);
      present({
        message: !routine
          ? 'Routine created successfully'
          : 'Routine updated successfully',
        duration: 2500,
      });
      onDismiss();
    } catch {
      present({
        message: !routine ? 'Error creating routine' : 'Error updating routine',
        duration: 2500,
      });
    }
  }

  return (
    <ModalWrapper
      title={routine ? 'Edit Routine' : 'Add New Routine'}
      onDismiss={onDismiss}
      contentClass="neutral-3"
    >
      <AnimatePresence exitBeforeEnter>
        <LayoutGroup>
          <main className="px-4 pt-6">
            <Details
              routineForm={routineForm}
              handleRoutineChange={handleRoutineChange}
            />
            {routineForm.repeats && (
              <RepeatRoutine
                routineForm={routineForm}
                handleRoutineChange={handleRoutineChange}
              />
            )}
            <ReminderOptions
              key="reminder-options"
              routineForm={routineForm}
              handleRoutineChange={handleRoutineChange}
            />
            <AddCompletionPhoto
              routineForm={routineForm}
              handleRoutineChange={handleRoutineChange}
              parentModalRef={parentModalRef}
            />
            {/** not giving the option to restrict sharing for multiple dependent selection */}
            {!multipleDependents && (
              <ShareWithMentors
                label="Share with additional mentors"
                isPrivate={!isShared}
                handlePrivateChange={(e) => setIsShared(e)}
                handleSelectedMentorChange={(e) => setSharedCaretakers(e)}
                sharedCaretakers={sharedCaretakers}
                caretakers={caretakers(dependent?.caretakers)}
              />
            )}
            {multipleDependents && (
              <div className="p-field">
                <label htmlFor={'multiple-dependents-input'}>Dependents</label>
                <MultiSelect
                  value={routineForm.dependents}
                  id={'multiple-dependents-input'}
                  className="mt-1 w-full"
                  options={multipleDependents?.map((dependent) => ({
                    label: `${dependent.profile?.firstName} ${dependent.profile?.lastName}`,
                    value: dependent.id,
                  }))}
                  onChange={(e) => handleRoutineChange(e.value, 'dependents')}
                />
              </div>
            )}
          </main>
          <div className="bg-neutral-100 p-4">
            <Tasks
              routineForm={routineForm}
              handleRoutineChange={handleRoutineChange}
            />
            <div className="flex w-full py-4">
              <Button
                label="Save"
                onClick={() => {
                  handleSaveRoutine(
                    { ...routineForm, sharedCaretakers },
                    routine,
                  );
                }}
                loading={isLoading}
                disabled={isLoading}
              />
            </div>
          </div>
        </LayoutGroup>
      </AnimatePresence>
    </ModalWrapper>
  );
}

import {
  IonTabs,
  IonRouterOutlet,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonPage,
} from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { albums, calendar, gift, person, trendingUp } from 'ionicons/icons';
import Profile from './pages/DependentProfilePage';
import Routines from './pages/DependentRoutinesPage';
import Rewards from './pages/DependentRewardsPage';
import DependentSchedule from './pages/DependentSchedulePage';
import { DependentProgressPage } from './pages/DependentProgressPage';
import { RoutineDetailPage } from '../_pages/routine-detail-page';
import { ViewRoutineHistoryPage } from '../_pages/view-routine-history-page';
import { CompleteRoutinePage } from './pages/CompleteRoutinePage';
import { CompletedFeedbackPage } from './pages/CompletedFeedbackPage';

const Dependent = () => {
  return (
    <>
      <IonPage>
        <IonRouterOutlet>
          <Route
            exact
            path="/routine-detail/:routineId/completed/:completedRoutineId"
          >
            <ViewRoutineHistoryPage />
          </Route>
          <Route exact path="/routine-detail/:routineId">
            <RoutineDetailPage />
          </Route>
          <Route
            exact
            path="/complete-routine/:routineId/:completedRoutineId/feedback"
          >
            <CompletedFeedbackPage />
          </Route>
          <Route
            exact
            path="/complete-routine/:routineId/:completedRoutineId/redo"
          >
            <CompleteRoutinePage />
          </Route>
          <Route exact path="/complete-routine/:routineId">
            <CompleteRoutinePage />
          </Route>
          <Route exact path="/tabs/:tab">
            <IonTabs>
              <IonRouterOutlet>
                <Route exact path="/tabs/schedule">
                  <DependentSchedule />
                </Route>
                <Route exact path="/tabs/routines">
                  <Routines />
                </Route>
                <Route exact path="/tabs/rewards">
                  <Rewards />
                </Route>
                <Route exact path="/tabs/profile">
                  <Profile />
                </Route>
                <Route exact path="/tabs/progress">
                  <DependentProgressPage />
                </Route>
              </IonRouterOutlet>
              <IonTabBar slot="bottom" className="pt-2 pb-1">
                <IonTabButton tab="schedule" href="/tabs/schedule">
                  <IonIcon icon={calendar} />
                  <IonLabel>Schedule</IonLabel>
                </IonTabButton>
                <IonTabButton tab="routines" href="/tabs/routines">
                  <IonIcon icon={albums} />
                  <IonLabel>Routines</IonLabel>
                </IonTabButton>
                <IonTabButton tab="progress" href={`/tabs/progress`}>
                  <IonIcon icon={trendingUp} />
                  <IonLabel>Progress</IonLabel>
                </IonTabButton>
                <IonTabButton tab="rewards" href="/tabs/rewards">
                  <IonIcon icon={gift} />
                  <IonLabel>Rewards</IonLabel>
                </IonTabButton>
                <IonTabButton tab="profile" href="/tabs/profile">
                  <IonIcon icon={person} />
                  <IonLabel>Profile</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
          </Route>

          <Route exact path="/tabs">
            <Redirect to="/tabs/schedule" />
          </Route>
          <Route exact path="/">
            <Redirect to="/tabs/schedule" />
          </Route>
          <Route exact path="/:wildcard">
            <Redirect to="/tabs/schedule" />
          </Route>
        </IonRouterOutlet>
      </IonPage>
    </>
  );
};

export default Dependent;

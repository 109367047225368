import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from '@ionic/react';
import { Route, useParams } from 'react-router';
import Profile from '../CaretakerProfilePage';
import Rewards from '../CaretakerRewardsPage';
import CaretakerRoutinesPage from '../CaretakerRoutinesPage';
import CaretakerSchedule from '../CaretakerSchedulePage';
import { albums, calendar, gift, person, trendingUp } from 'ionicons/icons';
import { CaretakerProgressPage } from '../CaretakerProgressPage';

export default function CaretakerTabs() {
  const { id } = useParams() as { id: string };
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path="/tabs/:id/routines">
          <CaretakerRoutinesPage />
        </Route>
        <Route exact path="/tabs/:id/schedule">
          <CaretakerSchedule />
        </Route>
        <Route exact path="/tabs/:id/rewards">
          <Rewards />
        </Route>
        <Route exact path="/tabs/:id/profile">
          <Profile />
        </Route>
        <Route exact path="/tabs/:id/progress">
          <CaretakerProgressPage />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className="pt-2 pb-1">
        <IonTabButton tab="schedule" href={`/tabs/${id}/schedule`}>
          <IonIcon icon={calendar} />
          <IonLabel>Schedule</IonLabel>
        </IonTabButton>
        <IonTabButton tab="routines" href={`/tabs/${id}/routines`}>
          <IonIcon icon={albums} />
          <IonLabel>Routines</IonLabel>
        </IonTabButton>
        <IonTabButton tab="progress" href={`/tabs/${id}/progress`}>
          <IonIcon icon={trendingUp} />
          <IonLabel>Progress</IonLabel>
        </IonTabButton>
        <IonTabButton tab="rewards" href={`/tabs/${id}/rewards`}>
          <IonIcon icon={gift} />
          <IonLabel>Rewards</IonLabel>
        </IonTabButton>
        <IonTabButton tab="profile" href={`/tabs/${id}/profile`}>
          <IonIcon icon={person} />
          <IonLabel>My Profile</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
}

import { useRef, useState } from 'react';
import RegisterHeader from '../../components/RegisterHeader';
import { DependentFormType } from '../RegisterDependent';
import RegisterDependentStep1 from './RegisterDependentStep1';
import DependentName from '../components/DependentName';
import DependentPhoto from '../components/DependentPhoto';
import {
  useProfileControllerChangeCaretakerStatusMutation,
  useProfileControllerCreateDependentProfileMutation,
} from '../../../../shared/store/api/endpoints';
import { useAuth0 } from '@auth0/auth0-react';
import useProfile from '../../../../shared/hooks/useProfile';
import { useHistory } from 'react-router-dom';
import useStorage from '../../../../shared/hooks/useStorage';

const ProfileExists = () => {
  const history = useHistory();
  const { user } = useAuth0();
  const { profile } = useProfile();
  const [fileUploading, setFileUploading] = useState(false);
  const [dependentFormState, setDependentFormState] =
    useState<DependentFormType>({
      firstName: profile.firstName || '',
      lastName: profile.lastName || '',
      smsNotifications: false,
    });

  const [updateProfile, { isLoading }] =
    useProfileControllerCreateDependentProfileMutation();
  const [changeCaretakerStatus] =
    useProfileControllerChangeCaretakerStatusMutation();

  const steps = [
    { label: 'Welcome', header: 'Welcome to Routine Success!' },
    {
      label: 'Details',
      header: 'Confirm your profile details',
    },
    {
      label: 'Photo',
      header: 'Add a picture to your profile',
    },
  ];

  const [photo, setPhoto] = useState<string | null>(null);
  const { uploadFile } = useStorage();
  const handleRegister = async () => {
    if (!user) return;
    let path;
    if (photo) {
      setFileUploading(true);
      const date = new Date().getTime();
      path = await uploadFile(photo, `${date}.jpeg`, 'image/jpeg');
      setFileUploading(false);
    }
    const data = {
      firstName: dependentFormState.firstName as string,
      lastName: dependentFormState.lastName as string,
      photo: path,
      phone: user.name as string,
      id: profile.id,
      permissions: ['3'], // from seed data, 3 is dependent
      smsNotifications: dependentFormState.smsNotifications,
    };
    await updateProfile({
      createProfile: data,
    }).unwrap();
    // change caretaker status separately from update profile for now.
    await changeCaretakerStatus({
      changeCaretakerStatusBody: {
        dependentId: profile.dependent?.id as string,
        // only changing for the first one for now.  possible change to many after the mvp
        // also might move this to the "approve caretaker" page
        caretakerId: (profile as any).dependent?.caretakers[0]
          .caretakerId as string,
        status: 'accepted',
      },
    }).unwrap();
    history.push('/');
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const rootRef = useRef<any>(null);
  return (
    <>
      <RegisterHeader
        steps={steps}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      {activeIndex === 0 && (
        <RegisterDependentStep1 handleNext={() => setActiveIndex(1)} />
      )}
      {activeIndex === 1 && (
        <DependentName
          handleNext={() => setActiveIndex(2)}
          setDependentFormState={setDependentFormState}
        />
      )}
      {activeIndex === 2 && (
        <DependentPhoto
          handleNext={handleRegister}
          setDependentFormState={setDependentFormState}
          photo={photo}
          setPhoto={setPhoto}
          loading={fileUploading || isLoading}
          parentRef={rootRef}
        />
      )}
    </>
  );
};

export default ProfileExists;

import { useContext, useRef, useState } from 'react';
import RewardPreview from '../../../_components/RewardPreview';
import RewardEmptyState from '../../components/RewardEmptyState';
import PageWrapper from '../../../../shared/components/PageWrapper';
import {
  Dependent,
  Goal,
  Profile,
  useGoalControllerAwardGoalMutation,
} from '../../../../shared/store/api/endpoints';
import CompletedReward from '../../../_components/CompletedReward';
import {
  IonModal,
  useIonActionSheet,
  useIonToast,
  NavContext,
} from '@ionic/react';
import useGoals from '../../../../shared/hooks/useGoals';
import CreateGoalModal from '../../components/CreateGoalModal';
import { useAuth0 } from '@auth0/auth0-react';
import { ellipsisVertical } from 'ionicons/icons';
import { useParams } from 'react-router-dom';
import useDependent from '../../../../shared/hooks/useDependent';

const Rewards = () => {
  // hooks
  const [presentActionSheet] = useIonActionSheet();
  const { logout } = useAuth0();
  const { id } = useParams() as { id: string };
  const dependent = useDependent(id) as Dependent;
  const { nextGoal, starsAchieved, finishedGoals, upcomingGoals } = useGoals(
    dependent.id,
  );
  const [awardGoal] = useGoalControllerAwardGoalMutation();
  const [present] = useIonToast();
  const { navigate } = useContext(NavContext);

  //Refs
  const rootRef = useRef<any>(null);
  const goalModalRef = useRef<HTMLIonModalElement>(null);

  // state
  const [showGoalModal, setShowGoalModal] = useState(false);
  const [goalToEdit, setGoalToEdit] = useState<Goal>();

  // methods
  const handleAwardGoal = async (id: string) => {
    try {
      await awardGoal({ id }).unwrap();
      present({
        message: 'Goal Awarded!',
        duration: 3000,
      });
    } catch {
      present({
        message: 'Error awarding goal',
        duration: 3000,
      });
    }
  };
  const dismissGoalModal = () => {
    setShowGoalModal(false);
    setGoalToEdit(undefined);
  };

  const handleActionSheet = () => {
    presentActionSheet([
      {
        text: 'Edit Reward',
        handler: () => {
          if (nextGoal) {
            setGoalToEdit(nextGoal);
            setShowGoalModal(true);
          }
        },
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  const handleCompletedActionSheet = (goal: Goal) => {
    presentActionSheet([
      {
        text: 'Reward Given',
        handler: () => {
          handleAwardGoal(goal.id.toString());
        },
      },
      {
        text: 'Edit Reward',
        handler: () => {
          if (goal) {
            setGoalToEdit(goal);
            setShowGoalModal(true);
          }
        },
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  const handleRewardsActionSheet = () => {
    presentActionSheet([
      {
        text: 'Add Reward',
        handler: () => {
          setGoalToEdit(undefined);
          setShowGoalModal(true);
        },
      },
      {
        text: 'Log Out',
        handler: () => {
          logout({ returnTo: window.location.origin });
        },
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  return (
    <PageWrapper
      title={`${dependent?.profile?.firstName}'s Rewards`}
      ref={rootRef}
      onAction={handleRewardsActionSheet}
      actionIcon={ellipsisVertical}
      onDismiss={() => navigate('/', 'back')}
      dismissType="home"
    >
      <main>
        <div className="bg-white px-4 pb-4 shadow-lg">
          {nextGoal ? (
            <RewardPreview
              goal={nextGoal}
              starsAchieved={starsAchieved}
              dependentProfile={dependent.profile}
              handleActionSheet={handleActionSheet}
            />
          ) : (
            <RewardEmptyState
              rootRef={rootRef}
              dependentProfile={dependent.profile as Profile}
            />
          )}
        </div>
        {upcomingGoals.length ? (
          <div className="p-4">
            <div className="pt-4 pb-2">
              <span>Upcoming Rewards</span>
            </div>
            <div className="flex flex-col gap-1">
              {upcomingGoals.map((goal) => (
                <CompletedReward
                  goal={goal}
                  key={goal.id}
                  handleActionSheet={handleActionSheet}
                  notStarted={true}
                ></CompletedReward>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
        {finishedGoals.length ? (
          <div className="px-4 pb-4">
            <div className="pt-4 pb-2">
              <span>Reward History</span>
            </div>
            <div className="flex flex-col gap-1">
              {finishedGoals.map((goal) => {
                return (
                  <CompletedReward
                    goal={goal}
                    key={goal.id}
                    handleActionSheet={() => handleCompletedActionSheet(goal)}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
      </main>
      <IonModal
        ref={goalModalRef}
        isOpen={showGoalModal}
        canDismiss={true}
        presentingElement={rootRef.current || undefined}
        onDidDismiss={dismissGoalModal}
      >
        {showGoalModal && (
          <CreateGoalModal
            onDismiss={dismissGoalModal}
            parentModalRef={goalModalRef}
            goal={goalToEdit}
          />
        )}
      </IonModal>
    </PageWrapper>
  );
};

export default Rewards;

import classNames from 'classnames';
import React, { FC } from 'react';

interface StatusPillProps {
  label: string;
  color: 'yellow' | 'green' | 'red' | 'blue';
}

const StatusPill: FC<StatusPillProps> = ({ label, color }) => (
  <div
    className={classNames(
      'flex h-[25px] w-[90px] items-center justify-center rounded-full',
      {
        [`bg-yellow-100`]: color === 'yellow',
        [`bg-green-100`]: color === 'green',
        [`bg-red-100`]: color === 'red',
        [`bg-blue-100`]: color === 'blue',
      },
    )}
  >
    <span className="text-sm">{label}</span>
  </div>
);

export default StatusPill;

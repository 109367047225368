import classNames from 'classnames';

interface ProgressHeaderItemProps {
  dataPoint: string | number;
  label: string;
  textColor?: string;
  bottomBorder?: boolean;
}

export function ProgressHeaderItem({
  dataPoint,
  label,
  textColor = '#00317B',
  bottomBorder = false,
}: ProgressHeaderItemProps) {
  return (
    <div
      className={classNames('flex flex-col items-center justify-center gap-1', {
        'my-2 border-b-[2px] border-neutral-200 pb-8': bottomBorder,
      })}
    >
      <span
        className="text-2xl font-bold leading-[31.2px]"
        style={{ color: textColor }}
      >
        {dataPoint}
      </span>
      <span className="text-[14px] leading-[19px]">{label}</span>
    </div>
  );
}

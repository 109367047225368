import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
} from '@heroicons/react/24/solid';

interface THProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
  children?: React.ReactNode;
  name?: string;
  selectedSort?: string;
  sortDirection?: 'asc' | 'desc' | undefined;
}
export function TH({
  children,
  name,
  selectedSort,
  sortDirection,
  ...props
}: THProps) {
  const { className, ...rest } = props;
  const isSelected = selectedSort === name;
  function renderSortIcon(
    selected: boolean,
    direction: 'asc' | 'desc' | undefined,
  ) {
    if (selected) {
      if (direction === 'asc') {
        return <ArrowUpIcon className="h-5 w-5 text-primary-teal" />;
      } else if (direction === 'desc') {
        return <ArrowDownIcon className="h-5 w-5 text-primary-teal" />;
      }
    }
    return <ArrowsUpDownIcon className="h-5 w-5 text-primary-teal" />;
  }
  return (
    <th className={`cursor-pointer p-4 ${props?.className}`} {...rest}>
      <div className="flex items-center gap-2">
        {children && (
          <>
            {children} {name && renderSortIcon(isSelected, sortDirection)}
          </>
        )}
      </div>
    </th>
  );
}

import { useAuth0 } from '@auth0/auth0-react';
import { IonModal, useIonActionSheet, useIonAlert } from '@ionic/react';
import { ellipsisVertical } from 'ionicons/icons';
import { useMemo, useRef, useState } from 'react';
import PageWrapper from '../../../../shared/components/PageWrapper';
import useProfile from '../../../../shared/hooks/useProfile';
import useRoutines from '../../../../shared/hooks/useRoutines';
import {
  CompletedRoutine,
  Dependent,
  Routine,
} from '../../../../shared/store/api/endpoints';
import RoutinesActiveList from './RoutinesActiveList';
import RoutinesReviewList from './RoutinesReviewList';

import {
  hourglassOutline,
  refreshOutline,
  sunnyOutline,
  timeOutline,
} from 'ionicons/icons';
import DependentCreateRoutineModal from '../../components/DependentCreateRoutineModal';
import { TabPanel, TabView } from 'primereact/tabview';
import RoutinesTab from '../../../_components/RoutinesTab';
import { useRoutineControllerDeleteRoutineMutation } from '../../../../shared/store/api/endpoint-with-tags';
import { useHistory } from 'react-router-dom';
import { useGoogleCalendar } from '../../../../shared/hooks/use-google-calendar';

export default function DependentRoutinesPage() {
  // Ref
  const routineModalRef = useRef<HTMLIonModalElement>(null);
  const rootRef = useRef<any>(null);

  //Create routine
  const [showCreateRoutineModal, setShowCreateRoutineModal] = useState(false);
  const [selectedRoutineToEdit, setSelectedRoutineToEdit] = useState<Routine>();

  // Hooks
  const { profile } = useProfile();
  const { id } = profile?.dependent as Dependent;
  const history = useHistory();
  const {
    attempted,
    failed,
    todaysRoutines,
    pastDueRoutines,
    routines,
    lastCompleted,
  } = useRoutines(id, true);
  const [present] = useIonActionSheet();
  const [showAlert] = useIonAlert();
  const [deleteRoutine] = useRoutineControllerDeleteRoutineMutation();
  const { deleteCalendarEvent } = useGoogleCalendar();

  const { logout } = useAuth0();

  const hasRoutines = useMemo(() => {
    return (
      pastDueRoutines?.length > 0 ||
      todaysRoutines?.length > 0 ||
      (failed as CompletedRoutine[])?.length > 0 ||
      (attempted as CompletedRoutine[])?.length > 0
    );
  }, [pastDueRoutines, todaysRoutines, failed, attempted]);

  // Properties
  function startRoutine(routine: Routine) {
    history.push('/complete-routine/' + routine.id);
  }

  const presentActionSheet = (completedRoutine: CompletedRoutine) => {
    present([
      {
        text: 'View Feedback',
        handler: () => {
          history.push(
            `/complete-routine/${completedRoutine.routineId}/${completedRoutine.id}/feedback`,
          );
        },
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  const handleActionSheet = () => {
    present([
      {
        text: 'Create a routine',
        handler: () => {
          setShowCreateRoutineModal(true);
          setSelectedRoutineToEdit(undefined);
        },
      },
      {
        text: 'Log out',
        handler: () => {
          logout({ returnTo: window.location.origin });
        },
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  const handleRoutineActionSheet = (routine: Routine) => {
    present([
      {
        text: 'Edit',
        handler: () => {
          // @ts-ignore - last completed is the wrong type
          if (
            !routine.repeatInterval &&
            // @ts-ignore - last completed is the wrong type
            lastCompleted?.find((r) => r.routine_id === routine.id)
          ) {
            showAlert({
              header: 'Cannot edit routine',
              message:
                'This routine does not repeat and has been previously completed so it cannot be edited. Please create a new routine.',
              buttons: ['OK'],
            });
            return;
          }
          setSelectedRoutineToEdit(routine);
          setShowCreateRoutineModal(true);
        },
      },
      {
        text: 'Delete',
        handler: () =>
          showAlert({
            header: 'Delete Routine',
            message:
              'Are you sure you want to delete this routine? This is not reversible.',
            buttons: [
              'Cancel',
              {
                text: 'Yes',
                handler: async () => {
                  await deleteRoutine({ id: routine.id.toString() });
                  if (routine.googleCalendarEventId) {
                    await deleteCalendarEvent(routine.googleCalendarEventId);
                  }
                },
              },
            ],
          }),
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };
  return (
    <PageWrapper
      title="Routines"
      ref={rootRef}
      onAction={handleActionSheet}
      actionIcon={ellipsisVertical}
    >
      <TabView>
        <TabPanel header="To Do">
          <div className="flex flex-col pb-8">
            <RoutinesActiveList
              title="Today's Routines"
              icon={sunnyOutline}
              routines={todaysRoutines}
              startRoutine={startRoutine}
            />

            <RoutinesActiveList
              title="Past Due Routines"
              icon={timeOutline}
              routines={pastDueRoutines}
              startRoutine={startRoutine}
            />

            <RoutinesReviewList
              title="Routines to Re-do"
              icon={refreshOutline}
              completedRoutines={failed as CompletedRoutine[]}
              handleActionSheet={(routine) => presentActionSheet(routine)}
            ></RoutinesReviewList>

            <RoutinesReviewList
              title="Routines Pending Review"
              icon={hourglassOutline}
              completedRoutines={attempted as CompletedRoutine[]}
            ></RoutinesReviewList>
            {!hasRoutines && (
              <div className="px-6 pb-6 pt-2">
                <p className="font-light">
                  You will get a text message on your phone when your next
                  routine begins.
                </p>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel header="All Routines">
          <RoutinesTab
            routines={routines}
            presentActionSheet={handleRoutineActionSheet}
            isCaretaker={false}
          />
        </TabPanel>
      </TabView>

      <IonModal
        ref={routineModalRef}
        isOpen={showCreateRoutineModal}
        canDismiss={true}
        onDidDismiss={() => {
          setSelectedRoutineToEdit(undefined);
          setShowCreateRoutineModal(false);
        }}
      >
        {showCreateRoutineModal && (
          <DependentCreateRoutineModal
            onDismiss={() => {
              setSelectedRoutineToEdit(undefined);
              setShowCreateRoutineModal(false);
            }}
            parentModalRef={rootRef}
            routine={selectedRoutineToEdit}
          />
        )}
      </IonModal>
    </PageWrapper>
  );
}

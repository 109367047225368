import { useState, useRef } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import Button from '../Button';
import DividerWithLabel from '../DividerWithLabel';

const Cropper: React.FC<{
  image: string;
  handleSave: (image: string) => void;
  handleCancel: () => void;
}> = ({ image, handleSave, handleCancel }) => {
  //Controls for the ReactAvatarEditor
  //Width and height must be of a 2:3 aspect ratio
  const [height] = useState<number>(300);
  const [width] = useState<number>(300);
  const [scale, setScale] = useState<number>(1.0);
  const [allowZoomOut] = useState<boolean>(false);
  const [rotate] = useState<number>(0.0);
  const [position, setPosition] = useState<any>({ x: 0.5, y: 0.5 });

  const editorRef: React.MutableRefObject<any> = useRef(null);

  const handleScale = (e: any) => {
    setScale(parseFloat(e.target.value));
  };

  const save = () => {
    if (editorRef) {
      editorRef.current.getImage().toBlob(
        (blob: Blob) => {
          handleSave(URL.createObjectURL(blob));
        },
        'image/jpeg',
        0.5, // keep an eye on image quality as we use the app.  The lower this is the better for performance reasons.
      );
    }
  };

  return (
    <div>
      <ReactAvatarEditor
        ref={editorRef}
        scale={scale}
        width={width}
        height={height}
        position={position}
        borderRadius={6}
        onPositionChange={setPosition}
        rotate={rotate}
        image={image}
        className="editor-canvas mx-auto touch-none rounded-md"
      />
      <div className="mx-auto mt-4 flex w-4/5 flex-row justify-center">
        <input
          name="scale"
          type="range"
          onChange={handleScale}
          min={allowZoomOut ? '0.1' : '1'}
          max="2"
          step="0.01"
          defaultValue="1"
          className="w-4/5"
        />
      </div>
      <div className="mx-6 mt-6">
        <Button onClick={save} label="Save" />
        <DividerWithLabel label="or" className="my-6" />
        <div
          className="w-full text-center text-sm font-bold text-gray-600 hover:text-gray-900"
          onClick={handleCancel}
        >
          <p>Cancel</p>
        </div>
      </div>
    </div>
  );
};

export default Cropper;

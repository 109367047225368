import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { CreateCaretakerProfile } from '../../../shared/store/api/endpoints';
import RegisterHeader from '../components/RegisterHeader';
import RegisterCaretakerStep1 from './RegisterCaretakerStep1';
import RegisterCaretakerStep2 from './RegisterCaretakerStep2';
import RegisterCaretakerStep3 from './RegisterCaretakerStep3';
import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  useIonActionSheet,
  useIonToast,
} from '@ionic/react';
import { useAuth0 } from '@auth0/auth0-react';
import useStorage from '../../../shared/hooks/useStorage';
import { useProfileControllerCreateCaretakerProfileMutation } from '../../../shared/store/api/endpoint-with-tags';
import { ellipsisVertical } from 'ionicons/icons';

export interface CaretakerFormType {
  firstName: string;
  lastName: string;
  phone: string;
  smsNotifications: boolean;
}

export interface DependentFormType {
  firstName: string;
  lastName: string;
  relationshipId: number;
  phone: string;
}

const RegisterCaretaker: React.FC = () => {
  //State
  const [photo, setPhoto] = useState<string | null>(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [createCaretakerProfile, { isLoading }] =
    useProfileControllerCreateCaretakerProfileMutation();
  const [caretakerFormState, setCaretakerFormState] =
    useState<CaretakerFormType>({
      firstName: '',
      lastName: '',
      phone: '',
      smsNotifications: false,
    });
  const [dependentFormState, setDependentFormState] =
    useState<DependentFormType>({
      firstName: '',
      lastName: '',
      phone: '',
      relationshipId: 1,
    });

  //Refs
  const registerFormRef = useRef<any>(null);

  //Hooks
  const [present] = useIonToast();
  const [presentAction] = useIonActionSheet();

  const { user, logout } = useAuth0();
  const { uploadFile } = useStorage();
  const history = useHistory();

  //Pull dependents name from step 2. If not available, use 'your dependent'
  const steps = [
    { label: 'Profile', header: 'Set up your mentor profile' },
    { label: 'Invite', header: 'Invite your mentee' },
    {
      label: 'Finish',
      header: `${
        dependentFormState?.firstName
          ? dependentFormState?.firstName
          : 'Your dependent'
      } is ready to go!`,
    },
  ];

  //Methods
  const handleRegister = async () => {
    try {
      //Create caretaker profile
      if (user && user.email) {
        const { relationshipId, ...dependent } = dependentFormState;
        //Upload photo
        let path;
        if (photo) {
          setFileUploading(true);
          const date = new Date().getTime();
          path = await uploadFile(photo, `${date}.jpeg`, 'image/jpeg');
          setFileUploading(false);
        }
        const { smsNotifications, ...formState } = caretakerFormState;
        const data: CreateCaretakerProfile = {
          caretaker: {
            ...formState,
            email: user.email,
            photo: path,
          },
          dependent,
          relationshipId,
          smsNotifications,
        };
        await createCaretakerProfile({ createCaretakerProfile: data }).unwrap();
        history.push('/');
      }
    } catch (error) {
      console.error(error);
      present('Unable to create caretaker, please try again.', 5000);
    }
  };

  // methods
  const handleActionSheet = () => {
    presentAction([
      {
        text: 'Log out',
        handler: () => {
          logout({ returnTo: window.location.origin });
        },
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  return (
    <IonPage ref={registerFormRef}>
      <IonContent>
        <div className="absolute top-0 right-0">
          <IonButton onClick={handleActionSheet} fill="clear">
            <IonIcon icon={ellipsisVertical} />
          </IonButton>
        </div>
        <IonGrid className="ion-no-padding" fixed>
          <div className="mx-auto flex h-full flex-col justify-between overflow-y-scroll px-6 pb-8">
            <RegisterHeader
              steps={steps}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
            {activeIndex === 0 && (
              <RegisterCaretakerStep1
                caretakerFormState={caretakerFormState}
                setActiveIndex={setActiveIndex}
                setCaretakerFormState={setCaretakerFormState}
                registerFormRef={registerFormRef}
                photo={photo}
                setPhoto={setPhoto}
              />
            )}
            {activeIndex === 1 && (
              <RegisterCaretakerStep2
                setActiveIndex={setActiveIndex}
                dependentFormState={dependentFormState}
                setDependentFormState={setDependentFormState}
                caretakerFormState={caretakerFormState}
              />
            )}
            {activeIndex === 2 && (
              <RegisterCaretakerStep3
                setActiveIndex={setActiveIndex}
                handleSubmit={handleRegister}
                isLoading={fileUploading || isLoading}
                dependentFormState={dependentFormState}
              />
            )}
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default RegisterCaretaker;

import { format } from 'date-fns';
import { CompletedRoutine } from '../../../../../shared/store/api/endpoints';
import { MobileTableWrapper } from '../../../../_components/mobile-table-wrapper/mobile-table-wrapper';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import CompletedStatus from '../../../../_components/CompletedStatus';
import { MobileTR } from '../../../../_components/mobile-tr';

export function MobileRoutineDetailTable({
  data,
}: {
  data: CompletedRoutine[];
}) {
  const history = useHistory();

  function handleView(routineId: number, completedRoutineId: number) {
    history.push(
      `/routine-detail/${routineId}/completed/${completedRoutineId}`,
    );
  }
  return (
    <div className="flex flex-col gap-4 p-4">
      {data.map((completedRoutine) => (
        <MobileTableWrapper key={completedRoutine.id}>
          <MobileTR
            label="Date"
            value={format(new Date(completedRoutine.updatedOn), 'Pp')}
          />
          <MobileTR
            label="Status"
            value={<CompletedStatus status={completedRoutine.status} />}
          />
          <MobileTR label="Points" value={completedRoutine.starsAwarded ?? 0} />
          <MobileTR
            label="Feedback"
            value={
              (completedRoutine.feedback &&
                completedRoutine.feedback.length &&
                completedRoutine.feedback[completedRoutine.feedback.length - 1]
                  .text) ||
              '--'
            }
          />
          <tr className="bg-white">
            <td colSpan={2} className="p-2 text-center">
              <Button
                className="p-button-text"
                label="View"
                onClick={() =>
                  handleView(completedRoutine.routineId, completedRoutine.id)
                }
              />
            </td>
          </tr>
        </MobileTableWrapper>
      ))}
    </div>
  );
}

import React from 'react';
import Lottie from 'lottie-react';
import loadingAnimation from './loadingAnimation.json';
import { motion } from 'framer-motion';
import PageTransitionWrapper from '../PageTransitionWrapper';
import { fadeIn } from '../../helpers/animation';

const FullPageLoading = () => {
  return (
    <PageTransitionWrapper>
      <main className="flex h-screen w-full items-center justify-center">
        <motion.div
          className="w-[300px]"
          variants={fadeIn}
          initial="hidden"
          animate="visible"
        >
          <Lottie animationData={loadingAnimation} loop />
        </motion.div>
      </main>
    </PageTransitionWrapper>
  );
};

export default FullPageLoading;

import { StarIcon } from '@heroicons/react/24/solid';
import { ProgressBar } from 'primereact/progressbar';
import { FC } from 'react';
import Image from '../../../shared/components/Image';
import { Goal } from '../../../shared/store/api/endpoints';
import { IonIcon } from '@ionic/react';
import { ellipsisHorizontal } from 'ionicons/icons';

interface RewardPreviewProps {
  goal: Goal | null;
  starsAchieved?: number;
  dependentProfile?: any;
  handleActionSheet?: () => void;
}

const RewardPreview: FC<RewardPreviewProps> = ({
  goal,
  starsAchieved = 0,
  dependentProfile = false,
  handleActionSheet,
}) => {
  //Templates
  const progressBarTemplate = (value: any) => {
    return (
      <div
        className={`flex items-center bg-gradient-to-tr ${
          value === 0 ? 'text-gray-800' : 'text-white'
        }`}
      >
        <StarIcon className="h-4 w-8" />
        <p>{starsAchieved}</p>
      </div>
    );
  };

  return (
    // <motion.section
    //   layoutId="reward-preview"
    //   variants={fadeIn}
    //   initial="hidden"
    //   animate="visible"
    //   exit="hidden"
    // >
    <section>
      {goal && (
        <>
          <div className="flex gap-x-4 pt-6">
            {goal.rewardPhoto && (
              <Image
                src={goal.rewardPhoto}
                className=" w-1/3 rounded-md"
                alt="reward"
              />
            )}
            <div className="text-primary-700 flex w-full flex-col gap-y-2">
              <div className="flex justify-between">
                <small className="text-sm font-light">
                  {dependentProfile
                    ? `${dependentProfile.firstName}'s`
                    : ' Your'}{' '}
                  next reward is{' '}
                </small>
                {handleActionSheet && (
                  <IonIcon
                    icon={ellipsisHorizontal}
                    slot="icon-only"
                    onClick={handleActionSheet}
                  />
                )}
              </div>
              <p className="text-2xl font-bold leading-6">{goal.name}</p>
            </div>
          </div>
          <div className="mt-4">
            {dependentProfile ? (
              <small className="text-sm">
                {dependentProfile ? dependentProfile.firstName : ' You'} need
                {dependentProfile && 's'}
                <span className="font-bold"> {goal.starsRequired} stars </span>
                to win
              </small>
            ) : (
              <small className="text-sm">
                You need{' '}
                <span className="font-bold"> {goal.starsRequired} stars </span>
                to win
              </small>
            )}
            <ProgressBar
              value={(starsAchieved / goal.starsRequired) * 100}
              displayValueTemplate={progressBarTemplate}
              className="my-2"
            />
          </div>
        </>
      )}
    </section>
    // </motion.section>
  );
};

export default RewardPreview;

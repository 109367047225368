import { useEffect, useState } from 'react';
import Button from '../../../../shared/components/Button';
import CompleteTask from '../../../_components/CompleteTask';
import {
  CompletedTask,
  CompletedTaskFeedback,
  useCompleteRoutineControllerGetCompletedRoutineQuery,
} from '../../../../shared/store/api/endpoints';
import { useIonAlert, useIonToast } from '@ionic/react';
import Image from '../../../../shared/components/Image';
import { InputTextarea } from 'primereact/inputtextarea';
import useProfile from '../../../../shared/hooks/useProfile';
import { useCompleteRoutineControllerUpdateCompleteRoutineMutation } from '../../../../shared/store/api/endpoint-with-tags';
import PreviousFeedback from '../CaretakerRoutinesPage/PreviousFeedback';
import PassedTask from '../../../_components/PassedTask';
import { useHistory, useParams } from 'react-router-dom';
import PageWrapper from '../../../../shared/components/PageWrapper';
import FullPageLoading from '../../../../shared/components/FullPageLoading/FullPageLoading';

export function ReviewCompletedPage() {
  const { completedRoutineId } = useParams() as { completedRoutineId: string };
  const history = useHistory();
  const { data: routine, isLoading: routineLoading } =
    useCompleteRoutineControllerGetCompletedRoutineQuery({
      id: completedRoutineId,
    });
  const [tasks, setTasks] = useState<CompletedTask[]>([]);
  const [passedTasks, setPassedTasks] = useState<CompletedTask[]>([]);
  const [notAttempted, setNotAttempted] = useState<CompletedTask[]>([]);

  const { profile } = useProfile();
  const [updateCompletedRoutine, { isLoading }] =
    useCompleteRoutineControllerUpdateCompleteRoutineMutation();
  const [currentFeedback, setCurrentFeedback] = useState<string>('');
  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    if (profile && routine) {
      // Even though we have secured this on the BE, we need to make sure that the user is allowed to view this page
      // if the profile doesn't have a caretaker, they aren't a caretaker, so they shouldn't be here
      if (!profile.caretaker) {
        history.push('/');
      }
      // the routine itself should be associated with the profile.  If it doesn't, redirect.
      const { routine: currentRoutine } = routine;
      const found =
        currentRoutine.caretakerId === profile.caretaker?.id
          ? true
          : currentRoutine.sharedCaretakers.find(
              (c) => c.profileId === profile.id,
            )
          ? true
          : false;
      if (!found) {
        history.push('/');
      }
      // these need to be changed every time the routine changes.  The page doesn't unmount when we navigate, so we need to reset the state
      setTasks(
        routine?.tasks.filter(
          (task) => task.status === 'attempted' || task.status === 'failed',
        ) || [],
      );
      setPassedTasks(
        routine?.tasks.filter((task) => task.status === 'passed') || [],
      );
      setNotAttempted(
        routine?.tasks.filter((task) => task.status === 'notAttempted') || [],
      );
      setCurrentFeedback('');
    }
  }, [profile, routine, history]);

  const showSuccessToast = () => {
    history.push(`/tabs/${routine?.dependentId}/routines`);

    present({
      message: 'Routine updated successfully',
      duration: 2500,
    });
    // reset this component because when we navigate it doesn't unmount.  Thank you ionic.
    setTasks([]);
    setPassedTasks([]);
    setNotAttempted([]);
    setCurrentFeedback('');
  };

  const showErrorToast = () => {
    present({
      message: 'Error updating routine',
      duration: 2500,
    });
  };

  const handleCompletedRoutine = async (starsAwarded: string) => {
    if (!routine) return;
    const newRoutine = {
      ...routine,
      tasks: [...passedTasks, ...tasks].map((task) => ({
        ...task,
        caretakerId: profile.caretaker?.id,
        feedback: task.feedback?.filter((f) => !f.id) || [],
      })),
      feedback: currentFeedback.length
        ? [
            ...routine.feedback,
            {
              caretakerId: profile.caretaker?.id,
              text: currentFeedback,
            },
          ]
        : routine.feedback,
      starsAwarded,
      status: 'passed',
    };
    try {
      await updateCompletedRoutine({
        id: routine.id.toString(),
        completedRoutine: newRoutine as any,
      }).unwrap();
      showSuccessToast();
    } catch {
      showErrorToast();
    }
  };

  const handlePass = () => {
    presentAlert({
      header: 'Routine Passed!',
      message: 'How many stars would you like to award?',
      inputs: [
        {
          label: '0',
          type: 'radio',
          value: 0,
        },
        {
          label: '25',
          type: 'radio',
          value: 25,
        },
        {
          label: '50',
          type: 'radio',
          value: 50,
        },
        {
          label: '100',
          type: 'radio',
          value: 100,
        },
      ],
      buttons: ['Ok'],
      onDidDismiss: (e: CustomEvent) => {
        if (!e.detail.data) return;
        const { values } = e.detail.data;
        handleCompletedRoutine(values);
      },
    });
  };

  const handleFail = async () => {
    if (!routine) return;
    const newRoutine = {
      ...routine,
      tasks: [...passedTasks, ...tasks].map((task) => ({
        ...task,
        caretakerId: task.caretakerId || profile.caretaker?.id,
        feedback: task.feedback?.filter((f) => !f.id) || [],
      })),
      status: 'failed',
      feedback: [
        ...routine.feedback,
        {
          caretakerId: profile.caretaker?.id,
          text: currentFeedback,
        },
      ],
    };
    try {
      await updateCompletedRoutine({
        id: routine.id.toString(),
        completedRoutine: newRoutine as any,
      }).unwrap();
      showSuccessToast();
    } catch {
      showErrorToast();
    }
  };

  const passTask = (passed: CompletedTask) => {
    const newTasks = [...tasks];
    const updatedTasks = newTasks.map((task) =>
      task.id === passed.id ? { ...task, status: 'passed' } : task,
    );
    setTasks(updatedTasks as CompletedTask[]);
  };

  const failTask = (failed: CompletedTask) => {
    const newTasks = [...tasks];
    const updatedTasks = newTasks.map((task) =>
      task.id === failed.id ? { ...task, status: 'failed' } : task,
    );
    setTasks(updatedTasks as CompletedTask[]);
  };

  const changePassedTask = (changedId: number, status: 'passed' | 'failed') => {
    const newTasks = [...passedTasks];
    const updatedTasks = newTasks.map((task) =>
      task.id === changedId ? { ...task, status } : task,
    );
    setPassedTasks(updatedTasks as CompletedTask[]);
  };

  const changeNotAttempted = (
    changedId: number,
    status: 'passed' | 'failed',
  ) => {
    const newTasks = [...notAttempted];
    const updatedTasks = newTasks.map((task) =>
      task.id === changedId ? { ...task, status } : task,
    );
    setNotAttempted(updatedTasks as CompletedTask[]);
  };

  const changeTaskFeedback = (
    feedbackArray: Partial<CompletedTaskFeedback>[],
    feedbackText: string,
  ) => {
    const newFeedbackExists = feedbackArray.find((f) => !f.id);
    let taskFeedback = feedbackArray;
    if (newFeedbackExists) {
      taskFeedback = feedbackArray.map((f) => {
        if (f.id) return f;
        return {
          ...f,
          text: feedbackText,
        };
      });
    } else {
      taskFeedback = [
        ...feedbackArray,
        {
          caretakerId: profile.caretaker?.id,
          text: feedbackText,
        } as CompletedTaskFeedback,
      ];
    }
    return taskFeedback;
  };

  const handleTaskFeedback = (
    task: CompletedTask,
    feedbackText: string,
    index: number,
  ) => {
    const { feedback } = task;
    const updatedFeedback = changeTaskFeedback(feedback, feedbackText);
    const newTasks = [...tasks];
    const updatedTasks = newTasks.map((t, i) =>
      (task.id && t.id === task.id) || index === i
        ? { ...t, feedback: updatedFeedback }
        : t,
    );
    setTasks(updatedTasks as CompletedTask[]);
  };

  const handleCompletedTaskFeedback = (
    task: CompletedTask,
    feedbackText: string,
  ) => {
    const { feedback } = task;
    const updatedFeedback = changeTaskFeedback(feedback, feedbackText);
    const newTasks = [...passedTasks];
    const updatedTasks = newTasks.map((t) =>
      t.id === task.id ? { ...t, feedback: updatedFeedback } : t,
    );
    setPassedTasks(updatedTasks as CompletedTask[]);
  };

  const handleNotAttemptedFeedback = (
    task: CompletedTask,
    feedbackText: string,
  ) => {
    const { feedback } = task;
    const updatedFeedback = changeTaskFeedback(feedback, feedbackText);
    const newTasks = [...notAttempted];
    const updatedTasks = newTasks.map((t) =>
      t.id === task.id ? { ...t, feedback: updatedFeedback } : t,
    );
    setNotAttempted(updatedTasks as CompletedTask[]);
  };

  return (
    <PageWrapper
      title={!routineLoading ? `Review ${routine?.routine.name || ''}` : ''}
      dismissType="back"
      defaultHref={`/tabs/${routine?.dependentId}/routines`}
    >
      <div className="flex flex-col pt-4">
        {routine &&
        routine.id === parseInt(completedRoutineId) &&
        !routineLoading ? (
          <>
            {routine.routine.requirePhoto && routine.photo && (
              <div>
                <Image
                  src={routine.photo}
                  alt={routine.routine.name}
                  className="mx-auto w-3/4 rounded-md shadow-xl"
                />
              </div>
            )}
            {passedTasks.length > 0 && (
              <div className="flex flex-col">
                <h2 className="mt-4 ml-4 text-2xl font-bold">
                  Completed Tasks
                </h2>
                {passedTasks.map((task, index) => (
                  <PassedTask
                    task={task}
                    key={task.id}
                    index={index}
                    onComplete={() => changePassedTask(task.id, 'passed')}
                    onFail={() => changePassedTask(task.id, 'failed')}
                    onTaskFeedbackChange={handleCompletedTaskFeedback}
                  />
                ))}
              </div>
            )}
            {tasks.length > 0 && (
              <div className="flex flex-col">
                <h2 className="mt-4 ml-4 text-2xl font-bold">
                  Tasks to Review
                </h2>
                {tasks.map((task, index) => (
                  <CompleteTask
                    key={task.id}
                    task={task}
                    index={index}
                    onFail={() => failTask(task)}
                    onComplete={() => passTask(task)}
                    onTaskFeedbackChange={handleTaskFeedback}
                    indexOffset={passedTasks.length}
                  />
                ))}
              </div>
            )}
            {notAttempted.length > 0 && (
              <div className="flex flex-col">
                <h2 className="mt-4 ml-4 text-2xl font-bold">
                  Tasks Not Attempted
                </h2>
                {notAttempted.map((task, index) => (
                  <CompleteTask
                    key={task.id}
                    task={task}
                    index={index}
                    onFail={() => changeNotAttempted(task.id, 'failed')}
                    onComplete={() => changeNotAttempted(task.id, 'passed')}
                    onTaskFeedbackChange={handleNotAttemptedFeedback}
                    indexOffset={passedTasks.length + tasks.length}
                  />
                ))}
              </div>
            )}

            {routine.feedback.filter((feedback) => feedback.text.length > 0)
              .length > 0 && (
              <div className="flex flex-col">
                <div className="mt-4 mb-2 ml-4">
                  <span>Previous Feedback</span>
                </div>
                <div className="mb-4 bg-white pt-4">
                  <PreviousFeedback
                    feedback={routine.feedback.filter(
                      (feedback) => feedback.text.length > 0,
                    )}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col px-4">
              <label htmlFor="feedback-input" className="mb-1">
                Feedback
              </label>
              <InputTextarea
                id="feedback-input"
                value={currentFeedback}
                onChange={(e) => setCurrentFeedback(e.target.value)}
                rows={4}
              />
            </div>
            <div className="flex w-full justify-center py-4">
              <div className="flex w-4/5 gap-4">
                <Button
                  onClick={handleFail}
                  label="Needs Work"
                  className="p-button-outlined"
                  disabled={isLoading}
                />
                <Button
                  onClick={handlePass}
                  label="Approve!"
                  disabled={isLoading}
                  loading={isLoading}
                />
              </div>
            </div>
          </>
        ) : (
          <FullPageLoading />
        )}
      </div>
    </PageWrapper>
  );
}

import { useIonToast } from '@ionic/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../../shared/components/Button';
import Image from '../../../../shared/components/Image';
import useProfile from '../../../../shared/hooks/useProfile';
import useStorage from '../../../../shared/hooks/useStorage';
import {
  CompletedTask,
  CreateCompletedRoutinePayload,
  useCompleteRoutineControllerGetCompletedRoutineQuery,
  useRoutineControllerGetRoutineByIdQuery,
} from '../../../../shared/store/api/endpoints';
import {
  useCompleteRoutineControllerCreateCompleteRoutineMutation,
  useCompleteRoutineControllerUpdateCompleteRoutineMutation,
} from '../../../../shared/store/api/endpoint-with-tags';
import AddPhoto from '../../../_components/AddPhoto';
import CompleteTask from '../../../_components/CompleteTask';
import DependentPassedTask from './_components/DependentPassedTask/DependentPassedTask';
import { useHistory, useParams } from 'react-router-dom';
import PageWrapper from '../../../../shared/components/PageWrapper/PageWrapper';
import FullPageLoading from '../../../../shared/components/FullPageLoading/FullPageLoading';

export function CompleteRoutinePage() {
  const { profile } = useProfile();
  const { routineId, completedRoutineId } = useParams() as {
    routineId: string;
    completedRoutineId: string;
  };
  const history = useHistory();
  const { data: routine } = useRoutineControllerGetRoutineByIdQuery({
    id: parseInt(routineId),
  });
  const { data: completedRoutine } =
    useCompleteRoutineControllerGetCompletedRoutineQuery(
      {
        id: completedRoutineId,
      },
      {
        skip: !completedRoutineId,
      },
    );

  useEffect(() => {
    if (profile && routine) {
      // only dependents can access this page
      if (!profile.dependent) {
        history.push('/');
      }
      // only the dependent who this routine belongs to can access this page
      if (routine.dependentId !== profile.dependent?.id) {
        history.push('/');
      }
    }
  }, [routine, profile, history]);

  const pageRef = useRef();

  const [photo, setPhoto] = useState<string | null>(null);
  const [tasks, setTasks] = useState<Partial<CompletedTask>[]>([]);

  useEffect(() => {
    if (completedRoutine) {
      setTasks(
        completedRoutine?.tasks
          .map((task: any) => ({
            ...task,
            status: task.caretakerId ? task.status : 'notAttempted',
          }))
          .filter((task: any) => task.status !== 'passed'),
      );
    } else if (routine) {
      setTasks(
        routine.tasks.map((task: string | CompletedTask) => {
          if (typeof task === 'string') {
            return { name: task, status: 'notAttempted' };
          } else {
            return { ...task };
          }
        }),
      );
    }
  }, [completedRoutine, routine]);

  const passedTasks = useMemo(
    () =>
      completedRoutine?.tasks.filter((task: any) => task.status === 'passed') ||
      [],
    [completedRoutine],
  );

  const [create, { isLoading: createLoading }] =
    useCompleteRoutineControllerCreateCompleteRoutineMutation();

  const [update, { isLoading: updateLoading }] =
    useCompleteRoutineControllerUpdateCompleteRoutineMutation();

  const { uploadFile } = useStorage();
  const [present] = useIonToast();

  const finishRoutine = async () => {
    if (!routine) return;
    let path;
    if (photo) {
      const date = new Date().getTime();
      path = await uploadFile(photo, `${date}.jpeg`, 'image/jpeg');
    }
    if (completedRoutine) {
      try {
        await update({
          id: completedRoutine.id.toString(),
          completedRoutine: {
            ...(completedRoutine as any),
            tasks: [...tasks, ...passedTasks].map((task) => ({
              ...task,
              // feedback should be an empty array so we don't create new feedback from the dependent app
              feedback: [],
            })),
            status: routine?.private ? 'passed' : 'attempted',
            photo: path || completedRoutine.photo || undefined,
          },
        }).unwrap();
        present({
          message: 'Routine resubmitted successfully',
          duration: 3000,
        });
        history.push('/tabs/routines');
        setTasks([]);
        setPhoto(null);
      } catch (error) {
        present({ message: 'Error completing routine', duration: 3000 });
      }
    } else {
      const body: CreateCompletedRoutinePayload = {
        routineId: routine.id,
        dependentId: profile?.dependent?.id as string,
        tasks: tasks,
        photo: path || undefined,
        status: routine.private ? 'passed' : 'attempted',
        privateRoutine: routine.private || false,
      };
      try {
        const payload = await create({
          createCompletedRoutinePayload: body,
        }).unwrap();

        present({
          message: 'Routine completed successfully',
          duration: 3000,
        });
        history.push('/tabs/routines');
        setTasks([]);
        setPhoto(null);
      } catch (error) {
        present({
          message: 'Error completing routine. Please try again.',
          duration: 3000,
        });
      }
    }
  };

  const completeTask = (task: Partial<CompletedTask>, index: number) => {
    if (!routine) return;
    const status =
      task.status === 'passed' || task.status === 'attempted'
        ? 'notAttempted'
        : routine.private
        ? 'passed'
        : ('attempted' as any);
    const newTasks = tasks.map((t, i) => (i === index ? { ...t, status } : t));
    setTasks(newTasks);
  };

  return (
    <PageWrapper title="Complete Routine" ref={pageRef} dismissType="back">
      <div className="py-8">
        {routine && routine.id === parseInt(routineId) ? (
          <>
            <div className="flex flex-col gap-y-1 px-6">
              <span className="text-gray-500">
                It is time to begin your routine
              </span>
              <span className="text-2xl font-bold">{routine.name}</span>
            </div>

            {routine.examplePhoto && (
              <div className="flex flex-col px-6 py-8">
                <span className="text-gray-500">
                  The final result should look like this
                </span>
                <div>
                  <Image
                    src={routine.examplePhoto}
                    alt="example"
                    className="mt-4 rounded-lg shadow-lg"
                  />
                </div>
              </div>
            )}
            {passedTasks.length > 0 && (
              <div className="flex flex-col">
                <h2 className="mt-4 ml-6 text-lg text-gray-500">
                  Completed Tasks
                </h2>
                {passedTasks.map((task: any, index: number) => (
                  <DependentPassedTask
                    task={task}
                    index={index}
                    key={task.id}
                  />
                ))}
              </div>
            )}
            {tasks.length > 0 && (
              <div className="flex flex-col">
                {completedRoutine && (
                  <h2 className="mt-4 ml-6 text-lg text-gray-500">
                    Tasks left to redo
                  </h2>
                )}
                {tasks?.map((task, index) => (
                  <CompleteTask
                    key={index}
                    task={task}
                    index={index}
                    onComplete={() => completeTask(task, index)}
                    indexOffset={passedTasks.length}
                  />
                ))}
              </div>
            )}

            {routine?.requirePhoto && (
              <CompleteTask
                index={routine.tasks.length}
                task={{ name: 'Take a picture of your finished routine' }}
              >
                <AddPhoto
                  photo={photo}
                  addPhoto={(url) => setPhoto(url)}
                  removePhoto={() => setPhoto(null)}
                  alt="Finished Routine"
                  parentModalRef={pageRef}
                />
              </CompleteTask>
            )}
            <div className="flex justify-center py-8">
              <div className="w-4/5">
                <Button
                  loading={updateLoading || createLoading}
                  disabled={
                    (routine.requirePhoto && !photo) ||
                    updateLoading ||
                    createLoading
                  }
                  label="I'm finished!"
                  onClick={() => finishRoutine()}
                />
              </div>
            </div>
          </>
        ) : (
          <FullPageLoading />
        )}
      </div>
    </PageWrapper>
  );
}

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonButton,
  IonButtons,
  IonIcon,
  IonBackButton,
} from '@ionic/react';
import { ReactNode, forwardRef } from 'react';
import { close, home } from 'ionicons/icons';

const PageWrapper: React.FC<{
  title: string;
  children: ReactNode;
  onDismiss?: () => void;
  dismissType?: string;
  ref?: any;
  onAction?: () => void;
  actionIcon?: string;
  contentClass?: string;
  defaultHref?: string;
}> = forwardRef(
  (
    {
      title,
      children,
      onDismiss,
      dismissType,
      onAction,
      actionIcon,
      contentClass,
      defaultHref,
    },
    ref,
  ) => (
    <IonPage ref={ref}>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            {dismissType === 'back' ? (
              <IonButtons slot="start">
                <IonBackButton defaultHref={defaultHref || ''} />
              </IonButtons>
            ) : onDismiss ? (
              dismissType === 'close' ? (
                <IonButton onClick={onDismiss}>
                  <IonIcon icon={close} />
                </IonButton>
              ) : dismissType === 'home' ? (
                <IonButtons slot="start">
                  <IonButton onClick={onDismiss}>
                    <IonIcon icon={home} />
                  </IonButton>
                </IonButtons>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </IonButtons>
          <IonButtons slot="end">
            {onAction && (
              <IonButton onClick={onAction}>
                <IonIcon icon={actionIcon} />
              </IonButton>
            )}
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={contentClass}>
        <IonGrid fixed>{children}</IonGrid>
      </IonContent>
    </IonPage>
  ),
);

export default PageWrapper;

// @ts-ignore
import { Auth0LockPasswordless as Auth0Lock } from 'auth0-lock';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

const useAuth0Lock = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const history = useHistory();
  const lock = new Auth0Lock(
    process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    process.env.REACT_APP_AUTH0_DOMAIN as string,
    {
      allowedConnections: ['sms'],
      auth: {
        redirectUrl: `${window.location.origin}/callback`,
        responseType: 'token id_token',
        params: {
          scope: 'openid email profile',
        },
      },
      theme: {
        logo: 'https://routine-success-app-develop-uuwp2dmh7q-uc.a.run.app/assets/RoutineSuccessLogo.png',
        primaryColor: '#00647b',
      },
      languageDictionary: {
        title: 'Routine Success',
        signUpTerms:
          "I consent to receive messages about routines from Routine Success at the provided number. I accept <a href='https://routine-success.com/terms'>terms of service</a> and <a href='https://routine-success.com/privacy'>privacy policy</a>.  Msg/data rates may apply.  Reply STOP to unsubscribe.",
        showTerms: true,
      },
    },
  );

  const showPasswordlessLogin = () => {
    lock.show();
    lock.on('authenticated', async (authResult: any) => {
      if (!authResult) return;
      /**
       * at this point we are logged in, but we don't have the token stored in
       * a way that can be used by the auth0-react library.
       * Getting token silently fetches the token and saves it as a cookie so
       * the app itself can use it.
       */
      verifyLogin();
    });
  };

  /**
   * This should be used in situations where we log in outside of the usual Auth0 React flow.
   *
   * Similar to how we we handle populating the token so the Auth0 library can consume it
   * we need to getAccessTokenSilently here as well to make sure the token is available.
   *
   */
  const verifyLogin = async () => {
    try {
      await getAccessTokenSilently();
    } catch {
      await getAccessTokenWithPopup();
    }
    history.push('/');
  };

  return {
    showPasswordlessLogin,
    verifyLogin,
  };
};

export default useAuth0Lock;

import { FC } from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { format, isToday } from 'date-fns';
import CompletedStatus from '../CompletedStatus';
import { IonIcon } from '@ionic/react';
import { ellipsisHorizontal } from 'ionicons/icons';

interface ReviewCompletedItemProps {
  // completedRoutine: CompletedRoutine;
  completedRoutine: any;
  handleActionSheet?: () => void;
}

const ReviewCompletedRoutine: FC<ReviewCompletedItemProps> = ({
  completedRoutine,
  handleActionSheet,
}) => (
  <div className="flex flex-col rounded-md bg-white px-4 pt-4 pb-6">
    <div className="flex justify-between">
      <span className="text-lg font-semibold">
        {completedRoutine.routine.name}
      </span>
      {handleActionSheet ? (
        <IonIcon
          icon={ellipsisHorizontal}
          slot="icon-only"
          onClick={handleActionSheet}
        />
      ) : (
        <CompletedStatus status={completedRoutine.status} />
      )}
    </div>
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <CalendarIcon className="h-4 w-4" />
        <span className="text pl-2">
          Completed{' '}
          {isToday(new Date(completedRoutine.createdOn)) ? 'at' : 'on'}{' '}
          {format(
            new Date(completedRoutine.createdOn),
            isToday(new Date(completedRoutine.createdOn)) ? 'p' : 'Pp',
          )}
        </span>
      </div>
      {handleActionSheet && (
        <CompletedStatus status={completedRoutine.status} />
      )}
    </div>
  </div>
);

export default ReviewCompletedRoutine;

import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { RoutineForm } from '../create-routine-modal.types';

const Details: React.FC<{
  routineForm: RoutineForm;
  handleRoutineChange: (val: any, key: string) => void;
  disabled?: boolean;
}> = ({ routineForm, handleRoutineChange, disabled }) => {
  const { name, startDate, repeats } = routineForm;
  return (
    <>
      <div className="flex flex-col">
        <label className="mb-1" htmlFor="routine-name">
          Routine name
        </label>
        <InputText
          id="routine-name"
          className="w-full"
          value={name}
          onChange={(e) => handleRoutineChange(e.target.value, 'name')}
        />
      </div>
      <div className="flex items-center gap-x-2">
        <div className="mt-4 flex w-2/3 flex-col">
          <label htmlFor="startDate" className="mb-1">
            Start date & time
          </label>
          <Calendar
            disabled={disabled}
            id="startDate"
            value={startDate}
            onChange={(e) =>
              e.value && handleRoutineChange(e.value as Date, 'startDate')
            }
            showIcon
            showTime
            hourFormat="12"
          />
        </div>
        <div className="mt-6 flex w-1/3 items-center justify-center gap-2 pt-4">
          <Checkbox
            disabled={disabled}
            inputId="repeats"
            value={repeats}
            onChange={() => handleRoutineChange(!repeats, 'repeats')}
            checked={repeats}
          />
          <label htmlFor="repeats" className="text-lg">
            Repeats
          </label>
        </div>
      </div>
    </>
  );
};

export default Details;

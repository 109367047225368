import { useState } from 'react';
import FormInput from '../../../shared/components/FormInput';
import Button from '../../../shared/components/Button';
import { useForm } from 'react-hook-form';
import FormInputWithMask from '../../../shared/components/FormInputWithMask';
import { useIonToast } from '@ionic/react';
import AddProfilePhoto from '../../../shared/components/AddProfilePhoto';
import useStorage from '../../../shared/hooks/useStorage';

import { useProfileControllerUpdateProfileMutation } from '../../../shared/store/api/endpoint-with-tags';
import { Profile, UpdateProfile } from '../../../shared/store/api/endpoints';
import ModalWrapper from '../../../shared/components/ModalWrapper/ModalWrapper';

const EditProfile = ({
  parentModalRef,
  onDismiss,
  profile,
}: {
  parentModalRef: any;
  onDismiss: () => void;
  profile: Profile;
}) => {
  const { uploadFile } = useStorage();
  //Form state
  const {
    control,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || '',
      phone: profile?.phone || '',
    },
  });

  //State
  const [photo, setPhoto] = useState<string | null>(profile?.photo || null);
  const [fileUploading, setFileUploading] = useState(false);
  //Methods
  const [updateProfile] = useProfileControllerUpdateProfileMutation();
  const [present] = useIonToast();

  const handleSave = async () => {
    try {
      let path;
      if (photo) {
        setFileUploading(true);
        const date = new Date().getTime();
        path = await uploadFile(photo, `${date}.jpeg`, 'image/jpeg');
        setFileUploading(false);
      }
      if (profile && profile.id) {
        const data: UpdateProfile = {
          id: profile.id,
          firstName: getValues('firstName'),
          lastName: getValues('lastName'),
          phone: getValues('phone'),
          photo: path,
        };
        updateProfile({ updateProfile: data });
      }
      //Close the edit modal
      onDismiss();
    } catch (error) {
      console.error(error);
      present('Whoops, something went wrong. Please try again 😁', 5000);
    }
  };

  const handleNewImage = (image: string) => {
    setPhoto(image);
  };

  return (
    <ModalWrapper title="Edit Profile" onDismiss={onDismiss}>
      <main className="mt-12 px-8">
        <section>
          <AddProfilePhoto
            photo={photo}
            addPhoto={handleNewImage}
            removePhoto={() => setPhoto(null)}
            alt="Profile photo"
            parentModalRef={parentModalRef}
            width="120"
          />
          <FormInput
            label="First name"
            name="firstName"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'First name is required',
              },
            }}
            errors={errors}
          />
          <FormInput
            label="Last name"
            name="lastName"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Last name is required',
              },
            }}
            errors={errors}
          />
          <FormInputWithMask
            label="Phone"
            name="phone"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Phone is required',
              },
            }}
            errors={errors}
            mask="(999) 999-9999"
          />
        </section>
        <section className="mt-8 flex gap-x-4">
          <Button
            className="grow"
            onClick={() => handleSave()}
            loading={fileUploading}
            disabled={fileUploading}
            label="Save"
          />
        </section>
        <section className="my-8 flex gap-x-4">
          <div>
            <h2 className="text-lg font-bold text-slate-700 dark:text-slate-500">
              Privacy
            </h2>
            {window.localStorage?.getItem('__ga_optOut_G-H5CCMB6WTS') && (
              <p className="font-bold">
                {' '}
                You have opted out of Analytics tracking
              </p>
            )}
            <p>
              This app uses Google Analytics as well as other tools and services
              to ensure an optimal experience. Additional privacy information
              for Google Analytics is available{' '}
              <a
                className="underline"
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              . Opt out of analytics tracking by clicking{' '}
              <a
                href="#privacy"
                className="underline"
                onClick={() => {
                  window.localStorage?.setItem(
                    '__ga_optOut_G-H5CCMB6WTS',
                    'true',
                  );
                  window.location.reload();
                }}
              >
                here
              </a>
              .
            </p>
          </div>
        </section>
      </main>
    </ModalWrapper>
  );
};

export default EditProfile;

import { UserCircleIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import { FC } from 'react';
import Button from '../../../../../shared/components/Button';
import Image from '../../../../../shared/components/Image';
import ModalWrapper from '../../../../../shared/components/ModalWrapper';
import { fadeIn } from '../../../../../shared/helpers/animation';
import { getCaretakerTitle } from '../../../../../shared/helpers/relationship-title';
import { Caretaker } from '../../../../../shared/store/api/endpoints';

interface ApproveCaretakerModalProps {
  onDismiss: () => void;
  caretaker: Caretaker;
  relationshipId: number;
  handleApprove: (approved: boolean) => void;
}

const ApproveCaretakerModal: FC<ApproveCaretakerModalProps> = ({
  onDismiss,
  caretaker,
  relationshipId,
  handleApprove,
}) => (
  <ModalWrapper title="Approve Caretaker" onDismiss={onDismiss}>
    <motion.main
      className="mt-8 flex h-full flex-col justify-between"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <section className="mt-12 flex flex-col items-center pb-8">
        <span className="p-4 text-center text-2xl">
          {caretaker.profile?.firstName} has added you to their account.
        </span>
        <h2 className="text-primary-700 text-2xl font-bold">
          Is {caretaker.profile?.firstName} one of your{' '}
          {relationshipId === 1 ? 'mentors' : 'parents'}?
        </h2>
        <div className="mt-6 flex items-center justify-center gap-x-6 rounded-md border border-gray-200 px-8 py-4 shadow-lg">
          <div>
            {caretaker?.profile?.photo ? (
              <Image
                src={caretaker?.profile?.photo || ''}
                className="ring-primary-700 w-16 rounded-full text-gray-300 ring-2 ring-offset-4"
                alt="Caretaker profile photo"
              />
            ) : (
              <UserCircleIcon className="w-16 text-gray-400" />
            )}
          </div>
          <div>
            <p className="text-xl font-semibold text-gray-800">
              {caretaker.profile?.firstName} {caretaker.profile?.lastName}
            </p>
            <p className="text-sm font-light">
              {getCaretakerTitle(relationshipId)}
            </p>
          </div>
        </div>
      </section>
      <section className="mt-8 flex justify-center">
        <div className="flex w-4/5 gap-x-4">
          <Button
            className="p-button-outlined grow"
            onClick={() => handleApprove(false)}
            label="No"
          />
          <Button
            className="grow"
            onClick={() => handleApprove(true)}
            label="Yes"
          />
        </div>
      </section>
    </motion.main>
  </ModalWrapper>
);

export default ApproveCaretakerModal;

import { useContext, useRef, useState } from 'react';
import PageWrapper from '../../../../shared/components/PageWrapper';
import RewardPreview from '../../../_components/RewardPreview';
import RoutineEmptyState from '../../components/RoutineEmptyState/RoutineEmptyState';
import RewardEmptyState from '../../components/RewardEmptyState';
import { ellipsisVertical } from 'ionicons/icons';
import {
  IonModal,
  NavContext,
  useIonActionSheet,
  useIonAlert,
} from '@ionic/react';
import CreateGoalModal from '../../components/CreateGoalModal';
import {
  Dependent,
  Profile,
  Routine,
} from '../../../../shared/store/api/endpoints';
import useRoutines from '../../../../shared/hooks/useRoutines';
import { useAuth0 } from '@auth0/auth0-react';
import useGoals from '../../../../shared/hooks/useGoals';
import { useParams } from 'react-router-dom';
import useDependent from '../../../../shared/hooks/useDependent';
import { CaretakerCreateRoutineModal } from '../../components/CaretakerCreateRoutineModal';
import useProfile from '../../../../shared/hooks/useProfile';
import { EditOccurrenceModal } from '../../../_components/edit-occurrence-modal';
import RoutineCalendar from '../../../_components/RoutineCalendar';

export default function CaretakerSchedulePage() {
  // hooks
  const { logout } = useAuth0();
  const { id } = useParams() as { id: string };
  const dependent = useDependent(id) as Dependent;
  const { profile } = useProfile();
  const {
    routines,
    setRangeStart,
    setRangeEnd,
    rangeCompleted,
    lastCompleted,
  } = useRoutines(dependent?.id, false, profile?.caretaker?.id);
  const { nextGoal, starsAchieved } = useGoals(dependent?.id);
  const { navigate } = useContext(NavContext);
  const [showAlert] = useIonAlert();

  const handleRangeChange = (start: Date, end: Date) => {
    // if (isSameWeek(start, rangeStart) || isSameWeek(end, rangeEnd)) return;
    // this is needed because set state is called in the mobiscroll component which messes things up
    setTimeout(() => {
      setRangeStart(start);
      setRangeEnd(end);
    });
  };

  //Refs
  const scheduleRootRef = useRef<any>(null);
  const routineModalRef = useRef<HTMLIonModalElement>(null);
  const editOccurrenceModalRef = useRef<HTMLIonModalElement>(null);

  // edit routine
  const [showCreateRoutineModal, setShowCreateRoutineModal] = useState(false);
  const [selectedRoutineToEdit, setSelectedRoutineToEdit] = useState<Routine>();
  const [showEditOccurrenceModal, setShowEditOccurrenceModal] = useState(false);
  const [selectedOccurrenceToEdit, setSelectedOccurrenceToEdit] = useState<{
    routine: Routine;
    start: Date;
  }>();

  const [present] = useIonActionSheet();
  const handleActionSheet = () => {
    present([
      {
        text: 'Create a new routine',
        handler: () => {
          setShowRoutineModal(true);
        },
      },
      {
        text: 'Add a reward',
        handler: () => {
          setShowGoalModal(true);
        },
      },
      {
        text: 'Log out',
        handler: () => {
          logout({ returnTo: window.location.origin });
        },
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  const scheduleRoutineModalRef = useRef<HTMLIonModalElement>(null);
  const [showRoutineModal, setShowRoutineModal] = useState(false);

  const goalModalRef = useRef<HTMLIonModalElement>(null);
  const [showGoalModal, setShowGoalModal] = useState(false);

  return (
    <PageWrapper
      title={`${dependent?.profile?.firstName}'s Schedule`}
      ref={scheduleRootRef}
      onAction={handleActionSheet}
      actionIcon={ellipsisVertical}
      onDismiss={() => navigate('/', 'back')}
      dismissType="home"
    >
      {dependent && (
        <>
          {routines === undefined || routines.length === 0 ? (
            <RoutineEmptyState rootRef={scheduleRootRef} showCalendar />
          ) : (
            <RoutineCalendar
              routines={routines}
              rangeChange={handleRangeChange}
              completed={rangeCompleted}
              editRoutine={(routine) => {
                setSelectedRoutineToEdit(routine);
                setShowCreateRoutineModal(true);
              }}
              editOccurrence={({ routine, start }) => {
                // @ts-ignore - last completed is the wrong type
                if (
                  !routine.repeatInterval &&
                  // @ts-ignore - last completed is the wrong type
                  lastCompleted?.find((r) => r.routine_id === routine.id)
                ) {
                  showAlert({
                    header: 'Cannot edit routine',
                    message:
                      'This routine does not repeat and has been previously completed so it cannot be edited. Please create a new routine.',
                    buttons: ['OK'],
                  });
                  return;
                }
                setSelectedOccurrenceToEdit({ routine, start });
                setShowEditOccurrenceModal(true);
              }}
            />
          )}
          <div className="px-6">
            {/* Goals */}
            {routines && routines.length === 0 && !nextGoal && (
              <hr className="mx-auto my-4 w-4/5 border" />
            )}

            {nextGoal ? (
              <RewardPreview
                goal={nextGoal}
                starsAchieved={starsAchieved}
                dependentProfile={dependent.profile}
              />
            ) : (
              <RewardEmptyState
                rootRef={scheduleRootRef}
                dependentProfile={dependent.profile as Profile}
              />
            )}
          </div>
        </>
      )}
      <IonModal
        ref={scheduleRoutineModalRef}
        isOpen={showRoutineModal}
        canDismiss={true}
        presentingElement={scheduleRootRef.current || undefined}
        onDidDismiss={() => setShowRoutineModal(false)}
      >
        {showRoutineModal && (
          <CaretakerCreateRoutineModal
            onDismiss={() => setShowRoutineModal(false)}
            parentModalRef={scheduleRoutineModalRef}
          />
        )}
      </IonModal>
      <IonModal
        ref={goalModalRef}
        isOpen={showGoalModal}
        canDismiss={true}
        presentingElement={scheduleRootRef.current || undefined}
        onDidDismiss={() => setShowGoalModal(false)}
      >
        {showGoalModal && (
          <CreateGoalModal
            onDismiss={() => setShowGoalModal(false)}
            parentModalRef={goalModalRef}
          />
        )}
      </IonModal>
      <IonModal
        ref={routineModalRef}
        isOpen={showCreateRoutineModal}
        canDismiss={true}
        onDidDismiss={() => {
          setSelectedRoutineToEdit(undefined);
          setShowCreateRoutineModal(false);
        }}
      >
        {showCreateRoutineModal && (
          <CaretakerCreateRoutineModal
            onDismiss={() => {
              setSelectedRoutineToEdit(undefined);
              setShowCreateRoutineModal(false);
            }}
            parentModalRef={scheduleRootRef}
            routine={selectedRoutineToEdit}
          />
        )}
      </IonModal>

      <IonModal
        ref={editOccurrenceModalRef}
        isOpen={showEditOccurrenceModal}
        canDismiss={true}
        onDidDismiss={() => {
          setShowEditOccurrenceModal(false);
          setSelectedRoutineToEdit(undefined);
        }}
      >
        {showEditOccurrenceModal && selectedOccurrenceToEdit && (
          <EditOccurrenceModal
            routine={selectedOccurrenceToEdit.routine}
            start={selectedOccurrenceToEdit.start}
            onDismiss={() => {
              setShowEditOccurrenceModal(false);
              setSelectedRoutineToEdit(undefined);
            }}
          />
        )}
      </IonModal>
    </PageWrapper>
  );
}

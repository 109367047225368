import { format, parse } from 'date-fns';
import { DateRange } from '../../main-app/_components/progress-sort-and-filter-header';
import { parseDate } from './dates';

export function formatInUSD(value: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value);
}

export function formatInUSDNearestDollar(value: number) {
  return formatInUSDNoCents(Math.round(value));
}

export function formatInUSDNoCents(value: number) {
  return formatInUSD(value).replace('.00', '');
}

export function formattedTimeFrame(dateRange: DateRange) {
  if (dateRange.timeFrame === 'Week') {
    return `${format(
      parse(dateRange.startDate, 'yyyy-MM-dd', new Date()),
      'MMM dd',
    )} - ${format(
      parse(dateRange.endDate, 'yyyy-MM-dd', new Date()),
      'MMM dd, yyyy',
    )}`;
  }
  if (dateRange.timeFrame === 'Month') {
    return `${format(
      parse(dateRange.startDate, 'yyyy-MM-dd', new Date()),
      'MMMM yyyy',
    )}`;
  }
  if (dateRange.timeFrame === 'Year') {
    return `${format(
      parse(dateRange.startDate, 'yyyy-MM-dd', new Date()),
      'yyyy',
    )}`;
  }
  return `${format(parseDate(dateRange.startDate), 'MMM dd, yyyy')} - ${format(
    parseDate(dateRange.endDate),
    'MMM dd, yyyy',
  )}`;
}

import { InputSwitch } from 'primereact/inputswitch';
import { Caretaker } from '../../../../shared/store/api/endpoints';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';

interface ShareWithMentorsProps {
  label: string;
  isPrivate: boolean;
  handlePrivateChange: (value: boolean) => void;
  handleSelectedMentorChange: (sharedCaretakers: string[]) => void;
  caretakers: Caretaker[];
  sharedCaretakers: string[];
}

export function ShareWithMentors({
  label,
  isPrivate,
  handlePrivateChange,
  handleSelectedMentorChange,
  caretakers,
  sharedCaretakers,
}: ShareWithMentorsProps) {
  const onSelectedMentorChange = (e: CheckboxChangeParams) => {
    if (e.checked) {
      handleSelectedMentorChange([...sharedCaretakers, e.value.id]);
    } else {
      handleSelectedMentorChange(
        sharedCaretakers.filter((id) => id !== e.value.id),
      );
    }
  };
  return (
    <div className="w-full">
      <div className="my-6 flex flex-col rounded-lg border border-gray-200 bg-white py-4 px-6 font-semibold">
        <div className="flex items-center justify-between">
          <label htmlFor="private" className="p-checkbox-label">
            {!isPrivate ? (
              <i className="pi pi-check mr-2" />
            ) : (
              <i className="pi pi-lock mr-2" />
            )}
            {label}
          </label>
          <InputSwitch
            checked={!isPrivate}
            onChange={(e) => handlePrivateChange(e.value)}
            id="private"
            disabled={caretakers.length === 0}
          />
        </div>
        {!isPrivate && (
          <div className="flex flex-col gap-6 pt-6">
            {caretakers?.map((caretaker) => (
              <div
                className="flex items-center justify-between"
                key={caretaker.id}
              >
                <label>
                  {caretaker.profile?.firstName} {caretaker.profile?.lastName}
                </label>
                <Checkbox
                  className="!h-6 !w-6"
                  style={{ width: '1.5rem', height: '1.5rem' }}
                  onChange={onSelectedMentorChange}
                  value={caretaker}
                  checked={
                    sharedCaretakers.find((id) => id === caretaker.id)
                      ? true
                      : false
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

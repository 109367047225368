import { Routine, RoutinesReport } from '../../../shared/store/api/endpoints';
import { ProgressSortButton } from './_components/progress-sort-button';
import { ProgressTimeFrameButton } from './_components/progress-time-frame-button';
import { ProgressFilterButton } from './_components/progress-filter-button';
import { ProgressTimeFrameDisplay } from './_components/progress-time-frame-display';

export interface DateRange {
  timeFrame: 'Week' | 'Month' | 'Year' | undefined;
  startDate: string;
  endDate: string;
}

export interface ProgressSortAndFilterHeaderProps {
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  isMobile?: boolean;
  routines: Routine[];
  selectedRoutines: string[];
  setSelectedRoutines: (selectedRoutines: string[]) => void;
  selectedStatus: string[];
  setSelectedStatus: (selectedStatus: string[]) => void;
  selectedFrequency: string[];
  setSelectedFrequency: (selectedFrequency: string[]) => void;
  changeSort: (column: keyof RoutinesReport) => void;
  sort: { direction: 'asc' | 'desc' | undefined; column: keyof RoutinesReport };
}

/**
 * Todo - this component can be refactored into smaller components for better readability
 */
export function ProgressSortAndFilterHeader({
  dateRange,
  setDateRange,
  isMobile = false,
  routines,
  selectedRoutines,
  setSelectedRoutines,
  selectedStatus,
  setSelectedStatus,
  selectedFrequency,
  setSelectedFrequency,
  changeSort,
  sort,
}: ProgressSortAndFilterHeaderProps) {
  return (
    <div className="flex flex-col gap-4 pt-8">
      <div className="flex justify-center">
        <div className="flex w-[400px] gap-4">
          {isMobile && (
            <ProgressSortButton changeSort={changeSort} sort={sort} />
          )}
          <ProgressFilterButton
            routines={routines}
            selectedRoutines={selectedRoutines}
            setSelectedRoutines={setSelectedRoutines}
            dateRange={dateRange}
            setDateRange={setDateRange}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedFrequency={selectedFrequency}
            setSelectedFrequency={setSelectedFrequency}
          />
          <ProgressTimeFrameButton
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </div>
      </div>
      <ProgressTimeFrameDisplay
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
    </div>
  );
}

import { Button } from 'primereact/button';
import { RoutinesReport } from '../../../shared/store/api/endpoints';
import { usePagination } from '../../dependent/pages/DependentProgressPage/_hooks/use-pagination';
import { readableFrequency } from '../../dependent/pages/DependentProgressPage/utils/frequency-string';
import { useHistory } from 'react-router-dom';
import { MobileTableWrapper } from '../mobile-table-wrapper/mobile-table-wrapper';
import { MobileTR } from '../mobile-tr';

export function MobileProgressTable({ data }: { data: RoutinesReport[] }) {
  const { itemsPerPage, handleItemsPerPageChange, paginatedData } =
    usePagination({ data });
  const history = useHistory();

  function handleShowMore() {
    handleItemsPerPageChange(itemsPerPage + 5);
  }

  function handleView(routineId: number) {
    history.push(`/routine-detail/${routineId}`);
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      {paginatedData.map((routineReport) => (
        <MobileTableWrapper key={routineReport.id}>
          <MobileTR label="Routine" value={routineReport.name} />
          <MobileTR
            label="Frequency"
            value={readableFrequency(routineReport.frequency)}
          />
          <MobileTR label="Assigned" value={routineReport.assigned} />
          <MobileTR label="Attempted" value={routineReport.completed} />
          <MobileTR label="Missed" value={routineReport.incomplete} />
          <MobileTR
            label="% Attempted"
            value={`${routineReport.percentComplete}%`}
          />
          <tr className="bg-white">
            <td colSpan={2} className="p-2 text-center">
              <Button
                className="p-button-text"
                label="View"
                onClick={() => handleView(routineReport.id)}
              />
            </td>
          </tr>
        </MobileTableWrapper>
      ))}
      {paginatedData.length < data.length && (
        <Button
          onClick={handleShowMore}
          label="Show More"
          className="p-button-text"
        />
      )}
    </div>
  );
}

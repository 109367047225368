import { fadeIn } from '../../../../../shared/helpers/animation';
import { motion } from 'framer-motion';
import { Dropdown } from 'primereact/dropdown';
import { RoutineForm } from '../../create-routine-modal.types';

const MonthOptions: React.FC<{
  routineForm: RoutineForm;
  handleRoutineChange: (val: any, key: string) => void;
  disabled?: boolean;
}> = ({ routineForm, handleRoutineChange, disabled }) => {
  const dropdownOptions = [
    ...Array.from(Array(31).keys()).map((i) => ({
      label:
        i + 1 >= 29 ? `${i + 1} (Only if month has ${i + 1} days)` : `${i + 1}`,
      value: (i + 1).toString(),
    })),
    { label: 'Last day of month', value: (-1).toString() },
  ];

  return (
    <motion.div
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className="flex flex-col py-4"
      layout
    >
      <p className="mb-1">Select day of month</p>
      <Dropdown
        disabled={disabled}
        value={routineForm.repeatOptions}
        options={dropdownOptions}
        onChange={(e) => handleRoutineChange(e.value, 'repeatOptions')}
      />
    </motion.div>
  );
};

export default MonthOptions;

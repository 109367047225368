import { InputText } from 'primereact/inputtext';
import Button from '../../../../shared/components/Button';
import { RoutineForm } from '../create-routine-modal.types';

const Tasks: React.FC<{
  routineForm: RoutineForm;
  handleRoutineChange: (val: any, key: string) => void;
}> = ({ routineForm, handleRoutineChange }) => {
  const { tasks } = routineForm;
  const handleTaskChange = (value: string, index: number) => {
    const newTasks = [...tasks];
    newTasks[index] = value;
    handleRoutineChange(newTasks, 'tasks');
  };

  const handleTaskDelete = (index: number) => {
    const newTasks = [...tasks];
    newTasks.splice(index, 1);
    handleRoutineChange(newTasks, 'tasks');
  };

  return (
    <div className="flex w-full flex-col py-4">
      {tasks?.map((task, index) => (
        <div key={index} className="p-field pb-8">
          <p>Task {index + 1}</p>
          <div className="p-inputgroup">
            <InputText
              id={`task-${index}`}
              className="w-full"
              onChange={(e) => handleTaskChange(e.target.value, index)}
              value={task}
            />
            <Button
              icon="pi pi-trash"
              className="p-button-danger"
              onClick={() => handleTaskDelete(index)}
            />
          </div>
        </div>
      ))}
      <div>
        <Button
          label="Add a task"
          className="p-button-outlined"
          onClick={() => handleRoutineChange([...tasks, ''], 'tasks')}
        />
      </div>
    </div>
  );
};

export default Tasks;

import { ChevronRightIcon } from '@heroicons/react/24/solid';

interface FilterOptionProps {
  name: string;
  value: string;
  selectedText: string;
  setSelectedFilter: (selectedFilter: string) => void;
}
export function FilterOption({
  name,
  value,
  selectedText,
  setSelectedFilter,
}: FilterOptionProps) {
  return (
    <div className="flex flex-col">
      <div
        className="flex cursor-pointer items-center justify-between py-2 px-4 hover:bg-neutral-300 hover:bg-opacity-20 hover:text-black"
        onClick={() => setSelectedFilter(value)}
      >
        <span>{name}</span>
        <ChevronRightIcon className="h-4 w-4" />
      </div>
      <span className="px-6 pb-2 text-xs font-semibold">{selectedText}</span>
    </div>
  );
}

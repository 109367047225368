import { SelectButton } from 'primereact/selectbutton';
import { RoutineForm } from '../create-routine-modal.types';

const ReminderOptions: React.FC<{
  routineForm: RoutineForm;
  handleRoutineChange: (val: any, key: string) => void;
}> = ({ routineForm, handleRoutineChange }) => {
  const { reminder } = routineForm;

  // values are currently the time in seconds.
  const selectOptions = [
    { name: 'None', value: null },
    { name: '5 min', value: 300 },
    { name: '10 min', value: 600 },
    {
      name: routineForm.repeatInterval === 'day' ? '1 hour' : '1 day',
      value: routineForm.repeatInterval === 'day' ? 3600 : 86400,
    },
  ];

  return (
    <div className="flex flex-col py-4">
      <p className="mb-1">Send reminder after</p>
      <SelectButton
        options={selectOptions}
        value={reminder}
        onChange={(e) => handleRoutineChange(e.value, 'reminder')}
        optionLabel="name"
        className="flex"
      />
    </div>
  );
};

export default ReminderOptions;

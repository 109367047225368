import { format, isToday, setDate, setDay } from 'date-fns';
import { CalendarIcon } from '@heroicons/react/24/solid';
import { Routine } from '../../../shared/store/api/endpoints';
import { ellipsisHorizontal, lockClosed } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

const RoutineItem: React.FC<{
  routine: Routine;
  handleActionSheet?: () => void;
  isCaretaker: boolean;
}> = ({ routine, handleActionSheet, isCaretaker }) => {
  const renderRoutineTime = (routine: Routine) => {
    let interval;
    if (routine.repeats) {
      switch (routine.repeatInterval) {
        case 'day':
          interval = 'Daily';
          break;
        case 'week':
          const days = routine.repeatOptions
            ?.split(',')
            .map((day: string) =>
              format(setDay(new Date(), parseInt(day)), 'EEEE'),
            );
          interval = `Every ${days?.join(', ')}`;
          break;
        case 'month':
          const day = format(
            setDate(
              new Date(routine.startDate),
              parseInt(routine.repeatOptions as string),
            ),
            'do',
          );
          interval = `Monthly on the ${day}`;
          break;
        default:
          interval = format(new Date(routine.startDate), 'MM/dd/yyyy');
          break;
      }
    } else {
      interval = isToday(new Date(routine.startDate))
        ? 'Today'
        : format(new Date(routine.startDate), 'MM/dd/yyyy');
    }
    const time = format(new Date(routine.startDate), 'h:mm a');
    return (
      <div className="flex text-xs text-gray-500">
        <span>
          <CalendarIcon className="mb-1 mr-1 inline h-3 w-3" />
          {interval}, {time}
        </span>
      </div>
    );
  };

  const showActionSheet = () => {
    if (handleActionSheet) {
      if (isCaretaker) {
        if (routine.caretakerId) {
          return true;
        } else {
          return false;
        }
      } else {
        if (routine.caretakerId) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  return (
    <div className="flex w-full border-t border-solid border-gray-200 bg-white p-4">
      <div className="flex w-2/3 flex-col gap-y-1">
        <span className="text-lg font-semibold leading-6 text-gray-900">
          {routine.name}
        </span>
        {renderRoutineTime(routine)}
      </div>
      <div className="flex w-1/3 justify-between">
        <span className="pl-4 text-sm font-semibold text-gray-900">
          {routine.tasks?.length}
        </span>
        {showActionSheet() ? (
          <IonIcon icon={ellipsisHorizontal} onClick={handleActionSheet} />
        ) : (
          <IonIcon icon={lockClosed} />
        )}
      </div>
    </div>
  );
};

export default RoutineItem;

import React, { FC } from 'react';
import StatusPill from '../StatusPill';

interface GoalStatusProps {
  status: 'pending' | 'received' | 'notStarted';
}

const GoalStatus: FC<GoalStatusProps> = ({ status }) => {
  switch (status) {
    case 'pending':
      return <StatusPill label="Pending" color="yellow" />;
    case 'received':
      return <StatusPill label="Received" color="green" />;
    case 'notStarted':
      return <StatusPill label="Not started" color="blue" />;
    default:
      return <></>;
  }
};

export default GoalStatus;

import { IonIcon, IonModal } from '@ionic/react';
import { motion } from 'framer-motion';
import useCamera from '../../../shared/hooks/useCamera';
import { trash } from 'ionicons/icons';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import Cropper from '../../../shared/components/Cropper';
import useStorage from '../../../shared/hooks/useStorage';
import { fadeIn } from '../../../shared/helpers/animation';
import ModalWrapper from '../../../shared/components/ModalWrapper/ModalWrapper';

const AddPhoto: React.FC<{
  photo: string | null;
  addPhoto: (url: string) => void;
  removePhoto: () => void;
  alt: string;
  parentModalRef: any;
}> = ({ photo, addPhoto, removePhoto, alt, parentModalRef }) => {
  const { getPhoto } = useCamera();
  const [rawPhoto, setRawPhoto] = useState<string | undefined>(undefined);
  const { getReadUrl } = useStorage();
  const [photoUrl, setPhotoUrl] = useState<string>();
  const handlePhoto = async () => {
    const image = await getPhoto();
    setRawPhoto(image.webPath);
    setPhotoModalOpen(true);
  };

  const [photoModalOpen, setPhotoModalOpen] = useState<boolean>(false);

  const handleCancel = () => {
    setPhotoModalOpen(false);
  };

  const handleNewImage = (image: string) => {
    addPhoto(image);
    setPhotoModalOpen(false);
  };

  useEffect(() => {
    if (photo) {
      const retrievePhoto = async () => {
        let url;
        try {
          url = new URL(photo);
          setPhotoUrl(url.toString());
        } catch {
          url = await getReadUrl(photo);
          setPhotoUrl(url);
        }
      };
      retrievePhoto();
    } else {
      setPhotoUrl(undefined);
    }
  }, [photo, getReadUrl]);

  return (
    <motion.div
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      {photoUrl ? (
        <section>
          <div>
            <motion.img
              src={photoUrl}
              alt={alt}
              className="mx-auto w-3/4 rounded-md shadow-xl"
              onClick={handlePhoto}
              whileTap={{ scale: 0.9 }}
            />
          </div>
          <div className="flex items-start justify-center pt-4">
            <button
              className="text flex items-center gap-x-2 font-semibold text-gray-600"
              onClick={removePhoto}
            >
              <IonIcon icon={trash} /> <span>Remove Photo</span>
            </button>
          </div>
        </section>
      ) : (
        <section>
          <motion.div
            whileTap={{ scale: 0.9 }}
            className="flex w-full items-center justify-center rounded-lg border-2 border-dashed border-gray-300 py-8 px-8 "
            onClick={handlePhoto}
          >
            <div className="flex items-center">
              <span className="text flex gap-x-2 font-semibold text-gray-600">
                <PlusCircleIcon className="w-4 " />
                Add Photo
              </span>
            </div>
          </motion.div>
        </section>
      )}
      <IonModal
        isOpen={photoModalOpen}
        onDidDismiss={() => setPhotoModalOpen(false)}
        presentingElement={parentModalRef.current}
      >
        <ModalWrapper title="Add example photo" onDismiss={handleCancel}>
          <div className="mt-8" />
          <Cropper
            image={rawPhoto || '/defaultAvatar.png'}
            handleSave={handleNewImage}
            handleCancel={handleCancel}
          />
        </ModalWrapper>
      </IonModal>
    </motion.div>
  );
};

export default AddPhoto;

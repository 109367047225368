import { FC } from 'react';
import Button from '../../../shared/components/Button';
import { SelectButton } from 'primereact/selectbutton';
import { CompletedTask } from '../../../shared/store/api/endpoints';
import { InputTextarea } from 'primereact/inputtextarea';
import PreviousFeedback from '../../caretaker/pages/CaretakerRoutinesPage/PreviousFeedback';

interface CompleteTaskProps {
  index: number;
  task: Partial<CompletedTask>;
  onComplete?: (index: number) => void;
  onFail?: (index: number) => void;
  onTaskFeedbackChange?: (
    task: CompletedTask,
    feedbackText: string,
    index: number,
  ) => void;
  children?: any;
  indexOffset?: number;
}

const CompleteTask: FC<CompleteTaskProps> = ({
  index,
  task,
  onComplete,
  onFail,
  children,
  onTaskFeedbackChange,
  indexOffset = 0,
}) => {
  const handleCompleteChange = (value: boolean) => {
    if (value && onComplete) {
      onComplete(index);
      return;
    }
    if (!value && onFail) {
      onFail(index);
      return;
    }
  };

  const selectOptions = [
    { name: 'Not done', value: false },
    { name: 'Done', value: true },
  ];

  return (
    <div className="my-4 flex flex-col bg-white p-4 shadow-sm">
      <div className="flex items-center py-2 pb-4">
        <div>
          {/* Added a parent div to avoid distortion of circle when task name is two lines */}
          <div className="flex h-8 w-8 min-w-max items-center justify-center rounded-full bg-primary-navy/50">
            <p className="text-sm font-bold text-white">
              {index + 1 + indexOffset}
            </p>
          </div>
        </div>
        <p className="pl-4 text-xl font-bold leading-5 text-primary-navy">
          {task.name}
        </p>
      </div>
      <div className="flex justify-center">
        {children ? (
          children
        ) : onFail ? (
          <div className="flex w-full flex-col gap-4">
            {task.feedback && task.feedback.length > 0 && (
              <PreviousFeedback feedback={task.feedback.filter((f) => f.id)} />
            )}
            <div className="flex flex-col">
              <label htmlFor="feedback-input" className="mb-1">
                Feedback
              </label>
              <InputTextarea
                id="feedback-input"
                onChange={(e) =>
                  onTaskFeedbackChange &&
                  onTaskFeedbackChange(
                    task as CompletedTask,
                    e.target.value,
                    index,
                  )
                }
                rows={1}
              />
            </div>
            <div className="flex w-full justify-center px-4">
              <SelectButton
                options={selectOptions}
                onChange={(e) => handleCompleteChange(e.value)}
                optionLabel="name"
                className="flex w-full"
                value={task.status === 'passed' ? true : false}
              />
            </div>
          </div>
        ) : (
          <div className="flex w-full flex-col">
            <div>
              {task.feedback && task.feedback.length > 0 && (
                <PreviousFeedback
                  feedback={task.feedback.filter((f) => f.id)}
                />
              )}
            </div>
            <div className="flex w-full justify-center">
              <div className="w-3/4">
                <Button
                  onClick={onComplete}
                  className={
                    task.status === 'notAttempted' || task.status === 'failed'
                      ? 'p-button-outlined'
                      : 'p-button'
                  }
                >
                  <div className="flex w-full items-center justify-center">
                    <i className="pi pi-thumbs-up pb-1" />
                    <span className="pl-2">I'm finished!</span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompleteTask;

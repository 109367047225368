import { useProfileControllerCheckPhoneMutation } from '../store/api/endpoints';

const useFormValidators = () => {
  const [checkPhone] = useProfileControllerCheckPhoneMutation();
  const validatePhone = async (phone: string) => {
    const phoneNumber = phone.replace(/\D/g, '');
    return new Promise(async (resolve, reject) => {
      if (phoneNumber.length < 10) {
        resolve(false);
      } else {
        const exists = await checkPhone({
          checkPhoneBody: { phone },
        });
        resolve(
          !(exists as { data: boolean }).data ||
            'Phone number already registered',
        );
      }
    });
  };

  return {
    validatePhone,
  };
};

export default useFormValidators;

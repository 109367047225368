import { format, isAfter, isToday, startOfMinute } from 'date-fns';
import { FC } from 'react';
import Button from '../../../../../shared/components/Button';
import { RoutineWithDueDate } from '../../../../../shared/types';

interface RoutineDetailItemProps {
  routine: RoutineWithDueDate;
  startRoutine: () => void;
}

const RoutineDetailItem: FC<RoutineDetailItemProps> = ({
  routine,
  startRoutine,
}) => {
  const isTimeForRoutine = checkIfPastDue(routine);

  function checkIfPastDue(routine: RoutineWithDueDate) {
    if (!routine) {
      return false;
    }
    // need to set to start of minute, or else it's possible for the user to see the wrong copy
    const startTime = startOfMinute(
      new Date(routine.dueDate || routine.startDate),
    );

    return isAfter(new Date(), startTime);
  }

  function getTimeStamp() {
    const targetDate = routine.dueDate || new Date(routine.startDate);
    return format(targetDate, isToday(targetDate) ? 'p' : 'P');
  }

  return (
    <div className="flex flex-col rounded-md bg-white px-4 pt-4 pb-6">
      <div>
        <div className="flex items-end justify-between gap-x-3">
          <span className="text-xl font-semibold">{routine.name}</span>
          <span className="text-base text-gray-500">{getTimeStamp()}</span>
        </div>
      </div>
      <div className="flex justify-center pt-6">
        <div className="w-4/5">
          <Button
            label={
              isTimeForRoutine ? 'Start the routine' : 'Start routine early'
            }
            onClick={startRoutine}
          />
        </div>
      </div>
    </div>
  );
};

export default RoutineDetailItem;

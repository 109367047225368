import { InputText } from 'primereact/inputtext';
import { Slider } from 'primereact/slider';
import { FC } from 'react';

interface RewardSliderProps {
  stars: number | string;
  onChange: (starsRequired: number) => void;
}

const RewardSlider: FC<RewardSliderProps> = ({ stars, onChange }) => (
  <div className="flex flex-col pt-4 pb-8">
    <label className="mb-1" htmlFor="stars-required">
      Stars required
    </label>
    <InputText
      id="stars-required"
      className="w-full"
      value={stars}
      type="number"
      onChange={(e) => onChange(parseInt(e.target.value))}
    />
    <div className="mx-4 mt-6">
      <Slider
        value={parseInt(stars as string) / 10}
        onChange={(e) => onChange((e.value as number) * 10)}
      />
    </div>
  </div>
);

export default RewardSlider;

import { motion } from 'framer-motion';
import FormInput from '../../../../shared/components/FormInput';
import { fadeIn } from '../../../../shared/helpers/animation';
import Button from '../../../../shared/components/Button';
import { useForm } from 'react-hook-form';
import { DependentFormType } from '../RegisterCaretaker';
import { CaretakerFormType } from '../RegisterCaretaker';
import FormInputWithMask from '../../../../shared/components/FormInputWithMask';
import FormSelect from '../../../../shared/components/FormSelect';

const RegisterCaretakerStep2 = ({
  setActiveIndex,
  dependentFormState,
  setDependentFormState,
  caretakerFormState,
}: {
  setActiveIndex: (index: number) => void;
  dependentFormState: DependentFormType;
  setDependentFormState: (dependentFormState: DependentFormType) => void;
  caretakerFormState: CaretakerFormType;
}) => {
  const {
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: dependentFormState.firstName,
      lastName: dependentFormState.lastName,
      relationshipId: dependentFormState.relationshipId,
      phone: dependentFormState.phone,
    },
  });

  const handleNext = () => {
    setDependentFormState({
      firstName: getValues().firstName,
      lastName: getValues().lastName,
      relationshipId: getValues().relationshipId,
      phone: getValues().phone,
    });
    setActiveIndex(2);
  };

  //These currently match the options in the db
  const options = [
    { label: 'Mentor', value: 1 },
    { label: 'Dad', value: 2 },
    { label: 'Mom', value: 3 },
  ];

  return (
    <motion.main
      className="mt-8 flex h-full flex-col justify-between"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <section>
        <FormInput
          label="First name"
          name="firstName"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'First name is required',
            },
          }}
          errors={errors}
        />
        <FormInput
          label="Last name"
          name="lastName"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Last name is required',
            },
          }}
          errors={errors}
        />
        <FormSelect
          name="relationshipId"
          label="Relationship to dependent"
          control={control}
          errors={errors}
          options={options}
          rules={{
            required: {
              value: true,
              message: 'Relationship is required',
            },
          }}
        />

        <FormInputWithMask
          label="Phone"
          name="phone"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Phone is required',
            },
            validate: {
              checkDifferentFromCaretakerPhone: (dependentPhone: string) =>
                dependentPhone !== caretakerFormState.phone ||
                'Cannot be the same as your number',
            },
          }}
          errors={errors}
          mask="(999) 999-9999"
        />
      </section>
      <section className="mt-8 flex flex-col items-center gap-y-6">
        {/* Removing skip for the time being, since we don't have any other way to add a dependent currently */}
        {/* <p
          className="text-sm font-bold text-gray-400"
          onClick={() => handleSkip()}
        >
          Skip
        </p> */}
        <Button
          className="grow"
          onClick={() => handleNext()}
          disabled={!isValid}
          label="Continue"
        />
      </section>
    </motion.main>
  );
};

export default RegisterCaretakerStep2;

export function MobileTableWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="overflow-x-auto rounded-lg border-b-[3px] border-[#274693] shadow-lg">
      <div className="h-full w-full">
        <table className="w-full overflow-hidden">
          <tbody>{children}</tbody>
        </table>
      </div>
    </div>
  );
}

export function MobileTR({
  label,
  value,
}: {
  label: string;
  value: string | number | JSX.Element;
}) {
  return (
    <tr className="border-[1px] border-b border-neutral-200">
      <td className="w-[140px] bg-primary-teal bg-opacity-30 p-4 font-bold">
        {label}
      </td>
      <td className="bg-white p-4">{value}</td>
    </tr>
  );
}

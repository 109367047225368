import { IonModal } from '@ionic/react';
import { motion } from 'framer-motion';
import useCamera from '../../hooks/useCamera';
import { PlusCircleIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import useStorage from '../../hooks/useStorage';
import AvatarCropper from '../AvatarCropper';
import ModalWrapper from '../ModalWrapper/ModalWrapper';

const AddProfilePhoto: React.FC<{
  photo: string | null;
  addPhoto: (url: string) => void;
  removePhoto: () => void;
  alt: string;
  parentModalRef: any;
  width?: string | number;
}> = ({
  photo,
  addPhoto,
  removePhoto,
  alt,
  parentModalRef,
  width = '100%',
}) => {
  const { getPhoto } = useCamera();
  const [rawPhoto, setRawPhoto] = useState<string | undefined>(undefined);
  const { getReadUrl } = useStorage();
  const [photoUrl, setPhotoUrl] = useState<string>();
  const handlePhoto = async () => {
    const image = await getPhoto();
    setRawPhoto(image.webPath);
    setPhotoModalOpen(true);
  };

  const [photoModalOpen, setPhotoModalOpen] = useState<boolean>(false);

  const handleCancel = () => {
    setPhotoModalOpen(false);
  };

  const handleNewImage = (image: string) => {
    addPhoto(image);
    setPhotoModalOpen(false);
  };

  useEffect(() => {
    if (photo) {
      const retrievePhoto = async () => {
        let url;
        try {
          url = new URL(photo);
          setPhotoUrl(url.toString());
        } catch {
          url = await getReadUrl(photo);
          setPhotoUrl(url);
        }
      };
      retrievePhoto();
    }
  }, [photo, getReadUrl]);

  return (
    <>
      {photoUrl ? (
        <div>
          <div>
            <motion.img
              src={photoUrl}
              alt={alt}
              width={width}
              className="mx-auto mb-8 rounded-full shadow-md ring-offset-4 drop-shadow-lg"
              onClick={handlePhoto}
              whileTap={{ scale: 0.9 }}
            />
          </div>
        </div>
      ) : (
        <div
          className="mx-auto mb-8 flex flex-col items-center"
          onClick={handlePhoto}
          style={{ width: width }}
        >
          <UserCircleIcon className="w-28 text-gray-300" />
          <span className="flex gap-x-2 text-sm font-semibold text-gray-600">
            <PlusCircleIcon className="w-4 " />
            Add Photo
          </span>
        </div>
      )}
      <IonModal
        isOpen={photoModalOpen}
        onDidDismiss={() => setPhotoModalOpen(false)}
        presentingElement={parentModalRef.current}
      >
        <ModalWrapper title="Add profile photo" onDismiss={handleCancel}>
          <div className="mt-8" />
          <AvatarCropper
            image={rawPhoto || '/defaultAvatar.png'}
            handleSave={handleNewImage}
            handleCancel={handleCancel}
          />
        </ModalWrapper>
      </IonModal>
    </>
  );
};

export default AddProfilePhoto;

import { Checkbox } from 'primereact/checkbox';

interface StatusFilterProps {
  selectedStatus: string[];
  setSelectedStatus: (selectedStatus: string[]) => void;
}

export const statusOptions = [
  {
    name: 'Attempted',
    value: 'attempted',
  },
  {
    name: 'Incomplete',
    value: 'failed',
  },
  {
    name: 'Passed',
    value: 'passed',
  },
];

export function StatusFilter({
  selectedStatus,
  setSelectedStatus,
}: StatusFilterProps) {
  function handleCheckboxChange(status: string) {
    if (selectedStatus.includes(status)) {
      setSelectedStatus(selectedStatus.filter((s) => s !== status));
    } else {
      setSelectedStatus([...selectedStatus, status]);
    }
  }
  return (
    <div className="flex flex-col px-4">
      {statusOptions.map((option) => (
        <div
          key={option.value}
          className="flex items-center justify-between py-2"
        >
          <label htmlFor={`status-${option.value}`} className="mr-2">
            {option.name}
          </label>
          <Checkbox
            inputId={`status-${option.value}`}
            className="mr-2"
            value={selectedStatus.includes(option.name)}
            checked={selectedStatus.includes(option.value)}
            onChange={() => handleCheckboxChange(option.value)}
          />
        </div>
      ))}
    </div>
  );
}

import { ReactNode } from 'react';
import SidebarListItem from './SidebarListItem';

const SidebarLayout = ({
  links,
  children,
}: {
  links: { to: string; label: string }[];
  children: ReactNode;
}) => {
  return (
    <div className="h-screen overflow-hidden">
      <div className="grid grid-cols-1 md:grid-cols-[min-content_1fr] h-full">
        <div className="h-full w-[250px] bg-slate-600 overflow-hidden">
          <nav className="w-full pt-6">
            <ul className="w-full">
              {links.map((link) => (
                <SidebarListItem
                  to={link.to}
                  label={link.label}
                  key={link.to}
                />
              ))}
            </ul>
          </nav>
        </div>
        <div className="h-full">{children}</div>
      </div>
    </div>
  );
};

export default SidebarLayout;

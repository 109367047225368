import { useIonToast } from '@ionic/react';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import Button from '../../../../shared/components/Button';
import ModalWrapper from '../../../../shared/components/ModalWrapper';
import { Goal } from '../../../../shared/store/api/endpoints';
import AddPhoto from '../AddPhoto';
import GoalDetails from './GoalDetails';
import useCreateGoalModal from './useCreateGoalModal';

export interface GoalForm {
  rewardPhoto: string | null;
  name: string;
  starsRequired: string;
}

const CreateGoalModal: React.FC<{
  onDismiss: () => void;
  goal?: Goal;
  parentModalRef: any;
}> = ({ onDismiss, goal, parentModalRef }) => {
  const [goalForm, setGoalForm] = useState<GoalForm>({
    rewardPhoto: goal?.rewardPhoto || null,
    name: goal?.name || '',
    starsRequired: goal?.starsRequired.toString() || '0',
  });

  const [present] = useIonToast();

  const {
    isLoading,
    createSuccess,
    createError,
    saveGoal,
    updateSuccess,
    updateError,
  } = useCreateGoalModal();

  const handleGoalChange = (value: any, key: string) => {
    const newGoalForm: any = { ...goalForm };
    newGoalForm[key] = value;
    setGoalForm(newGoalForm);
  };

  useEffect(() => {
    if (!isLoading) {
      if (createSuccess || updateSuccess) {
        onDismiss();
        present({
          message: goal ? 'Goal updated' : 'Goal created',
          duration: 3000,
        });
      }
      if (createError) {
        // todo add error toast
        console.error('error adding goal');
        present({
          message: 'Error adding goal',
          duration: 3000,
        });
      }
      if (updateError) {
        present({
          message: 'Error updating goal',
        });
      }
    }
  }, [
    isLoading,
    createSuccess,
    createError,
    updateSuccess,
    updateError,
    goal,
    onDismiss,
    present,
  ]);

  return (
    <ModalWrapper
      title={goal ? 'Edit Reward' : 'Add New Reward'}
      onDismiss={onDismiss}
      contentClass="neutral-3"
    >
      <AnimatePresence exitBeforeEnter>
        <LayoutGroup>
          <motion.div layout className="px-6 py-8">
            <div className="pb-4">
              <AddPhoto
                photo={goalForm.rewardPhoto}
                addPhoto={(photo) => handleGoalChange(photo, 'rewardPhoto')}
                removePhoto={() => handleGoalChange(null, 'rewardPhoto')}
                alt="reward"
                parentModalRef={parentModalRef}
              />
            </div>
            <div className="pb-4">
              <GoalDetails
                goalForm={goalForm}
                handleGoalChange={handleGoalChange}
              />
            </div>
            <div className="flex w-full py-4">
              <Button
                label="Save"
                onClick={() => saveGoal(goalForm, goal)}
                loading={isLoading}
                disabled={isLoading}
              />
            </div>
          </motion.div>
        </LayoutGroup>
      </AnimatePresence>
    </ModalWrapper>
  );
};

export default CreateGoalModal;

import { useAuth0 } from '@auth0/auth0-react';
import {
  IonModal,
  NavContext,
  useIonActionSheet,
  useIonAlert,
} from '@ionic/react';
import { TabView, TabPanel } from 'primereact/tabview';
import { useContext, useRef, useState } from 'react';
import FullPageLoading from '../../../../shared/components/FullPageLoading';
import PageWrapper from '../../../../shared/components/PageWrapper';
import useRoutines from '../../../../shared/hooks/useRoutines';
import { useRoutineControllerDeleteRoutineMutation } from '../../../../shared/store/api/endpoint-with-tags';
import { Dependent, Routine } from '../../../../shared/store/api/endpoints';
import RoutineEmptyState from '../../components/RoutineEmptyState/RoutineEmptyState';
import ReviewCompleted from './ReviewCompleted';
import RoutineHeader from './RoutineHeader';
import { ellipsisVertical } from 'ionicons/icons';
import { useParams } from 'react-router-dom';
import useDependent from '../../../../shared/hooks/useDependent';
import RoutinesTab from '../../../_components/RoutinesTab';
import useProfile from '../../../../shared/hooks/useProfile';
import { CaretakerCreateRoutineModal } from '../../components/CaretakerCreateRoutineModal';

export default function CaretakerRoutinesPage() {
  // hooks
  const { logout } = useAuth0();
  const { id } = useParams() as { id: string };
  const dependent = useDependent(id) as Dependent;
  const { profile } = useProfile();
  const { routines, isLoading, isUninitialized, lastCompleted } = useRoutines(
    dependent?.id,
    false,
    profile?.caretaker?.id,
  );
  const [deleteRoutine] = useRoutineControllerDeleteRoutineMutation();
  const [present] = useIonActionSheet();
  const [showAlert] = useIonAlert();
  const { navigate } = useContext(NavContext);

  // state
  const [showModal, setShowModal] = useState(false);
  const [selectedRoutine, setSelectedRoutine] = useState<Routine>();

  // refs
  const routineModalRef = useRef<HTMLIonModalElement>(null);
  const routineRootRef = useRef<any>(null);

  const presentActionSheet = (routine: Routine) => {
    present([
      {
        text: 'Edit',
        handler: () => {
          // @ts-ignore - last completed is the wrong type
          if (
            !routine.repeatInterval &&
            // @ts-ignore - last completed is the wrong type
            lastCompleted?.find((r) => r.routine_id === routine.id)
          ) {
            showAlert({
              header: 'Cannot edit routine',
              message:
                'This routine does not repeat and has been previously completed so it cannot be edited. Please create a new routine.',
              buttons: ['OK'],
            });
            return;
          }
          setSelectedRoutine(routine);
          setShowModal(true);
        },
      },
      {
        text: 'Delete',
        handler: () =>
          showAlert({
            header: 'Delete Routine',
            message:
              'Are you sure you want to delete this routine? This is not reversible.',
            buttons: [
              'Cancel',
              {
                text: 'Yes',
                handler: () => deleteRoutine({ id: routine.id.toString() }),
              },
            ],
          }),
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  const presentRoutinesActionSheet = () => {
    present([
      {
        text: 'Log Out',
        handler: () => {
          logout({ returnTo: window.location.origin });
        },
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  return (
    <PageWrapper
      title={`${dependent?.profile?.firstName}'s Routines`}
      ref={routineRootRef}
      onAction={presentRoutinesActionSheet}
      actionIcon={ellipsisVertical}
      onDismiss={() => navigate('/', 'back')}
      dismissType="home"
    >
      {isLoading || isUninitialized ? (
        <FullPageLoading />
      ) : (
        <div className="bg-white shadow-lg">
          <RoutineHeader
            routines={routines as any}
            dependent={dependent}
            handleAddRoutine={() => {
              setSelectedRoutine(undefined);
              setShowModal(true);
            }}
          />
          <TabView>
            <TabPanel header="All Routines">
              <RoutinesTab
                routines={routines}
                presentActionSheet={presentActionSheet}
                isCaretaker={true}
              />
            </TabPanel>
            <TabPanel header="To Review">
              <ReviewCompleted rootRef={routineRootRef} />
            </TabPanel>
          </TabView>
        </div>
      )}
      {routines === undefined ||
        (routines.length === 0 && (
          <RoutineEmptyState rootRef={routineRootRef} />
        ))}
      <IonModal
        ref={routineModalRef}
        isOpen={showModal}
        canDismiss={true}
        presentingElement={routineRootRef.current || undefined}
        onDidDismiss={() => {
          setSelectedRoutine(undefined);
          setShowModal(false);
        }}
      >
        {showModal && (
          <CaretakerCreateRoutineModal
            onDismiss={() => {
              setSelectedRoutine(undefined);
              setShowModal(false);
            }}
            parentModalRef={routineModalRef}
            routine={selectedRoutine}
          />
        )}
      </IonModal>
    </PageWrapper>
  );
}

import { RoutinesReport } from '../../../shared/store/api/endpoints';
import { ProgressHeader } from '../ProgressHeader';

export function MultipleRoutinesProgressHeader({
  data,
  isMobile = false,
}: {
  data: RoutinesReport[];
  isMobile?: boolean;
}) {
  const completed = data.reduce((acc, curr) => acc + curr.completed, 0);
  const incomplete = data.reduce((acc, curr) => acc + curr.incomplete, 0);
  const percentComplete = completed
    ? Math.round((completed / (completed + incomplete)) * 100)
    : 0;
  return (
    <ProgressHeader
      isMobile={isMobile}
      routines={data.length}
      percentComplete={percentComplete}
      numberCompleted={completed}
      numberIncomplete={incomplete}
    />
  );
}

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useParams } from 'react-router-dom';
import { useCompleteRoutineControllerGetCompletedRoutineQuery } from '../../../shared/store/api/endpoints';
import { format } from 'date-fns';
import Image from '../../../shared/components/Image';
import CompletedStatus from '../../_components/CompletedStatus';
import FullPageLoading from '../../../shared/components/FullPageLoading/FullPageLoading';

export function ViewRoutineHistoryPage() {
  const { completedRoutineId } = useParams() as { completedRoutineId: string };

  const { data, isLoading } =
    useCompleteRoutineControllerGetCompletedRoutineQuery(
      {
        id: completedRoutineId,
      },
      {
        skip: !completedRoutineId,
      },
    );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/routines" />
          </IonButtons>
          <IonTitle>Routine History</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid fixed>
          {data && !isLoading && data.id === parseInt(completedRoutineId) ? (
            <div className="flex flex-col items-center gap-4 pt-8">
              <span className="text-center text-lg font-bold">
                {data.routine.name}
              </span>
              <span>{format(new Date(data.updatedOn), 'Pp')}</span>
              <CompletedStatus status={data.status} />
              {data?.routine.examplePhoto && (
                <>
                  <span>The final result should look like this:</span>
                  <Image src={data.routine.examplePhoto} alt="Example photo" />
                </>
              )}
              {data.photo && (
                <>
                  <div className="flex gap-2 py-4 uppercase">
                    <span>Photo</span>
                    {data?.routine.requirePhoto && <span>(Required)</span>}
                  </div>
                  <Image src={data.photo} alt="Routine photo" />
                </>
              )}

              {data.feedback.length > 0 ? (
                <div className="flex flex-col items-center gap-4 py-4">
                  <span className="uppercase">FEEDBACK</span>
                  {data.feedback
                    .filter((feedback) => feedback.text.length > 0)
                    .map((feedback) => (
                      <span className="px-4 pb-6 font-light" key={feedback.id}>
                        "{feedback.text}" -{' '}
                        {feedback.caretaker?.profile?.firstName}
                      </span>
                    ))}
                </div>
              ) : (
                <div className="flex flex-col items-center gap-4 py-4">
                  <span className="uppercase">FEEDBACK</span>
                  <span className="px-4 pb-6 font-light">
                    No feedback given for this instance of the routine
                  </span>
                </div>
              )}

              {data.tasks.length > 0 && (
                <div className="flex w-full flex-col items-center gap-4 py-4">
                  <span className="uppercase">TASKS</span>
                  {data.tasks.map((task, index) => (
                    <div
                      key={`task-${index}`}
                      className="flex w-full gap-6 bg-white px-4 py-6"
                    >
                      <div>
                        {/* Added a parent div to avoid distortion of circle when task name is two lines */}
                        <div className="flex h-8 w-8 min-w-max items-center justify-center rounded-full bg-primary-navy/50">
                          <p className="text-sm font-bold text-white">
                            {index + 1}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-lg font-bold">{task.name}</span>
                        <CompletedStatus status={task.status} />
                        {task.feedback.map((feedback) => (
                          <>
                            <span key={feedback.id}>
                              "{feedback.text}" -{' '}
                              {feedback.caretaker?.profile?.firstName}
                            </span>
                            {format(new Date(feedback.createdOn), 'Pp')}
                          </>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <FullPageLoading />
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useDependent from '../../../../shared/hooks/useDependent';
import useProfile from '../../../../shared/hooks/useProfile';
import useStorage from '../../../../shared/hooks/useStorage';
import {
  useGoalControllerCreateGoalMutation,
  useGoalControllerUpdateGoalMutation,
} from '../../../../shared/store/api/endpoint-with-tags';
import { Dependent, Goal } from '../../../../shared/store/api/endpoints';
import { GoalForm } from './CreateGoalModal';

const useCreateGoalModal = () => {
  const { profile } = useProfile();
  const { id } = useParams() as { id: string };
  const dependent = useDependent(id) as Dependent;
  const [fileUploading, setFileUploading] = useState(false);
  const { uploadFile } = useStorage();

  const [
    createGoal,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
    },
  ] = useGoalControllerCreateGoalMutation();
  const [
    updateGoal,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useGoalControllerUpdateGoalMutation();

  const saveGoal = async (goalForm: GoalForm, goal?: Goal) => {
    if (!profile) return;
    try {
      let path;
      if (
        (goal &&
          goalForm.rewardPhoto &&
          goal.rewardPhoto !== goalForm.rewardPhoto) ||
        (!goal && goalForm.rewardPhoto)
      ) {
        // uploading file state just in case things are slow.
        setFileUploading(true);
        const date = new Date().getTime();
        path = await uploadFile(
          goalForm.rewardPhoto,
          `${date}.jpeg`,
          'image/jpeg',
        );
        setFileUploading(false);
        // todo add cleanup for old files
      }
      let body;
      // handle update/editing
      if (goal) {
        body = {
          ...goal,
          ...goalForm,
          starsRequired: parseInt(goalForm.starsRequired),
          rewardPhoto: path || goal.rewardPhoto,
        };
        await updateGoal({ goal: body, id: goal.id.toString() }).unwrap();
      } else {
        body = {
          ...goalForm,
          starsRequired: parseInt(goalForm.starsRequired),
          rewardPhoto: path || '',
          caretakerId: profile?.caretaker?.id as string,
          dependentId: dependent.id,
        };
        await createGoal({ createGoal: body }).unwrap();
      }
    } finally {
      setFileUploading(false);
    }
  };

  return {
    isLoading: fileUploading || createLoading || updateLoading,
    createSuccess,
    createError,
    updateSuccess,
    updateError,
    saveGoal,
  };
};

export default useCreateGoalModal;

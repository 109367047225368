import { UserIcon } from '@heroicons/react/24/solid';
import Button from '../../../../../shared/components/Button';
import Image from '../../../../../shared/components/Image';
import { Routine } from '../../../../../shared/store/api/endpoints';

const RoutineHeader: React.FC<{
  routines: Routine[];
  dependent: any;
  handleAddRoutine: () => void;
}> = ({ routines, dependent, handleAddRoutine }) => {
  return (
    <div className="flex justify-between">
      <div className="flex items-center p-4">
        <div className="flex h-[40px] w-[40px] justify-center overflow-hidden rounded-full bg-gray-400">
          {dependent.profile.photo ? (
            <Image
              src={dependent.profile.photo}
              alt="Dependent profile photo"
            />
          ) : (
            <UserIcon className="h-[50px] w-[45px] text-gray-200" />
          )}
        </div>
        <div className="ml-4 flex flex-col">
          <span className="text font-semibold">
            {dependent.profile.firstName} {dependent.profile.lastName}
          </span>
          <span className="text-sm text-gray-400">
            {routines ? routines.length : 0} Routine
            {routines.length !== 1 && 's'}
          </span>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Button
          label="Add Routine"
          onClick={handleAddRoutine}
          className="p-button-text"
          icon="pi pi-plus"
        />
      </div>
    </div>
  );
};

export default RoutineHeader;

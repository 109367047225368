import { createSlice } from '@reduxjs/toolkit';
import { format, startOfWeek } from 'date-fns';

export interface DependentProgressState {
  startDate: string;
  endDate: string;
  timeFrame: 'Week' | 'Month' | 'Year';
}

export const dependentProgressStore = createSlice({
  name: 'dependentProgress',
  initialState: {
    timeFrame: 'Week',
    startDate: format(startOfWeek(new Date()), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  } as DependentProgressState,
  reducers: {
    setDateRange: (state, { payload: { timeFrame, startDate, endDate } }) => {
      state.timeFrame = timeFrame;
      state.startDate = startDate;
      state.endDate = endDate;
    },
  },
});

export const { setDateRange } = dependentProgressStore.actions;

export default dependentProgressStore.reducer;

import { AnimatePresence, LayoutGroup } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../../../shared/components/Button';
import AddCompletionPhoto from '../../../_components/CreateRoutineModal/AddCompletionPhoto';
import Details from '../../../_components/CreateRoutineModal/Details';
import RepeatRoutine from '../../../_components/CreateRoutineModal/RepeatRoutine';
import Tasks from '../../../_components/CreateRoutineModal/Tasks';
import ReminderOptions from '../../../_components/CreateRoutineModal/ReminderOptions';

import { useIonToast } from '@ionic/react';
import { InputSwitch } from 'primereact/inputswitch';
import ModalWrapper from '../../../../shared/components/ModalWrapper';
import {
  CaretakerDependent,
  Dependent,
  Routine,
} from '../../../../shared/store/api/endpoints';

import useDependentCreateRoutine from './useDependentCreateRoutine';
import useProfile from '../../../../shared/hooks/useProfile';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import useAllRoutines from '../../../../shared/hooks/useRoutines';
import { RoutineForm } from '../../../_components/CreateRoutineModal/create-routine-modal.types';
import { GoogleSync } from '../google-sync/google-sync';
import { useGoogle } from '../../../../shared/hooks/use-google';
import { useGoogleCalendar } from '../../../../shared/hooks/use-google-calendar';
import { useRoutineControllerUpdateRoutineMutation } from '../../../../shared/store/api/endpoint-with-tags';
interface DependentCreateRoutineProps {
  onDismiss: () => void;
  routine?: Routine;
  parentModalRef: any;
}

export default function DependentCreateRoutine({
  onDismiss,
  routine,
  parentModalRef,
}: DependentCreateRoutineProps) {
  // hooks
  const [present] = useIonToast();
  const { isLoading, saveRoutine } = useDependentCreateRoutine();
  const [updateRoutine] = useRoutineControllerUpdateRoutineMutation();
  const { profile } = useProfile();

  const { lastCompleted } = useAllRoutines((profile.dependent as Dependent).id);
  const [routineToGoogleSync, setRoutineToGoogleSync] = useState<
    Routine | undefined
  >(undefined);
  const [googleSyncLoading, setGoogleSyncLoading] = useState<boolean>(false);
  const { checkSignInStatus, initClient } = useGoogle();
  const { submitCalendarEvent } = useGoogleCalendar();
  // state
  const [routineForm, setRoutineForm] = useState<RoutineForm>({
    name: routine?.name || '',
    repeats: routine?.repeats === false ? false : true,
    startDate: routine?.startDate ? new Date(routine.startDate) : new Date(),
    repeatInterval: routine?.repeatInterval || 'day',
    repeatOptions: routine?.repeatOptions
      ? routine.repeatInterval === 'week'
        ? routine.repeatOptions.split(',')
        : routine.repeatOptions
      : null,
    reminder: routine?.reminder || 300,
    requirePhoto: routine?.requirePhoto || false,
    tasks: routine?.tasks || [],
    examplePhoto: routine?.examplePhoto || null,
    dependents: [],
    // If there is a caretaker id associated with the routine, then the routine is shared with the mentor
  });

  const [isPrivate, setIsPrivate] = useState<boolean>(
    routine?.private || false,
  );

  const [googleSync, setGoogleSync] = useState<boolean>(
    !!routine?.googleCalendarEventId ?? false,
  );

  const firstRenderRef = useRef(false);

  const caretakers = useCallback(
    (caretakers: CaretakerDependent[] | undefined) => {
      return caretakers ? caretakers.map(({ caretaker }) => caretaker) : [];
    },
    [],
  );

  const caretakerIds = useCallback(
    (caretakerDependents: CaretakerDependent[] | undefined) => {
      return caretakers(caretakerDependents).map((caretaker) => caretaker.id);
    },
    [caretakers],
  );

  const [sharedCaretakers, setSharedCaretakers] = useState<string[]>(
    routine
      ? routine.sharedCaretakers.map((caretaker) => caretaker.id)
      : caretakerIds(profile?.dependent?.caretakers),
  );

  useEffect(() => {
    if (firstRenderRef.current) {
      if (!isPrivate) {
        setSharedCaretakers(caretakerIds(profile?.dependent?.caretakers));
      } else {
        setSharedCaretakers([]);
      }
    }
    if (!firstRenderRef.current) {
      firstRenderRef.current = true;
    }
  }, [isPrivate, profile, routine, caretakerIds]);

  // methods
  const handleRoutineChange = (value: any, key: string) => {
    const newRoutineForm: any = { ...routineForm };
    newRoutineForm[key] = value;
    // reset options if interval is changed
    if (key === 'repeatInterval') {
      if (value === 'month') {
        // month needs a default value
        newRoutineForm['repeatOptions'] = '1';
      } else {
        newRoutineForm['repeatOptions'] = null;
      }
    }
    setRoutineForm(newRoutineForm);
  };

  const onSelectedMentorChange = (e: CheckboxChangeParams) => {
    if (e.checked) {
      setSharedCaretakers([...sharedCaretakers, e.value.id]);
    } else {
      setSharedCaretakers(sharedCaretakers.filter((id) => id !== e.value.id));
    }
  };

  async function handleSaveRoutine() {
    try {
      const newRoutine = await saveRoutine(
        { ...routineForm, sharedCaretakers, private: isPrivate, googleSync },
        routine,
      );
      present({
        message: routine
          ? 'Routine updated successfully'
          : 'Routine created successfully',
        duration: 2500,
      });
      if (googleSync && newRoutine) {
        await connectGoogleCalendar(newRoutine);
      } else {
        onDismiss();
      }
    } catch (e) {
      present({
        message: routine ? 'Error updating routine' : 'Error creating routine',
        duration: 2500,
      });
    }
  }

  async function connectGoogleCalendar(newRoutine: Routine) {
    setGoogleSyncLoading(true);
    const success = await initClient();
    if (success) {
      const isSignedIn = await checkSignInStatus();
      if (isSignedIn) {
        const googleCalendarEventId = await submitCalendarEvent(newRoutine);
        await updateRoutine({
          id: newRoutine.id,
          updateRoutinePayload: {
            googleCalendarEventId: googleCalendarEventId,
          },
        });
        setGoogleSyncLoading(false);
        onDismiss();
      } else {
        setGoogleSyncLoading(false);
        setRoutineToGoogleSync(newRoutine);
      }
    }
  }

  return (
    <ModalWrapper
      title={routine ? 'Edit Routine' : 'Add New Routine'}
      onDismiss={onDismiss}
      contentClass="neutral-3"
    >
      <AnimatePresence exitBeforeEnter>
        <LayoutGroup>
          <main className="px-4 pt-6">
            <Details
              routineForm={routineForm}
              handleRoutineChange={handleRoutineChange}
            />
            {routineForm.repeats && (
              <RepeatRoutine
                routineForm={routineForm}
                handleRoutineChange={handleRoutineChange}
              />
            )}
            <ReminderOptions
              key="reminder-options"
              routineForm={routineForm}
              handleRoutineChange={handleRoutineChange}
            />
            <GoogleSync shouldSync={googleSync} setShouldSync={setGoogleSync} />
            <AddCompletionPhoto
              routineForm={routineForm}
              handleRoutineChange={handleRoutineChange}
              parentModalRef={parentModalRef}
            />
            {/* TODO: Put this in it's own component */}
            <div className="w-full">
              <div className="my-6 flex flex-col rounded-lg border border-gray-200 bg-white py-4 px-6 font-semibold">
                <div className="flex items-center justify-between">
                  <label htmlFor="private" className="p-checkbox-label">
                    {!isPrivate ? (
                      <i className="pi pi-check mr-2" />
                    ) : (
                      <i className="pi pi-lock mr-2" />
                    )}
                    Share with your mentor
                  </label>
                  <InputSwitch
                    checked={!isPrivate}
                    onChange={(e) => setIsPrivate(!e.value)}
                    id="private"
                  />
                </div>
                {!isPrivate && (
                  <div className="flex flex-col gap-6 pt-6">
                    {profile?.dependent?.caretakers?.map(({ caretaker }) => (
                      <div
                        className="flex items-center justify-between"
                        key={caretaker.id}
                      >
                        <label>
                          {caretaker.profile?.firstName}{' '}
                          {caretaker.profile?.lastName}
                        </label>
                        <Checkbox
                          className="!h-6 !w-6"
                          style={{ width: '1.5rem', height: '1.5rem' }}
                          onChange={onSelectedMentorChange}
                          value={caretaker}
                          checked={
                            sharedCaretakers.find((id) => id === caretaker.id)
                              ? true
                              : false
                          }
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </main>
          <div className="bg-neutral-100 p-4">
            <Tasks
              routineForm={routineForm}
              handleRoutineChange={handleRoutineChange}
            />
            <div className="flex w-full py-4">
              <Button
                label="Save"
                onClick={() => {
                  handleSaveRoutine();
                }}
                loading={
                  isLoading || googleSyncLoading || !!routineToGoogleSync
                }
                disabled={
                  isLoading || googleSyncLoading || !!routineToGoogleSync
                }
              />
            </div>
          </div>
        </LayoutGroup>
      </AnimatePresence>
      <Dialog
        visible={!!routineToGoogleSync}
        header="Connect Google Calendar"
        onHide={() => {
          setRoutineToGoogleSync(undefined);
          onDismiss();
        }}
        className="m-4"
        footer={
          <div className="flex w-full justify-center pt-4">
            <Button
              className="google p-button-outlined p-0"
              aria-label="Google"
              isLoading={googleSyncLoading}
              disabled={googleSyncLoading}
              onClick={async () => {
                if (!routineToGoogleSync) return;
                setGoogleSyncLoading(true);
                const googleCalendarEventId = await submitCalendarEvent(
                  routineToGoogleSync,
                );
                await updateRoutine({
                  id: routineToGoogleSync.id,
                  updateRoutinePayload: {
                    googleCalendarEventId: googleCalendarEventId,
                  },
                });
                setGoogleSyncLoading(false);
                onDismiss();
              }}
            >
              <img
                className="h-6 w-6"
                src={'/assets/Google_Calendar_icon_(2020).svg'}
                alt="Google Calendar"
              />
              <span className="px-3">Connect Google Calendar</span>
            </Button>
          </div>
        }
      >
        <div className="pt-4">
          You need to connect your Google Calendar to sync your routine.
        </div>
      </Dialog>
    </ModalWrapper>
  );
}

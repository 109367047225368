import { useMemo, useState } from 'react';
import {
  useCompleteRoutineControllerGetAttemptedQuery,
  useCompleteRoutineControllerGetFailedQuery,
  useCompleteRoutineControllerGetRangeCompletedQuery,
  useCompleteRoutineControllerGetLastCompletedQuery,
  useRoutineControllerGetAllRoutinesForDependentQuery,
} from '../store/api/endpoint-with-tags';

import {
  endOfDay,
  endOfWeek,
  isAfter,
  isPast,
  isSameDay,
  isToday,
  parseISO,
  startOfWeek,
} from 'date-fns';
import { ModifiedDate, Routine } from '../store/api/endpoints';
import { sortRoutinesByMinutesElapsed } from '../helpers/time/time-helpers';
import { occurrences } from '../utility/recurrence';
import { RoutineWithDueDate } from '../types';

export default function useAllRoutines(
  dependentId: string,
  showPrivate = false,
  caretakerId = '',
) {
  const [rangeStart, setRangeStart] = useState<Date>(startOfWeek(new Date()));
  const [rangeEnd, setRangeEnd] = useState<Date>(endOfWeek(new Date()));

  const {
    data: routines,
    isLoading,
    isUninitialized,
  } = useRoutineControllerGetAllRoutinesForDependentQuery(
    { dependentId: dependentId, showPrivate, caretakerId },
    {
      skip: !dependentId,
    },
  );

  // includes private fyi, if we ever display this in the FE
  const { data: rangeCompleted } =
    useCompleteRoutineControllerGetRangeCompletedQuery(
      {
        dependentId: dependentId,
        startDate: rangeStart?.toISOString() as string,
        endDate: rangeEnd?.toISOString() as string,
      },
      {
        skip: !dependentId || !rangeStart || !rangeEnd,
      },
    );

  // includes private fyi, if we ever display this in the FE
  const { data: lastCompleted } =
    useCompleteRoutineControllerGetLastCompletedQuery(
      {
        dependentId: dependentId,
      },
      {
        skip: !dependentId,
      },
    );

  // private will not be attempted, so this is ok.
  const { data: attempted, refetch: attemptedRefetch } =
    useCompleteRoutineControllerGetAttemptedQuery(
      { dependentId: dependentId, caretakerId },
      { skip: !dependentId },
    );

  // private will not be failed, so this is ok.
  const { data: failed } = useCompleteRoutineControllerGetFailedQuery(
    { dependentId: dependentId, caretakerId },
    { skip: !dependentId },
  );

  const { sortedRoutines, todaysRoutines, pastDueRoutines } = useMemo(() => {
    let sorted: Routine[] = [];
    let todaysRoutines: Routine[] = [];
    let pastDueRoutines: Routine[] = [];
    if (routines && routines.length > 0 && lastCompleted) {
      sorted = sortRoutinesByMinutesElapsed(routines);

      // "dueDate" is added by sortRoutinesByMinutesElapsed method, making typing a PITA here
      sorted
        .filter((routine: RoutineWithDueDate) => {
          const endDate = routine.endDate
            ? new Date(routine.endDate)
            : endOfDay(new Date());
          const routineOccurrences = occurrences({
            routine,
            endDate,
            startDate: new Date(routine.startDate),
          });
          const lastOccurrence =
            routineOccurrences && routineOccurrences.length
              ? routineOccurrences[routineOccurrences.length - 1]
              : undefined;
          // if there isn't an occurrence for the routine, it shouldn't be in the list
          if (!lastOccurrence) return false;
          routine.dueDate = lastOccurrence;
          // the api type for last completed is wrong.  getLastCompleted() in the API uses raw SQL,
          const completedRecord = lastCompleted?.find((completed: any) => {
            // completed is not converted to camel case because it uses raw sql
            return completed.routine_id === routine.id;
          });
          if (completedRecord) {
            const completedDate = parseISO((completedRecord as any).created_on);
            const sameDay = isSameDay(completedDate, lastOccurrence as Date);
            const after = isAfter(completedDate, lastOccurrence as Date);

            const past = isPast(completedDate);
            // if completedDate is after the dueDate, the routine has been completed
            if (after) return false;
            // if completed is not today and it was created in the past, then we want to show it today.
            if (!sameDay && past) return true;
            return false;
          }
          if (routine.modifiedDates && routine.modifiedDates.length > 0) {
            const found = routine.modifiedDates.find((modified: ModifiedDate) =>
              isSameDay(new Date(modified.date), lastOccurrence as Date),
            );
            if (found) {
              routine.dueDate = new Date(found.date);
            }
          }
          return true;
        })
        .filter((routine) => {
          const excludedDate = routine.excludedDates.find((excluded: any) =>
            isSameDay(
              new Date(excluded.excludedDate),
              new Date((routine as any).dueDate as Date),
            ),
          );
          if (excludedDate) {
            return false;
          } else {
            return true;
          }
        })
        .forEach((routine: any) => {
          // need to prevent the routine from being added to the list if it's expired
          // might be irrelevant now because we are checking for last completed above.
          const isExpired = routine.endDate
            ? isPast(endOfDay(new Date(routine.endDate)))
            : false;

          if (isToday(routine.dueDate as Date) && !isExpired) {
            todaysRoutines.push(routine);
          } else if (isPast(routine.dueDate as Date) && !isExpired) {
            pastDueRoutines.push(routine);
          }
        });
    }
    return {
      sortedRoutines: sorted,
      todaysRoutines,
      pastDueRoutines,
    };
  }, [routines, lastCompleted]);

  return {
    routines: sortedRoutines,
    isLoading,
    isUninitialized,
    attempted,
    attemptedRefetch,
    failed,
    rangeCompleted,
    setRangeStart,
    setRangeEnd,
    todaysRoutines,
    pastDueRoutines,
    lastCompleted,
  };
}

import { useGoogle } from './use-google';
import { Routine } from '../store/api/endpoints';
import { rRuleString } from '../utility/recurrence';

export function useGoogleCalendar() {
  const { initClient, getSignedInUserEmail, signInToGoogle, gapi } =
    useGoogle();

  async function submitCalendarEvent(event: Routine) {
    try {
      const success = await initClient();
      if (success) {
        let email = await getSignedInUserEmail();
        if (!email) {
          await signInToGoogle();
          email = await getSignedInUserEmail();
        }
        // if we get here without an email, throw an error
        if (!email) {
          throw new Error(
            'No email found. Please sign in to Google to sync with your calendar.',
          );
        }
        // add event to calendar here
        const result: any = await publishCalendarEvent(event);
        return result.id;
      }
    } catch (error) {
      throw new Error('Error submitting event to Google Calendar.');
    }
  }

  async function publishCalendarEvent(routine: Routine) {
    let existingGoogleEvent;
    const formattedEvent = formatRoutine(routine);
    if (routine.googleCalendarEventId) {
      existingGoogleEvent = await getCalendarEvent(
        routine.googleCalendarEventId,
      );
      if (existingGoogleEvent) {
        return updateCalendarEvent(
          formattedEvent,
          routine.googleCalendarEventId,
        );
      }
    } else {
      return createCalendarEvent(formattedEvent);
    }
  }

  function getCalendarEvent(id: string) {
    return new Promise((resolve, reject) => {
      try {
        gapi.client.load('calendar', 'v3', () => {
          const request = gapi.client.calendar.events.get({
            calendarId: 'primary',
            eventId: id,
          });
          request.execute((e: any) => {
            resolve(e);
          });
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  function updateCalendarEvent(event: FormattedEvent, eventId: string) {
    console.log('event to update', event);
    return new Promise(async (resolve, reject) => {
      try {
        gapi.client.load('calendar', 'v3', () => {
          const request = gapi.client.calendar.events.update({
            calendarId: 'primary',
            eventId,
            resource: event,
          });
          request.execute((e: any) => {
            resolve(e);
          });
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  function deleteCalendarEvent(eventId: string) {
    return new Promise(async (resolve, reject) => {
      try {
        gapi.client.load('calendar', 'v3', () => {
          const request = gapi.client.calendar.events.delete({
            calendarId: 'primary',
            eventId,
          });
          request.execute((e: any) => {
            resolve(e);
          });
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  function createCalendarEvent(event: FormattedEvent) {
    return new Promise(async (resolve, reject) => {
      try {
        gapi.client.load('calendar', 'v3', () => {
          const request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          });
          request.execute((e: any) => {
            resolve(e);
          });
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  function formatRoutine(routine: Routine): FormattedEvent {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const recurrence = rRuleString(routine);
    let formattedEvent: FormattedEvent = {
      summary: routine.name,
      description: `Head over to ${process.env.REACT_APP_URL}/complete-routine/${routine.id} to complete your routine!`,
      start: {
        dateTime: routine.startDate,
        timeZone,
      },
      end: {
        dateTime: routine.startDate, // we don't have an end date. for recurrence this is just the start and end of the initial occurrence
        timeZone,
      },
      reminders: {
        useDefault: false,
      },
    };
    if (recurrence) {
      formattedEvent = { ...formattedEvent, recurrence: [recurrence] };
    }
    return formattedEvent;
  }

  return {
    submitCalendarEvent,
    deleteCalendarEvent,
  };
}

interface FormattedEvent {
  summary: string;
  description: string;
  start: {
    dateTime: string;
    timeZone: string;
  };
  end: {
    dateTime: string;
    timeZone: string;
  };
  recurrence?: string[];
  reminders: {
    useDefault: boolean;
  };
}

import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef, useState } from 'react';
import { RouteProps, useHistory } from 'react-router-dom';
import FullPageLoading from '../FullPageLoading';
import axios from 'axios';
import { useProfileControllerUpdateProfileMutation } from '../../store/api/endpoint-with-tags';
import useProfile from '../../hooks/useProfile';
import { Profile } from '../../store/api/endpoints';

const AuthGuard: React.FC<RouteProps> = ({ children }) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const [skip, setSkip] = useState(true);
  const { profile, profileLoading, profileUninitialized, profileFetching } =
    useProfile(skip);
  const [updateProfile] = useProfileControllerUpdateProfileMutation();
  const history = useHistory();

  const profileRef = useRef<Profile | undefined>();

  // handle Auth0 loading
  useEffect(() => {
    const addInterceptor = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        axios.interceptors.request.use((request) => {
          if (request.url) {
            const isApiUrl = request.url.startsWith(
              process.env.REACT_APP_API_URL as string,
            );
            if (user && isApiUrl && accessToken.length > 0) {
              //@ts-ignore
              request.headers.common.Authorization = `Bearer ${accessToken}`;
            }
          }
          return request;
        });
        setSkip(false);
      } catch (error) {
        console.error('error', error);
      }
    };

    if (!isLoading) {
      if (isAuthenticated && (user?.email || user?.name)) {
        // adds interceptor and also gets profile
        addInterceptor();
      } else {
        history.push('/get-started');
      }
    }
  }, [isLoading, isAuthenticated, user, getAccessTokenSilently, history]);

  // handle last login
  useEffect(() => {
    if (!profileLoading && profile && profileRef.current?.id !== profile.id) {
      const data = {
        lastLogin: new Date().toISOString(),
        id: profile.id,
      };
      // setting profileRef to current profile so we don't update lastLogin every time profile changes
      profileRef.current = profile;
      updateProfile({ updateProfile: data });
    }
  }, [profileLoading, profile, updateProfile]);

  // handle profile loading
  useEffect(() => {
    if (!profileLoading && !profileUninitialized && !profileFetching) {
      if (profile) {
        // dependent profile exists, but they haven't completed onboarding
        if (profile.dependent?.verified === false) {
          if (!history.location.pathname.includes('register')) {
            history.replace('/register/dependent');
            return;
          }
        } else {
          if (history.location.pathname.includes('register')) {
            history.push('/');
          }
        }
        // User can't register twice.  If they have a profile already, redirect.
      } else {
        if (!history.location.pathname.includes('register')) {
          // if there is no profile, force user here to register.
          if (user?.email) {
            // this is a caretaker
            history.replace('/register');
          } else if (user?.name) {
            // this is a dependent
            history.replace('/register/dependent');
          }
        }
      }
    }
  }, [
    profileLoading,
    history,
    profile,
    profileUninitialized,
    profileFetching,
    user,
  ]);

  return isLoading || profileLoading || profileUninitialized ? (
    <FullPageLoading />
  ) : (
    <>{children}</>
  );
};

export default AuthGuard;

import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { InputMask } from 'primereact/inputmask';
import { Controller } from 'react-hook-form';

const FormInputWithMask = ({
  control,
  errors,
  name,
  label,
  rules,
  type,
  inputRef,
  forgot,
  mask,
}: {
  control: any;
  errors: any;
  name: string;
  label: string;
  rules?: any;
  type?: any;
  inputRef?: any;
  forgot?: boolean;
  mask?: string;
}) => {
  return (
    <div>
      <label htmlFor={name + '-input'} className="ml-2">
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {
          return (
            <InputMask
              id={name + '-input'}
              className={classNames('mt-1 w-full', {
                'p-invalid': fieldState.invalid,
              })}
              {...field}
              mask={mask}
              ref={inputRef}
            />
          );
        }}
      />
      <div className="mx-2 mb-4 flex h-4 w-full items-baseline justify-end pr-4">
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <small className="p-error p-d-block">{message}</small>
          )}
        />
      </div>
    </div>
  );
};
export default FormInputWithMask;

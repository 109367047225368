import { Routine } from '../../../shared/store/api/endpoints';
import RoutineItem from '../RoutineItem';

export interface RoutinesTabProps {
  routines: Routine[];
  presentActionSheet: (routine: Routine) => void;
  isCaretaker: boolean;
}

export default function RoutinesTab({
  routines,
  presentActionSheet,
  isCaretaker = true,
}: RoutinesTabProps) {
  // if routine has an "end date", then it's been edited, and we don't want to show it.  The list of routines includes both original and modified routines
  const uniqueRoutines = routines.filter((routine) => !routine.endDate);
  return (
    <div className="border-t border-solid border-gray-200">
      <div className="flex px-4 py-2 text-gray-500">
        <div className="w-2/3">
          <span className="text-sm font-semibold">Routine Name</span>
        </div>
        <div className="w-1/3">
          <span className="text-sm font-semibold">Tasks</span>
        </div>
      </div>
      {uniqueRoutines?.map((routine: any) => (
        <RoutineItem
          routine={routine}
          key={routine.id}
          isCaretaker={isCaretaker}
          handleActionSheet={() => presentActionSheet(routine)}
        />
      ))}
    </div>
  );
}

import { useIonToast } from '@ionic/react';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../../../shared/components/Button';
import FormInput from '../../../../shared/components/FormInput';
import FormInputWithMask from '../../../../shared/components/FormInputWithMask';
import FormSelect from '../../../../shared/components/FormSelect';
import ModalWrapper from '../../../../shared/components/ModalWrapper';
import { fadeIn } from '../../../../shared/helpers/animation';
import useProfile from '../../../../shared/hooks/useProfile';
import { useProfileControllerAddDependentToCaretakerMutation } from '../../../../shared/store/api/endpoint-with-tags';

interface AddDependentProps {
  onDismiss: () => void;
}

interface FormProps {
  firstName: string;
  lastName: string;
  relationshipId: string;
  phone: string;
}

const AddDependent: FC<AddDependentProps> = ({ onDismiss }) => {
  // hooks
  const [present] = useIonToast();
  const [createProfile] = useProfileControllerAddDependentToCaretakerMutation();
  const { profile } = useProfile();
  // form
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      relationshipId: '',
      phone: '',
    },
  });

  // methods
  const handleCreate = async (values: FormProps) => {
    const { firstName, lastName, relationshipId, phone } = values;
    try {
      await createProfile({
        addDependent: {
          firstName,
          lastName,
          phone,
          permissions: ['3'],
          relationshipId,
          caretakerId: profile.caretaker?.id as string,
          smsNotifications: true,
        },
      }).unwrap();
      present({
        message: 'Dependent added successfully',
        duration: 2500,
      });
      onDismiss();
    } catch {
      present({
        message: 'Error adding dependent, please try again',
        duration: 2500,
      });
    }
  };

  //These currently match the options in the db, populated by seed data
  const options = [
    { label: 'Mentor', value: 1 },
    { label: 'Dad', value: 2 },
    { label: 'Mom', value: 3 },
  ];
  return (
    <ModalWrapper title="Add Mentee" onDismiss={onDismiss}>
      <motion.main
        className="mt-8 flex h-full flex-col justify-between px-6"
        variants={fadeIn}
        initial="hidden"
        animate="visible"
      >
        <form>
          <FormInput
            label="First Name"
            name="firstName"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'First name is required',
              },
            }}
            errors={errors}
          />
          <FormInput
            label="Last name"
            name="lastName"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Last name is required',
              },
            }}
            errors={errors}
          />
          <FormSelect
            name="relationshipId"
            label="Relationship to dependent"
            control={control}
            errors={errors}
            options={options}
            rules={{
              required: {
                value: true,
                message: 'Relationship is required',
              },
            }}
          />
          <FormInputWithMask
            label="Phone"
            name="phone"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Phone is required',
              },
            }}
            errors={errors}
            mask="(999) 999-9999"
          />
          <div className="mt-8 flex">
            <Button
              className="grow"
              onClick={handleSubmit((value) => handleCreate(value))}
              disabled={!isValid}
              label="Add Mentee"
            />
          </div>
        </form>
      </motion.main>
    </ModalWrapper>
  );
};

export default AddDependent;

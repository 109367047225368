import { Route } from 'react-router-dom';
import CaretakerSelectDependentPage from './pages/CaretakerSelectDependentPage';
import CaretakerTabs from './pages/CaretakerTabs';
import { RoutineDetailPage } from '../_pages/routine-detail-page';
import { ViewRoutineHistoryPage } from '../_pages/view-routine-history-page';
import { ReviewCompletedPage } from './pages/ReviewCompletedPage';
import { IonPage, IonRouterOutlet } from '@ionic/react';

export default function Caretaker() {
  return (
    <>
      <IonPage>
        <IonRouterOutlet>
          <Route path="/tabs/:id">
            <CaretakerTabs />
          </Route>
          <Route
            exact
            path="/routine-detail/:routineId/completed/:completedRoutineId"
          >
            <ViewRoutineHistoryPage />
          </Route>
          <Route exact path="/routine-detail/:routineId">
            <RoutineDetailPage />
          </Route>
          <Route exact path="/review-completed/:completedRoutineId">
            <ReviewCompletedPage />
          </Route>
          <Route exact path="/">
            <CaretakerSelectDependentPage />
          </Route>
        </IonRouterOutlet>
      </IonPage>
    </>
  );
}

import { RRule } from 'rrule';
import { Routine } from '../store/api/endpoints';
import { startOfDay } from 'date-fns';

export function formatAsRRule(routine: Routine, startDate?: Date) {
  if (!routine.repeatInterval) return;
  if (routine.repeatInterval === 'day') {
    let rule = new RRule({
      freq: RRule.DAILY,
    });
    if (startDate) {
      rule = new RRule({
        freq: RRule.DAILY,
        dtstart: startDate,
      });
    }
    return rule;
  }
  if (routine.repeatInterval === 'week') {
    let rule = new RRule({
      freq: RRule.WEEKLY,
      byweekday: routine.repeatOptions?.split(',').map((day) => {
        // week starts on monday in rrule, but sunday in our app
        const dayNum = parseInt(day) - 1;
        return dayNum < 0 ? 6 : dayNum;
      }),
    });
    if (startDate) {
      rule = new RRule({
        freq: RRule.WEEKLY,
        byweekday: routine.repeatOptions?.split(',').map((day) => {
          // week starts on monday in rrule, but sunday in our app
          const dayNum = parseInt(day) - 1;
          return dayNum < 0 ? 6 : dayNum;
        }),
        dtstart: startDate,
      });
    }
    return rule;
  }
  if (routine.repeatInterval === 'month') {
    let rule = new RRule({
      freq: RRule.MONTHLY,
      bymonthday: routine.repeatOptions?.split(',').map((day) => parseInt(day)),
    });
    if (startDate) {
      rule = new RRule({
        freq: RRule.MONTHLY,
        bymonthday: routine.repeatOptions
          ?.split(',')
          .map((day) => parseInt(day)),
        dtstart: startDate,
      });
    }
    return rule;
  }
}

export function rRuleString(routine: Routine) {
  const rule = formatAsRRule(routine);
  if (!rule) return;
  return rule.toString();
}

export function occurrences({
  routine,
  endDate,
  startDate,
}: {
  routine: Routine;
  endDate: Date;
  startDate?: Date;
}) {
  const rule = formatAsRRule(routine, startDate || new Date(routine.startDate));

  if (!routine.repeatInterval) {
    return [new Date(routine.startDate)];
  }
  if (!rule) return;
  const occurrences = rule.between(
    startDate ? startOfDay(startDate) : new Date(routine.startDate),
    endDate,
  );
  return occurrences;
}

import { Camera, CameraResultType } from '@capacitor/camera';

const useCamera = () => {
  const getPhoto = () => {
    return Camera.getPhoto({
      quality: 100,
      resultType: CameraResultType.Uri,
    });
  };

  return {
    getPhoto,
  };
};

export default useCamera;

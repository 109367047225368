import { IonModal, NavContext, useIonActionSheet } from '@ionic/react';
import { useContext, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ellipsisVertical } from 'ionicons/icons';
import EditProfile from '../../../_components/EditProfile';
import useProfile from '../../../../shared/hooks/useProfile';
import ProfileDetails from '../../../_components/ProfileDetails/ProfileDetails';
import PageWrapper from '../../../../shared/components/PageWrapper';
import { TabPanel, TabView } from 'primereact/tabview';
import CaretakerOrDependentTab from '../../../_components/CaretakerOrDependentTab';
import { CaretakerDependent } from '../../../../shared/store/api/endpoints';
import NotificationsTab from './NotificationsTab';

const CaretakerProfilePage = () => {
  // hooks
  const [present] = useIonActionSheet();
  const { navigate } = useContext(NavContext);
  const { profile: caretakerProfile, profileLoading: caretakerProfileLoading } =
    useProfile();

  //State
  const [activeIndex, setActiveIndex] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);

  //Ref
  const rootRef = useRef<any>(null);
  const editModalRef = useRef<HTMLIonModalElement>(null);

  //Methods
  const handleEditProfile = () => {
    setShowEditModal(true);
  };
  const handleActionSheet = () => {
    present([
      {
        text: 'Edit Profile',
        handler: () => {
          handleEditProfile();
        },
      },
      {
        text: 'Log out',
        handler: () => {
          handleSignOut();
        },
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  //Auth
  const { logout } = useAuth0();
  const handleSignOut = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <PageWrapper
      title="My Profile"
      onAction={handleActionSheet}
      actionIcon={ellipsisVertical}
      ref={rootRef}
      contentClass="neutral-2"
      onDismiss={() => navigate('/', 'back')}
      dismissType="home"
    >
      {caretakerProfile && !caretakerProfileLoading && (
        <>
          <ProfileDetails profile={caretakerProfile} />
        </>
      )}
      <section className="bg-neutral-100 pt-2">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Mentees">
            <CaretakerOrDependentTab
              caretakerDependents={
                caretakerProfile.caretaker?.dependents as CaretakerDependent[]
              }
              caretakerOrDependent="caretaker"
            />
          </TabPanel>
          <TabPanel header="Notifications">
            {caretakerProfile.caretaker && (
              <NotificationsTab caretaker={caretakerProfile.caretaker} />
            )}
          </TabPanel>
        </TabView>
      </section>
      <IonModal
        ref={editModalRef}
        isOpen={showEditModal}
        canDismiss={true}
        presentingElement={rootRef.current}
        onDidDismiss={() => setShowEditModal(false)}
      >
        <EditProfile
          parentModalRef={editModalRef}
          onDismiss={() => setShowEditModal(false)}
          profile={caretakerProfile}
        />
      </IonModal>
    </PageWrapper>
  );
};

export default CaretakerProfilePage;

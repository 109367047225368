export const getCaretakerTitle = (
  relationshipId: number,
  capitalize: boolean = true,
) => {
  switch (relationshipId) {
    case 1:
      return capitalize ? 'Mentor' : 'mentor';
    case 2:
      return capitalize ? 'Dad' : 'dad';
    case 3:
      return capitalize ? 'Mom' : 'mom';
    default:
      return capitalize ? 'Mentor' : 'mentor';
  }
};

export const getDependentTitle = (
  relationshipId: number,
  capitalize: boolean = false,
) => {
  switch (relationshipId) {
    case 1:
      return capitalize ? 'Mentee' : 'mentee';
    case 2:
    case 3:
      return capitalize ? 'Young person' : 'young person';
    default:
      return capitalize ? 'Mentee' : 'mentee';
  }
};

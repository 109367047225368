import { motion } from 'framer-motion';
import FormInput from '../../../../../shared/components/FormInput';
import { useForm } from 'react-hook-form';
import Button from '../../../../../shared/components/Button';
import { fadeIn } from '../../../../../shared/helpers/animation';
import { DependentFormType } from '../../RegisterDependent';
import useProfile from '../../../../../shared/hooks/useProfile';
import { FormCheckbox } from '../../../../../shared/components/form-checkbox/form-checkbox';
import FormInputWithMask from '../../../../../shared/components/FormInputWithMask/FormInputWithMask';
import { useAuth0 } from '@auth0/auth0-react';
const DependentName = ({
  handleNext,
  setDependentFormState,
}: {
  handleNext: () => void;
  setDependentFormState: (dependentFormState: DependentFormType) => void;
}) => {
  const { profile } = useProfile();
  const { user } = useAuth0();

  const {
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || '',
      smsNotifications: false,
      phone:
        user?.name && Number(user.name.substring(2))
          ? user.name.substring(2)
          : profile?.phone || '',
    },
  });

  const nextStep = () => {
    setDependentFormState({
      firstName: getValues().firstName,
      lastName: getValues().lastName,
      smsNotifications: getValues().smsNotifications,
    });
    handleNext();
  };

  return (
    <motion.main
      className="mt-8 flex h-full flex-col justify-between"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <section>
        <h2 className="mb-9 text-left text-lg font-medium">
          If these are not correct, please edit your first and last name.
        </h2>
        <FormInput
          label="First name"
          name="firstName"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'First name is required',
            },
          }}
          errors={errors}
        />
        <FormInput
          label="Last name"
          name="lastName"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Last name is required',
            },
          }}
          errors={errors}
        />
        <FormInputWithMask
          label="Phone number"
          name="phone"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Phone number is required',
            },
          }}
          errors={errors}
          mask="(999) 999-9999"
        />
        <section className="flex flex-col gap-4 pt-4">
          <h2 className="text-left text-lg font-medium">
            Would you like to receive notifications?
          </h2>
          <p>
            Routine Success uses SMS messages to notify you when to start your
            routines, when your routine has been reviewed, and your routine
            progress.
          </p>
          <FormCheckbox
            label="I consent to receive SMS notifications from Routine Success"
            name="smsNotifications"
            control={control}
            errors={errors}
            rules={{
              required: {
                value: true,
                message: 'SMS notifications are required',
              },
            }}
          />
        </section>
      </section>

      <section className="mt-8">
        <Button
          className="grow"
          onClick={() => nextStep()}
          disabled={!isValid}
          label="Yes"
        />
      </section>
    </motion.main>
  );
};

export default DependentName;

import { Button } from 'primereact/button';
import { useHistory } from 'react-router';
import { RoutinesReport } from '../../../shared/store/api/endpoints';
import { usePagination } from '../../dependent/pages/DependentProgressPage/_hooks/use-pagination';
import { readableFrequency } from '../../dependent/pages/DependentProgressPage/utils/frequency-string';
import { Pagination } from '../pagination';
import { TableWrapper } from '../table-wrapper';
import { TD } from '../td';
import { TH } from '../th';

interface ProgressTableProps {
  data: RoutinesReport[];
  changeSort: (column: keyof RoutinesReport) => void;
  sort: { direction: 'asc' | 'desc' | undefined; column: keyof RoutinesReport };
}

export function ProgressTable({
  data,
  changeSort,
  sort: { direction, column },
}: ProgressTableProps) {
  const {
    currentPage,
    itemsPerPage,
    handlePageChange,
    handleItemsPerPageChange,
    paginatedData,
  } = usePagination({ data });
  const history = useHistory();

  function handleView(routineId: number) {
    history.push(`/routine-detail/${routineId}`);
  }

  return (
    <div className="flex w-full flex-col items-center pb-8">
      <TableWrapper
        header={
          <>
            <TH
              className="min-w-[250px]"
              onClick={() => changeSort('name')}
              name="name"
              selectedSort={column}
              sortDirection={direction}
            >
              Routine
            </TH>
            <TH
              onClick={() => changeSort('frequency')}
              name="frequency"
              selectedSort={column}
              sortDirection={direction}
            >
              Frequency
            </TH>
            <TH
              onClick={() => changeSort('assigned')}
              name="assigned"
              selectedSort={column}
              sortDirection={direction}
            >
              Assigned
            </TH>
            <TH
              onClick={() => changeSort('completed')}
              name="completed"
              selectedSort={column}
              sortDirection={direction}
            >
              Attempted
            </TH>
            <TH
              onClick={() => changeSort('incomplete')}
              name="incomplete"
              selectedSort={column}
              sortDirection={direction}
            >
              Missed
            </TH>
            <TH
              onClick={() => changeSort('percentComplete')}
              name="percentComplete"
              selectedSort={column}
              sortDirection={direction}
            >
              % Attempted
            </TH>
            <TH></TH>
          </>
        }
        body={
          <>
            {paginatedData.map((routine) => (
              <tr
                key={routine.name}
                className="border-[2px] border-b border-neutral-200 bg-white"
              >
                <TD>{routine.name}</TD>
                <TD>{readableFrequency(routine.frequency)}</TD>
                <TD className="text-right">{routine.assigned}</TD>
                <TD className="text-right">{routine.completed}</TD>
                <TD className="text-right">{routine.incomplete}</TD>
                <TD className="text-right">{routine.percentComplete}%</TD>
                <TD>
                  {' '}
                  <Button
                    className="p-button-text"
                    label="View"
                    onClick={() => handleView(routine.id)}
                  />
                </TD>
              </tr>
            ))}
          </>
        }
      />
      <Pagination
        pages={Math.ceil(data.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={(page) => handlePageChange(page)}
        onItemsPerPageChange={(items) => handleItemsPerPageChange(items)}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

import { Checkbox } from 'primereact/checkbox';

interface GoogleSyncProps {
  shouldSync: boolean;
  setShouldSync: (shouldSync: boolean) => void;
}

export function GoogleSync({ shouldSync, setShouldSync }: GoogleSyncProps) {
  return (
    <div className="flex w-full items-center justify-start gap-2 pt-4">
      <Checkbox
        inputId="google-sync"
        value={shouldSync}
        onChange={(e) => setShouldSync(!e.value)}
        checked={shouldSync}
      />
      <label htmlFor="google-sync">Sync routine with Google Calendar</label>
    </div>
  );
}

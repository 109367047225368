import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import {
  addWeeks,
  startOfWeek,
  endOfWeek,
  addMonths,
  startOfMonth,
  endOfMonth,
  addYears,
  startOfYear,
  endOfYear,
  startOfDay,
  format,
} from 'date-fns';
import { formattedTimeFrame } from '../../../../../shared/utility/string-formatting';
import { DateRange } from '../../progress-sort-and-filter-header';
import { parseDate } from '../../../../../shared/utility/dates';

interface ProgressTimeFrameDisplayProps {
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
}

export function ProgressTimeFrameDisplay({
  dateRange,
  setDateRange,
}: ProgressTimeFrameDisplayProps) {
  function handleTimeFrame(direction: -1 | 1) {
    if (dateRange.timeFrame === 'Week') {
      const newWeek = addWeeks(parseDate(dateRange.startDate), direction);
      const newStartDate =
        startOfWeek(newWeek) > new Date()
          ? format(startOfWeek(new Date()), 'yyyy-MM-dd')
          : format(startOfWeek(newWeek), 'yyyy-MM-dd');
      const newEndDate =
        endOfWeek(newWeek) > new Date()
          ? format(new Date(), 'yyyy-MM-dd')
          : format(endOfWeek(newWeek), 'yyyy-MM-dd');
      setDateRange({
        timeFrame: 'Week',
        startDate: newStartDate,
        endDate: newEndDate,
      });
    }
    if (dateRange.timeFrame === 'Month') {
      const newMonth = addMonths(parseDate(dateRange.startDate), direction);
      const newStartDate =
        startOfMonth(newMonth) > new Date()
          ? format(startOfMonth(new Date()), 'yyyy-MM-dd')
          : format(startOfMonth(newMonth), 'yyyy-MM-dd');
      const newEndDate =
        endOfMonth(newMonth) > new Date()
          ? format(new Date(), 'yyyy-MM-dd')
          : format(endOfMonth(newMonth), 'yyyy-MM-dd');
      setDateRange({
        timeFrame: 'Month',
        startDate: newStartDate,
        endDate: newEndDate,
      });
    }
    if (dateRange.timeFrame === 'Year') {
      const newYear = addYears(parseDate(dateRange.startDate), direction);
      const newStartDate =
        startOfYear(newYear) > new Date()
          ? format(startOfYear(new Date()), 'yyyy-MM-dd')
          : format(startOfYear(newYear), 'yyyy-MM-dd');
      const newEndDate =
        endOfYear(newYear) > new Date()
          ? format(new Date(), 'yyyy-MM-dd')
          : format(endOfYear(newYear), 'yyyy-MM-dd');
      setDateRange({
        timeFrame: 'Year',
        startDate: newStartDate,
        endDate: newEndDate,
      });
    }
  }

  return (
    <div className="flex items-center justify-center gap-2">
      {dateRange.timeFrame && (
        <button onClick={() => handleTimeFrame(-1)}>
          <ChevronLeftIcon className="h-5 w-5" />
        </button>
      )}
      <div className="min-w-[150px] text-center">
        <span className="text-center text-lg font-bold">
          {formattedTimeFrame(dateRange)}
        </span>
      </div>
      {dateRange.timeFrame && (
        <>
          {startOfDay(parseDate(dateRange.endDate)) >=
          startOfDay(new Date()) ? (
            <div className="h-5 w-5"></div>
          ) : (
            <button onClick={() => handleTimeFrame(1)}>
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          )}
        </>
      )}
    </div>
  );
}

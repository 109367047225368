import { useAuth0 } from '@auth0/auth0-react';
import { IonModal, useIonActionSheet } from '@ionic/react';
import { ellipsisVertical } from 'ionicons/icons';
import { TabPanel, TabView } from 'primereact/tabview';
import { useRef, useState } from 'react';
import PageWrapper from '../../../../shared/components/PageWrapper';
import useProfile from '../../../../shared/hooks/useProfile';
import { CaretakerDependent } from '../../../../shared/store/api/endpoints';
import CaretakerOrDependentTab from '../../../_components/CaretakerOrDependentTab';
import EditProfile from '../../../_components/EditProfile';
import ProfileDetails from '../../../_components/ProfileDetails';
import NotificationsTab from './NotificationsTab';

export default function DependentProfilePage() {
  const [present] = useIonActionSheet();
  const { logout } = useAuth0();

  // state
  const { profile: dependentProfile, profileLoading: dependentProfileLoading } =
    useProfile();
  const [showEditModal, setShowEditModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  // effects

  // methods
  const handleActionSheet = () => {
    present([
      {
        text: 'Edit Profile',
        handler: () => {
          handleEditProfile();
        },
      },
      {
        text: 'Log out',
        handler: () => {
          handleSignOut();
        },
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  const handleEditProfile = () => {
    setShowEditModal(true);
  };

  //Auth
  const handleSignOut = () => {
    logout({ returnTo: window.location.origin });
  };

  //Ref
  const rootRef = useRef<any>(null);
  const editModalRef = useRef<HTMLIonModalElement>(null);

  return (
    <PageWrapper
      title="Profile"
      onAction={handleActionSheet}
      actionIcon={ellipsisVertical}
      ref={rootRef}
      contentClass="neutral-2"
    >
      {dependentProfile && !dependentProfileLoading && (
        <>
          <ProfileDetails profile={dependentProfile} />
        </>
      )}
      <section className="pt-2">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Mentors">
            <CaretakerOrDependentTab
              caretakerDependents={
                dependentProfile.dependent?.caretakers as CaretakerDependent[]
              }
              caretakerOrDependent="dependent"
            />
          </TabPanel>
          <TabPanel header="Notifications">
            {dependentProfile.dependent && (
              <NotificationsTab dependent={dependentProfile.dependent} />
            )}
          </TabPanel>
        </TabView>
      </section>
      <IonModal
        ref={editModalRef}
        isOpen={showEditModal}
        canDismiss={true}
        presentingElement={rootRef.current || undefined}
        onDidDismiss={() => setShowEditModal(false)}
      >
        <EditProfile
          parentModalRef={editModalRef}
          onDismiss={() => setShowEditModal(false)}
          profile={dependentProfile}
        />
      </IonModal>
    </PageWrapper>
  );
}

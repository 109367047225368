import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonGrid,
} from '@ionic/react';
import React, { FC } from 'react';
import { close } from 'ionicons/icons';

interface ModalWrapperProps {
  title: string;
  children: React.ReactNode;
  onDismiss?: () => void;
  contentClass?: string;
}

const ModalWrapper: FC<ModalWrapperProps> = ({
  title,
  children,
  onDismiss,
  contentClass,
}) => (
  <>
    <IonHeader>
      <IonToolbar mode="ios">
        {onDismiss && (
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        )}
        <IonTitle>{title}</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent className={contentClass}>
      <IonGrid fixed>{children}</IonGrid>
    </IonContent>
  </>
);

export default ModalWrapper;

import { useHistory } from 'react-router-dom';
import { CompletedRoutine } from '../../../../../shared/store/api/endpoints';
import { Button } from 'primereact/button';
import { format } from 'date-fns';
import { usePagination } from '../../../../dependent/pages/DependentProgressPage/_hooks/use-pagination';
import { useSort } from '../../../../dependent/pages/DependentProgressPage/_hooks/use-sort';
import CompletedStatus from '../../../../_components/CompletedStatus';
import { TableWrapper } from '../../../../_components/table-wrapper';
import { TH } from '../../../../_components/th';
import { TD } from '../../../../_components/td';
import { Pagination } from '../../../../_components/pagination';

export function RoutineDetailTable({ data }: { data: CompletedRoutine[] }) {
  const {
    sortedData,
    changeSort,
    sort: { direction, column },
  } = useSort({
    data,
    columns: ['updatedOn', 'status', 'starsAwarded'],
  });

  const {
    currentPage,
    itemsPerPage,
    handlePageChange,
    handleItemsPerPageChange,
    paginatedData,
  } = usePagination({ data: sortedData });
  const history = useHistory();

  function handleView(routineId: number, completedRoutineId: number) {
    history.push(
      `/routine-detail/${routineId}/completed/${completedRoutineId}`,
    );
  }

  return (
    <div className="flex w-full flex-col items-center pb-8">
      <TableWrapper
        header={
          <>
            <TH
              name="updatedOn"
              onClick={() => changeSort('updatedOn')}
              selectedSort={column}
              sortDirection={direction}
            >
              Date
            </TH>
            <TH
              name="status"
              onClick={() => changeSort('status')}
              selectedSort={column}
              sortDirection={direction}
            >
              Status
            </TH>
            <TH
              name="starsAwarded"
              onClick={() => changeSort('starsAwarded')}
              selectedSort={column}
              sortDirection={direction}
            >
              Points
            </TH>
            <TH>Feedback</TH>
            <TH></TH>
          </>
        }
        body={paginatedData.map((completedRoutine) => (
          <tr
            key={completedRoutine.id}
            className="border-[2px] border-b border-neutral-200 bg-white"
          >
            <TD>{format(new Date(completedRoutine.updatedOn), 'Pp')}</TD>
            <TD>
              <CompletedStatus status={completedRoutine.status} />
            </TD>
            <TD>{completedRoutine.starsAwarded ?? 0}</TD>
            <TD>
              {(completedRoutine.feedback &&
                completedRoutine.feedback.length &&
                completedRoutine.feedback[completedRoutine.feedback.length - 1]
                  .text) ||
                '--'}
            </TD>
            <TD>
              <Button
                className="p-button-text"
                label="View"
                onClick={() =>
                  handleView(completedRoutine.routineId, completedRoutine.id)
                }
              />
            </TD>
          </tr>
        ))}
      />
      <Pagination
        pages={Math.ceil(data.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={(page) => handlePageChange(page)}
        onItemsPerPageChange={(itemsPerPage) =>
          handleItemsPerPageChange(itemsPerPage)
        }
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

import { gapi } from 'gapi-script';

export function useGoogle() {
  const API_KEY = process.env.REACT_APP_GCP_API_KEY;
  const DISCOVERY_DOCS = [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  ];
  const CLIENT_ID = process.env.REACT_APP_GCP_PROJECT_ID;
  const SCOPES = 'https://www.googleapis.com/auth/calendar.events';
  function initClient() {
    return new Promise((resolve, reject) => {
      gapi.load('client:auth2', () => {
        try {
          gapi.client
            .init({
              apiKey: API_KEY,
              clientId: CLIENT_ID,
              discoveryDocs: DISCOVERY_DOCS,
              scope: SCOPES,
            })
            .then((success: any) => {
              resolve(true);
            })
            .catch((error: any) => {
              console.error(error);
              reject(false);
            });
        } catch (error) {
          reject(false);
        }
      });
    });
  }

  async function checkSignInStatus() {
    try {
      let status = await gapi.auth2.getAuthInstance().isSignedIn.get();
      return status;
    } catch (error) {
      console.error(error);
    }
  }

  async function signInToGoogle() {
    try {
      let googleuser = await gapi.auth2
        .getAuthInstance()
        .signIn({ prompt: 'consent' });
      if (googleuser) {
        return true;
      }
    } catch (error) {
      throw new Error();
    }
  }

  function signOutFromGoogle() {
    try {
      var auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(function () {
        auth2.disconnect();
      });
      return true;
    } catch (error) {
      throw new Error();
    }
  }

  async function getSignedInUserEmail() {
    try {
      let status = await checkSignInStatus();
      if (status) {
        var auth2 = gapi.auth2.getAuthInstance();
        var profile = auth2.currentUser.get().getBasicProfile();
        return profile.getEmail();
      } else {
        return null;
      }
    } catch (error) {
      throw new Error();
    }
  }

  return {
    initClient,
    checkSignInStatus,
    signInToGoogle,
    signOutFromGoogle,
    getSignedInUserEmail,
    gapi,
  };
}

import { Redirect } from 'react-router-dom';
import FullPageLoading from '../shared/components/FullPageLoading';
import useProfile from '../shared/hooks/useProfile';
import Caretaker from './caretaker/Caretaker';
import Dependent from './dependent';

const MainApp = () => {
  const { user, profile, profileLoading, profileUninitialized } = useProfile();
  if (!profileLoading && !profileUninitialized) {
    if (
      profile &&
      profile.permissions?.find(
        (p) => p.permission && p.permission.role === 'caretaker',
      )
    ) {
      return <Caretaker />;
    } else if (
      profile &&
      profile.permissions?.find(
        (p) => p.permission && p.permission.role === 'young_person',
      ) &&
      profile.dependent?.verified
    ) {
      // we're a dependent...
      return <Dependent />;
    } else {
      if (user?.email) {
        return <Redirect to="/register" />;
      } else if (user?.name) {
        return <Redirect to="/register/dependent" />;
      } else {
        return <></>;
      }
    }
  } else {
    return <FullPageLoading />;
  }
};

export default MainApp;

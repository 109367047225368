import { apiSlice as api } from './apiSlice';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    appControllerGetHello: build.query<
      AppControllerGetHelloApiResponse,
      AppControllerGetHelloApiArg
    >({
      query: () => ({ url: `/` }),
    }),
    profileControllerCheckPhone: build.mutation<
      ProfileControllerCheckPhoneApiResponse,
      ProfileControllerCheckPhoneApiArg
    >({
      query: (queryArg) => ({
        url: `/profile/check-phone`,
        method: 'POST',
        body: queryArg.checkPhoneBody,
      }),
    }),
    profileControllerGetByEmail: build.query<
      ProfileControllerGetByEmailApiResponse,
      ProfileControllerGetByEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/profile/get-by-email`,
        params: { email: queryArg.email },
      }),
    }),
    profileControllerGetByPhone: build.query<
      ProfileControllerGetByPhoneApiResponse,
      ProfileControllerGetByPhoneApiArg
    >({
      query: (queryArg) => ({
        url: `/profile/get-by-phone`,
        params: { phone: queryArg.phone },
      }),
    }),
    profileControllerGetAllProfiles: build.query<
      ProfileControllerGetAllProfilesApiResponse,
      ProfileControllerGetAllProfilesApiArg
    >({
      query: () => ({ url: `/profile/get-all-profiles` }),
    }),
    profileControllerCreateDependentProfile: build.mutation<
      ProfileControllerCreateDependentProfileApiResponse,
      ProfileControllerCreateDependentProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/profile/create-dependent-profile`,
        method: 'POST',
        body: queryArg.createProfile,
      }),
    }),
    profileControllerCreateCaretakerProfile: build.mutation<
      ProfileControllerCreateCaretakerProfileApiResponse,
      ProfileControllerCreateCaretakerProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/profile/create-caretaker-profile`,
        method: 'POST',
        body: queryArg.createCaretakerProfile,
      }),
    }),
    profileControllerCreateProfile: build.mutation<
      ProfileControllerCreateProfileApiResponse,
      ProfileControllerCreateProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/profile/create-profile`,
        method: 'POST',
        body: queryArg.createProfile,
      }),
    }),
    profileControllerUpdateProfile: build.mutation<
      ProfileControllerUpdateProfileApiResponse,
      ProfileControllerUpdateProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/profile/update-profile`,
        method: 'PUT',
        body: queryArg.updateProfile,
      }),
    }),
    profileControllerChangeCaretakerStatus: build.mutation<
      ProfileControllerChangeCaretakerStatusApiResponse,
      ProfileControllerChangeCaretakerStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/profile/change-caretaker-status`,
        method: 'PUT',
        body: queryArg.changeCaretakerStatusBody,
      }),
    }),
    profileControllerAddDependentToCaretaker: build.mutation<
      ProfileControllerAddDependentToCaretakerApiResponse,
      ProfileControllerAddDependentToCaretakerApiArg
    >({
      query: (queryArg) => ({
        url: `/profile/add-dependent-to-caretaker`,
        method: 'POST',
        body: queryArg.addDependent,
      }),
    }),
    storageControllerGenerateReadUrl: build.query<
      StorageControllerGenerateReadUrlApiResponse,
      StorageControllerGenerateReadUrlApiArg
    >({
      query: (queryArg) => ({ url: `/storage/read-url/${queryArg.fileName}` }),
    }),
    storageControllerGenerateWriteUrl: build.query<
      StorageControllerGenerateWriteUrlApiResponse,
      StorageControllerGenerateWriteUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/storage/write-url`,
        params: { fileName: queryArg.fileName, fileType: queryArg.fileType },
      }),
    }),
    storageControllerUploadFile: build.mutation<
      StorageControllerUploadFileApiResponse,
      StorageControllerUploadFileApiArg
    >({
      query: () => ({ url: `/storage/upload-file`, method: 'POST' }),
    }),
    routineControllerCreateRoutine: build.mutation<
      RoutineControllerCreateRoutineApiResponse,
      RoutineControllerCreateRoutineApiArg
    >({
      query: (queryArg) => ({
        url: `/routine/create-routine`,
        method: 'POST',
        body: queryArg.createRoutinePayload,
      }),
    }),
    routineControllerUpdateRoutine: build.mutation<
      RoutineControllerUpdateRoutineApiResponse,
      RoutineControllerUpdateRoutineApiArg
    >({
      query: (queryArg) => ({
        url: `/routine/update-routine/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateRoutinePayload,
      }),
    }),
    routineControllerGetAllRoutinesForDependent: build.query<
      RoutineControllerGetAllRoutinesForDependentApiResponse,
      RoutineControllerGetAllRoutinesForDependentApiArg
    >({
      query: (queryArg) => ({
        url: `/routine/get-all-routines-for-dependent/${queryArg.dependentId}`,
        params: {
          showPrivate: queryArg.showPrivate,
          caretakerId: queryArg.caretakerId,
        },
      }),
    }),
    routineControllerDeleteRoutine: build.mutation<
      RoutineControllerDeleteRoutineApiResponse,
      RoutineControllerDeleteRoutineApiArg
    >({
      query: (queryArg) => ({
        url: `/routine/delete-routine/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    routineControllerGetRoutineById: build.query<
      RoutineControllerGetRoutineByIdApiResponse,
      RoutineControllerGetRoutineByIdApiArg
    >({
      query: (queryArg) => ({ url: `/routine/${queryArg.id}` }),
    }),
    goalControllerCreateGoal: build.mutation<
      GoalControllerCreateGoalApiResponse,
      GoalControllerCreateGoalApiArg
    >({
      query: (queryArg) => ({
        url: `/goal/create-goal`,
        method: 'POST',
        body: queryArg.createGoal,
      }),
    }),
    goalControllerUpdateGoal: build.mutation<
      GoalControllerUpdateGoalApiResponse,
      GoalControllerUpdateGoalApiArg
    >({
      query: (queryArg) => ({
        url: `/goal/update-goal/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.goal,
      }),
    }),
    goalControllerGetGoalsByDependant: build.query<
      GoalControllerGetGoalsByDependantApiResponse,
      GoalControllerGetGoalsByDependantApiArg
    >({
      query: (queryArg) => ({
        url: `/goal/get-goals-by-dependent/${queryArg.dependentId}`,
      }),
    }),
    goalControllerAwardGoal: build.mutation<
      GoalControllerAwardGoalApiResponse,
      GoalControllerAwardGoalApiArg
    >({
      query: (queryArg) => ({
        url: `/goal/award-goal/${queryArg.id}`,
        method: 'PUT',
      }),
    }),
    caretakerControllerGetByProfileId: build.query<
      CaretakerControllerGetByProfileIdApiResponse,
      CaretakerControllerGetByProfileIdApiArg
    >({
      query: (queryArg) => ({
        url: `/caretaker/get-by-profile-id/${queryArg.profileId}`,
      }),
    }),
    caretakerControllerUpdate: build.mutation<
      CaretakerControllerUpdateApiResponse,
      CaretakerControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/caretaker/update/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.caretaker,
      }),
    }),
    completeRoutineControllerCreateCompleteRoutine: build.mutation<
      CompleteRoutineControllerCreateCompleteRoutineApiResponse,
      CompleteRoutineControllerCreateCompleteRoutineApiArg
    >({
      query: (queryArg) => ({
        url: `/complete-routine`,
        method: 'POST',
        body: queryArg.createCompletedRoutinePayload,
      }),
    }),
    completeRoutineControllerUpdateCompleteRoutine: build.mutation<
      CompleteRoutineControllerUpdateCompleteRoutineApiResponse,
      CompleteRoutineControllerUpdateCompleteRoutineApiArg
    >({
      query: (queryArg) => ({
        url: `/complete-routine/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.completedRoutine,
      }),
    }),
    completeRoutineControllerGetCompletedRoutine: build.query<
      CompleteRoutineControllerGetCompletedRoutineApiResponse,
      CompleteRoutineControllerGetCompletedRoutineApiArg
    >({
      query: (queryArg) => ({ url: `/complete-routine/${queryArg.id}` }),
    }),
    completeRoutineControllerGetDateCompleted: build.query<
      CompleteRoutineControllerGetDateCompletedApiResponse,
      CompleteRoutineControllerGetDateCompletedApiArg
    >({
      query: (queryArg) => ({
        url: `/complete-routine/get-day-completed/${queryArg.date}/${queryArg.dependentId}`,
      }),
    }),
    completeRoutineControllerGetRangeCompleted: build.query<
      CompleteRoutineControllerGetRangeCompletedApiResponse,
      CompleteRoutineControllerGetRangeCompletedApiArg
    >({
      query: (queryArg) => ({
        url: `/complete-routine/get-range-completed/${queryArg.dependentId}/${queryArg.startDate}/${queryArg.endDate}`,
      }),
    }),
    completeRoutineControllerGetLastCompleted: build.query<
      CompleteRoutineControllerGetLastCompletedApiResponse,
      CompleteRoutineControllerGetLastCompletedApiArg
    >({
      query: (queryArg) => ({
        url: `/complete-routine/get-last-completed/${queryArg.dependentId}`,
      }),
    }),
    completeRoutineControllerGetAttempted: build.query<
      CompleteRoutineControllerGetAttemptedApiResponse,
      CompleteRoutineControllerGetAttemptedApiArg
    >({
      query: (queryArg) => ({
        url: `/complete-routine/get-attempted/${queryArg.dependentId}`,
        params: { caretakerId: queryArg.caretakerId },
      }),
    }),
    completeRoutineControllerGetCompleted: build.query<
      CompleteRoutineControllerGetCompletedApiResponse,
      CompleteRoutineControllerGetCompletedApiArg
    >({
      query: (queryArg) => ({
        url: `/complete-routine/get-completed/${queryArg.dependentId}`,
        params: { caretakerId: queryArg.caretakerId },
      }),
    }),
    completeRoutineControllerGetFailed: build.query<
      CompleteRoutineControllerGetFailedApiResponse,
      CompleteRoutineControllerGetFailedApiArg
    >({
      query: (queryArg) => ({
        url: `/complete-routine/failed/${queryArg.dependentId}`,
        params: { caretakerId: queryArg.caretakerId },
      }),
    }),
    notificationsControllerTriggerNotifications: build.query<
      NotificationsControllerTriggerNotificationsApiResponse,
      NotificationsControllerTriggerNotificationsApiArg
    >({
      query: () => ({ url: `/notifications/trigger-notifications` }),
    }),
    notificationsControllerSendReminder: build.mutation<
      NotificationsControllerSendReminderApiResponse,
      NotificationsControllerSendReminderApiArg
    >({
      query: () => ({ url: `/notifications/send-reminder`, method: 'POST' }),
    }),
    dependentControllerUpdate: build.mutation<
      DependentControllerUpdateApiResponse,
      DependentControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/dependent/update/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.dependent,
      }),
    }),
    dependentControllerGetDependent: build.query<
      DependentControllerGetDependentApiResponse,
      DependentControllerGetDependentApiArg
    >({
      query: (queryArg) => ({ url: `/dependent/${queryArg.id}` }),
    }),
    dependentReportingControllerGetSingleRoutineReport: build.query<
      DependentReportingControllerGetSingleRoutineReportApiResponse,
      DependentReportingControllerGetSingleRoutineReportApiArg
    >({
      query: (queryArg) => ({
        url: `/dependent-reporting/routine/${queryArg.routineId}`,
        params: {
          dependentId: queryArg.dependentId,
          caretakerId: queryArg.caretakerId,
          startDate: queryArg.startDate,
          endDate: queryArg.endDate,
          completedStatus: queryArg.completedStatus,
        },
      }),
    }),
    dependentReportingControllerGetRoutinesReport: build.query<
      DependentReportingControllerGetRoutinesReportApiResponse,
      DependentReportingControllerGetRoutinesReportApiArg
    >({
      query: (queryArg) => ({
        url: `/dependent-reporting/${queryArg.dependentId}`,
        params: {
          caretakerId: queryArg.caretakerId,
          startDate: queryArg.startDate,
          endDate: queryArg.endDate,
          routineIds: queryArg.routineIds,
          completedStatus: queryArg.completedStatus,
          frequency: queryArg.frequency,
        },
      }),
    }),
    routineOccurrenceControllerModifyDate: build.mutation<
      RoutineOccurrenceControllerModifyDateApiResponse,
      RoutineOccurrenceControllerModifyDateApiArg
    >({
      query: (queryArg) => ({
        url: `/routine-occurrence/modify/${queryArg.routineId}`,
        method: 'POST',
        body: queryArg.routineOccurrencePayload,
      }),
    }),
    routineOccurrenceControllerCancelOccurrence: build.mutation<
      RoutineOccurrenceControllerCancelOccurrenceApiResponse,
      RoutineOccurrenceControllerCancelOccurrenceApiArg
    >({
      query: (queryArg) => ({
        url: `/routine-occurrence/cancel/${queryArg.routineId}`,
        method: 'POST',
        body: queryArg.routineOccurrencePayload,
      }),
    }),
    routineOccurrenceControllerGetModifiedDates: build.query<
      RoutineOccurrenceControllerGetModifiedDatesApiResponse,
      RoutineOccurrenceControllerGetModifiedDatesApiArg
    >({
      query: (queryArg) => ({
        url: `/routine-occurrence/modified-dates/${queryArg.routineId}`,
      }),
    }),
    routineOccurrenceControllerGetExcludedDates: build.query<
      RoutineOccurrenceControllerGetExcludedDatesApiResponse,
      RoutineOccurrenceControllerGetExcludedDatesApiArg
    >({
      query: (queryArg) => ({
        url: `/routine-occurrence/excluded-dates/${queryArg.routineId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as endpoints };
export type AppControllerGetHelloApiResponse = /** status 200  */ string;
export type AppControllerGetHelloApiArg = void;
export type ProfileControllerCheckPhoneApiResponse = /** status 201  */ boolean;
export type ProfileControllerCheckPhoneApiArg = {
  checkPhoneBody: CheckPhoneBody;
};
export type ProfileControllerGetByEmailApiResponse = /** status 200  */ Profile;
export type ProfileControllerGetByEmailApiArg = {
  email: string;
};
export type ProfileControllerGetByPhoneApiResponse = /** status 200  */ Profile;
export type ProfileControllerGetByPhoneApiArg = {
  phone: string;
};
export type ProfileControllerGetAllProfilesApiResponse =
  /** status 200  */ Profile[];
export type ProfileControllerGetAllProfilesApiArg = void;
export type ProfileControllerCreateDependentProfileApiResponse =
  /** status 201  */ Profile;
export type ProfileControllerCreateDependentProfileApiArg = {
  createProfile: CreateProfile;
};
export type ProfileControllerCreateCaretakerProfileApiResponse =
  /** status 201  */ Profile;
export type ProfileControllerCreateCaretakerProfileApiArg = {
  createCaretakerProfile: CreateCaretakerProfile;
};
export type ProfileControllerCreateProfileApiResponse =
  /** status 201  */ Profile;
export type ProfileControllerCreateProfileApiArg = {
  createProfile: CreateProfile;
};
export type ProfileControllerUpdateProfileApiResponse =
  /** status 200  */ Profile;
export type ProfileControllerUpdateProfileApiArg = {
  updateProfile: UpdateProfile;
};
export type ProfileControllerChangeCaretakerStatusApiResponse = unknown;
export type ProfileControllerChangeCaretakerStatusApiArg = {
  changeCaretakerStatusBody: ChangeCaretakerStatusBody;
};
export type ProfileControllerAddDependentToCaretakerApiResponse =
  /** status 201  */ Profile;
export type ProfileControllerAddDependentToCaretakerApiArg = {
  addDependent: AddDependent;
};
export type StorageControllerGenerateReadUrlApiResponse =
  /** status 200  */ string;
export type StorageControllerGenerateReadUrlApiArg = {
  fileName: string;
};
export type StorageControllerGenerateWriteUrlApiResponse =
  /** status 200  */ string;
export type StorageControllerGenerateWriteUrlApiArg = {
  fileName: string;
  fileType: string;
};
export type StorageControllerUploadFileApiResponse = /** status 201  */ string;
export type StorageControllerUploadFileApiArg = void;
export type RoutineControllerCreateRoutineApiResponse =
  /** status 201  */ Routine;
export type RoutineControllerCreateRoutineApiArg = {
  createRoutinePayload: CreateRoutinePayload;
};
export type RoutineControllerUpdateRoutineApiResponse =
  /** status 200  */ Routine;
export type RoutineControllerUpdateRoutineApiArg = {
  id: number;
  updateRoutinePayload: UpdateRoutinePayload;
};
export type RoutineControllerGetAllRoutinesForDependentApiResponse =
  /** status 200  */ Routine[];
export type RoutineControllerGetAllRoutinesForDependentApiArg = {
  dependentId: string;
  showPrivate: boolean;
  caretakerId: string;
};
export type RoutineControllerDeleteRoutineApiResponse = unknown;
export type RoutineControllerDeleteRoutineApiArg = {
  id: string;
};
export type RoutineControllerGetRoutineByIdApiResponse =
  /** status 200  */ Routine;
export type RoutineControllerGetRoutineByIdApiArg = {
  id: number;
};
export type GoalControllerCreateGoalApiResponse = /** status 201  */ Goal;
export type GoalControllerCreateGoalApiArg = {
  createGoal: CreateGoal;
};
export type GoalControllerUpdateGoalApiResponse = /** status 200  */ Goal;
export type GoalControllerUpdateGoalApiArg = {
  id: string;
  goal: Goal;
};
export type GoalControllerGetGoalsByDependantApiResponse =
  /** status 200  */ GoalResponse;
export type GoalControllerGetGoalsByDependantApiArg = {
  dependentId: string;
};
export type GoalControllerAwardGoalApiResponse = /** status 200  */ Goal;
export type GoalControllerAwardGoalApiArg = {
  id: string;
};
export type CaretakerControllerGetByProfileIdApiResponse =
  /** status 200  */ object;
export type CaretakerControllerGetByProfileIdApiArg = {
  profileId: string;
};
export type CaretakerControllerUpdateApiResponse = /** status 200  */ Caretaker;
export type CaretakerControllerUpdateApiArg = {
  id: string;
  caretaker: Caretaker;
};
export type CompleteRoutineControllerCreateCompleteRoutineApiResponse =
  /** status 201  */ CompletedRoutine;
export type CompleteRoutineControllerCreateCompleteRoutineApiArg = {
  createCompletedRoutinePayload: CreateCompletedRoutinePayload;
};
export type CompleteRoutineControllerUpdateCompleteRoutineApiResponse =
  /** status 200  */ CompletedRoutine;
export type CompleteRoutineControllerUpdateCompleteRoutineApiArg = {
  id: string;
  completedRoutine: CompletedRoutine;
};
export type CompleteRoutineControllerGetCompletedRoutineApiResponse =
  /** status 200  */ CompletedRoutine;
export type CompleteRoutineControllerGetCompletedRoutineApiArg = {
  id: string;
};
export type CompleteRoutineControllerGetDateCompletedApiResponse =
  /** status 200  */ CompletedRoutine[];
export type CompleteRoutineControllerGetDateCompletedApiArg = {
  date: string;
  dependentId: string;
};
export type CompleteRoutineControllerGetRangeCompletedApiResponse =
  /** status 200  */ CompletedRoutine[];
export type CompleteRoutineControllerGetRangeCompletedApiArg = {
  dependentId: string;
  startDate: string;
  endDate: string;
};
export type CompleteRoutineControllerGetLastCompletedApiResponse =
  /** status 200  */ CompletedRoutine[];
export type CompleteRoutineControllerGetLastCompletedApiArg = {
  dependentId: string;
};
export type CompleteRoutineControllerGetAttemptedApiResponse =
  /** status 200  */ CompletedRoutine[];
export type CompleteRoutineControllerGetAttemptedApiArg = {
  dependentId: string;
  caretakerId: string;
};
export type CompleteRoutineControllerGetCompletedApiResponse =
  /** status 200  */ CompletedRoutine[];
export type CompleteRoutineControllerGetCompletedApiArg = {
  dependentId: string;
  caretakerId: string;
};
export type CompleteRoutineControllerGetFailedApiResponse =
  /** status 200  */ CompletedRoutine[];
export type CompleteRoutineControllerGetFailedApiArg = {
  dependentId: string;
  caretakerId: string;
};
export type NotificationsControllerTriggerNotificationsApiResponse = unknown;
export type NotificationsControllerTriggerNotificationsApiArg = void;
export type NotificationsControllerSendReminderApiResponse = unknown;
export type NotificationsControllerSendReminderApiArg = void;
export type DependentControllerUpdateApiResponse = /** status 200  */ Dependent;
export type DependentControllerUpdateApiArg = {
  id: string;
  dependent: Dependent;
};
export type DependentControllerGetDependentApiResponse =
  /** status 200  */ Dependent;
export type DependentControllerGetDependentApiArg = {
  id: string;
};
export type DependentReportingControllerGetSingleRoutineReportApiResponse =
  /** status 200  */ SingleRoutineReport;
export type DependentReportingControllerGetSingleRoutineReportApiArg = {
  routineId: number;
  dependentId: string;
  caretakerId: string;
  startDate: string;
  endDate: string;
  completedStatus: string[];
};
export type DependentReportingControllerGetRoutinesReportApiResponse =
  /** status 200  */ RoutinesReport[];
export type DependentReportingControllerGetRoutinesReportApiArg = {
  dependentId: string;
  caretakerId: string;
  startDate: string;
  endDate: string;
  routineIds: string[];
  completedStatus: string[];
  frequency: string[];
};
export type RoutineOccurrenceControllerModifyDateApiResponse =
  /** status 201  */ object;
export type RoutineOccurrenceControllerModifyDateApiArg = {
  routineId: string;
  routineOccurrencePayload: RoutineOccurrencePayload;
};
export type RoutineOccurrenceControllerCancelOccurrenceApiResponse =
  /** status 201  */ object;
export type RoutineOccurrenceControllerCancelOccurrenceApiArg = {
  routineId: string;
  routineOccurrencePayload: RoutineOccurrencePayload;
};
export type RoutineOccurrenceControllerGetModifiedDatesApiResponse =
  /** status 200  */ ModifiedDate[];
export type RoutineOccurrenceControllerGetModifiedDatesApiArg = {
  routineId: string;
};
export type RoutineOccurrenceControllerGetExcludedDatesApiResponse = unknown;
export type RoutineOccurrenceControllerGetExcludedDatesApiArg = {
  routineId: string;
};
export type CheckPhoneBody = {
  phone: string;
};
export type BasicRoutine = {
  repeatInterval?: 'day' | 'week' | 'month';
  id: number;
  createdOn: string;
  updatedOn: string;
  dependentId: string;
  caretakerId: string;
  name: string;
  repeats: boolean;
  repeatOptions: string | null;
  startDate: string;
  endDate: string | null;
  requirePhoto: boolean;
  tasks: string[];
  reminder: number | null;
  examplePhoto: string | null;
  deleted: string | null;
  private: boolean | null;
  googleCalendarEventId: string;
  originalRoutineId: number | null;
};
export type ExcludedDate = {
  id: string;
  routineId: number;
  date: string;
};
export type ModifiedDate = {
  id: string;
  date: string;
  originalDate: string;
  routineId: number;
};
export type Routine = {
  repeatInterval?: 'day' | 'week' | 'month';
  googleCalendarEventId?: string | null;
  originalRoutineId?: number | null;
  originalRoutine?: BasicRoutine | null;
  modifiedRoutines?: BasicRoutine[];
  id: number;
  createdOn: string;
  updatedOn: string;
  dependentId: string;
  caretakerId: string;
  name: string;
  repeats: boolean;
  repeatOptions: string | null;
  startDate: string;
  endDate: string | null;
  requirePhoto: boolean;
  tasks: string[];
  reminder: number | null;
  examplePhoto: string | null;
  deleted: string | null;
  private: boolean | null;
  sharedCaretakers: Caretaker[];
  excludedDates: ExcludedDate[];
  modifiedDates: ModifiedDate[];
};
export type Goal = {
  id: number;
  createdOn: string;
  updatedOn: string;
  name: string;
  starsRequired: number;
  rewardPhoto: string | null;
  dependentId: string;
  caretakerId: string;
  awarded: string;
};
export type CompletedTaskFeedback = {
  id: number;
  createdOn: string;
  updatedOn: string;
  completedTaskId: number;
  text: string;
  caretakerId: string;
  caretaker?: Caretaker;
};
export type CompletedTask = {
  status: 'attempted' | 'passed' | 'failed' | 'notAttempted';
  id: number;
  createdOn: string;
  updatedOn: string;
  name: string;
  completedRoutineId: number;
  caretakerId: string;
  caretaker?: Caretaker;
  feedback: CompletedTaskFeedback[];
};
export type Feedback = {
  id: number;
  createdOn: string;
  updatedOn: string;
  completedRoutineId: number;
  text: string;
  caretakerId: string;
  caretaker?: Caretaker;
};
export type CompletedRoutine = {
  status: 'attempted' | 'passed' | 'failed' | 'notAttempted';
  id: number;
  createdOn: string;
  updatedOn: string;
  dependentId: string;
  dependent: Dependent;
  routineId: number;
  tasks: CompletedTask[];
  photo: string | null;
  routine: Routine;
  starsAwarded: number;
  feedback: Feedback[];
};
export type Dependent = {
  id: string;
  createdOn: string;
  updatedOn: string;
  profileId: string;
  profile?: Profile;
  verified: boolean;
  routines?: Routine[];
  goals?: Goal[];
  completedRoutines?: CompletedRoutine[];
  caretakers?: CaretakerDependent[];
  smsNotifications: boolean;
  routineStartsNotification: boolean;
  taskRedoneNotification: boolean;
};
export type RelationshipType = {
  id: number;
  name: string;
};
export type CaretakerDependent = {
  inviteStatus: 'pending' | 'accepted' | 'declined';
  caretaker: Caretaker;
  dependent: Dependent;
  caretakerId: string;
  dependentId: string;
  relationshipTypeId: number;
  relationshipType: RelationshipType;
};
export type Caretaker = {
  id: string;
  createdOn: string;
  updatedOn: string;
  profileId: string;
  profile?: Profile;
  routines?: Routine[];
  goals?: Goal[];
  dependents?: CaretakerDependent[];
  smsNotifications: boolean;
  routineCompletedNotification: boolean;
  missedRoutineNotification: boolean;
};
export type Permission = {
  role: 'caretaker' | 'young_person' | 'admin';
  id: number;
};
export type ProfilePermission = {
  profileId: string;
  permissionId: number;
  permission?: Permission;
};
export type Profile = {
  id: string;
  createdOn: string;
  updatedOn: string;
  lastLogin: string;
  email: string | null;
  firstName: string;
  lastName: string;
  phone: string;
  photo: string;
  caretaker?: Caretaker;
  dependent?: Dependent;
  permissions?: ProfilePermission[];
};
export type CreateProfile = {
  id?: string;
  email?: string;
  firstName: string;
  lastName: string;
  phone: string;
  photo?: string;
  permissions: string[];
  smsNotifications: boolean;
};
export type CreateCaretakerProfile = {
  caretaker: {
    email?: string;
    phone?: string;
    firstName?: string;
    lastName?: string;
    photo?: string;
  };
  dependent: {
    phone?: string;
    firstName?: string;
    lastName?: string;
  };
  relationshipId: number;
  smsNotifications: boolean;
};
export type UpdateProfile = {
  id: string;
  email?: string;
  lastLogin?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  photo?: string;
};
export type ChangeCaretakerStatusBody = {
  status: 'pending' | 'accepted' | 'declined';
  caretakerId: string;
  dependentId: string;
};
export type AddDependent = {
  firstName: string;
  lastName: string;
  phone: string;
  photo?: string;
  permissions: string[];
  relationshipId: string;
  caretakerId: string;
  smsNotifications: boolean;
};
export type CreateRoutinePayload = {
  googleCalendarEventId?: string | null;
  originalRoutineId?: number | null;
  name: string;
  repeats: boolean;
  startDate: string;
  repeatInterval: object;
  repeatOptions: string | null;
  reminder: number;
  requirePhoto: boolean;
  tasks: string[];
  dependentId: string;
  examplePhoto: string | null;
  private: boolean | null;
  sharedCaretakers: string[];
  caretakerId: string;
};
export type UpdateRoutinePayload = {
  id?: number;
  name?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  repeats?: boolean;
  repeatInterval?: object;
  repeatOptions?: string;
  examplePhoto?: string;
  rewardStars?: number;
  dueDate?: string;
  sharedCaretakers?: string[];
  excludedDates?: ExcludedDate[];
  modifiedDates?: ModifiedDate[];
  modifiedRoutines?: object[];
  googleCalendarEventId?: string;
  originalRoutine?: object;
};
export type CreateGoal = {
  name: string;
  dependentId: string;
  caretakerId: string;
  starsRequired: number;
  rewardPhoto: string;
};
export type GoalResponse = {
  goals: Goal[];
  starsAwarded: number;
  totalStars: number;
};
export type CreateCompletedRoutinePayload = {
  status: 'attempted' | 'passed' | 'failed' | 'notAttempted';
  dependentId: string;
  routineId: number;
  tasks: object[];
  photo?: string;
  privateRoutine: boolean;
};
export type MinimalCompletedRoutine = {
  status: 'attempted' | 'passed' | 'failed' | 'notAttempted';
  id: number;
  createdOn: string;
  routineId: number;
  dependentId: string;
  updatedOn: string;
  starsAwarded: number;
};
export type RoutineWithCompleted = {
  repeatInterval?: 'day' | 'week' | 'month';
  googleCalendarEventId?: string | null;
  originalRoutineId?: number | null;
  originalRoutine?: BasicRoutine | null;
  modifiedRoutines?: BasicRoutine[];
  id: number;
  createdOn: string;
  updatedOn: string;
  dependentId: string;
  caretakerId: string;
  name: string;
  repeats: boolean;
  repeatOptions: string | null;
  startDate: string;
  endDate: string | null;
  requirePhoto: boolean;
  tasks: string[];
  reminder: number | null;
  examplePhoto: string | null;
  deleted: string | null;
  private: boolean | null;
  sharedCaretakers: Caretaker[];
  excludedDates: ExcludedDate[];
  modifiedDates: ModifiedDate[];
  completedRoutines: MinimalCompletedRoutine[];
};
export type SingleRoutineReport = {
  assigned: number;
  completed: number;
  incomplete: number;
  percentComplete: number;
  routine: RoutineWithCompleted;
};
export type RoutinesReport = {
  frequency: 'day' | 'week' | 'month';
  assigned: number;
  completed: number;
  incomplete: number;
  percentComplete: number;
  name: string;
  id: number;
};
export type RoutineOccurrencePayload = {
  date: string;
  originalDate?: string;
};
export const {
  useAppControllerGetHelloQuery,
  useProfileControllerCheckPhoneMutation,
  useProfileControllerGetByEmailQuery,
  useProfileControllerGetByPhoneQuery,
  useProfileControllerGetAllProfilesQuery,
  useProfileControllerCreateDependentProfileMutation,
  useProfileControllerCreateCaretakerProfileMutation,
  useProfileControllerCreateProfileMutation,
  useProfileControllerUpdateProfileMutation,
  useProfileControllerChangeCaretakerStatusMutation,
  useProfileControllerAddDependentToCaretakerMutation,
  useStorageControllerGenerateReadUrlQuery,
  useStorageControllerGenerateWriteUrlQuery,
  useStorageControllerUploadFileMutation,
  useRoutineControllerCreateRoutineMutation,
  useRoutineControllerUpdateRoutineMutation,
  useRoutineControllerGetAllRoutinesForDependentQuery,
  useRoutineControllerDeleteRoutineMutation,
  useRoutineControllerGetRoutineByIdQuery,
  useGoalControllerCreateGoalMutation,
  useGoalControllerUpdateGoalMutation,
  useGoalControllerGetGoalsByDependantQuery,
  useGoalControllerAwardGoalMutation,
  useCaretakerControllerGetByProfileIdQuery,
  useCaretakerControllerUpdateMutation,
  useCompleteRoutineControllerCreateCompleteRoutineMutation,
  useCompleteRoutineControllerUpdateCompleteRoutineMutation,
  useCompleteRoutineControllerGetCompletedRoutineQuery,
  useCompleteRoutineControllerGetDateCompletedQuery,
  useCompleteRoutineControllerGetRangeCompletedQuery,
  useCompleteRoutineControllerGetLastCompletedQuery,
  useCompleteRoutineControllerGetAttemptedQuery,
  useCompleteRoutineControllerGetCompletedQuery,
  useCompleteRoutineControllerGetFailedQuery,
  useNotificationsControllerTriggerNotificationsQuery,
  useNotificationsControllerSendReminderMutation,
  useDependentControllerUpdateMutation,
  useDependentControllerGetDependentQuery,
  useDependentReportingControllerGetSingleRoutineReportQuery,
  useDependentReportingControllerGetRoutinesReportQuery,
  useRoutineOccurrenceControllerModifyDateMutation,
  useRoutineOccurrenceControllerCancelOccurrenceMutation,
  useRoutineOccurrenceControllerGetModifiedDatesQuery,
  useRoutineOccurrenceControllerGetExcludedDatesQuery,
} = injectedRtkApi;

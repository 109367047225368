import { format } from 'date-fns';
import { FC } from 'react';
import { Goal } from '../../../shared/store/api/endpoints';
import GoalStatus from '../GoalStatus';
import { ellipsisHorizontal } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { motion } from 'framer-motion';

interface CompletedRewardProps {
  goal: Goal;
  handleActionSheet?: () => void;
  pendingText?: string;
  notStarted?: boolean;
}

const CompletedReward: FC<CompletedRewardProps> = ({
  goal,
  handleActionSheet,
  pendingText = 'Not given yet',
  notStarted,
}) => {
  return (
    <div className="flex flex-col gap-2 bg-white p-3">
      <div className="flex justify-between">
        <span className="font-semibold">{goal.name}</span>
        <div>
          {!goal.awarded && handleActionSheet && (
            <motion.button className="h-[25px] w-[40px]">
              <IonIcon icon={ellipsisHorizontal} onClick={handleActionSheet} />
            </motion.button>
          )}
        </div>
      </div>
      <div className="flex justify-between">
        {goal.awarded ? (
          <span>Earned {format(new Date(goal.awarded), 'PPP')}</span>
        ) : (
          <div>{pendingText}</div>
        )}
        <GoalStatus
          status={
            notStarted ? 'notStarted' : goal.awarded ? 'received' : 'pending'
          }
        />
      </div>
    </div>
  );
};

export default CompletedReward;

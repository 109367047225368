import { useAuth0 } from '@auth0/auth0-react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
} from '@ionic/react';
import { ellipsisVertical } from 'ionicons/icons';
import { useDependentReportingControllerGetRoutinesReportQuery } from '../../../../shared/store/api/endpoints';
import useProfile from '../../../../shared/hooks/useProfile';
import { ResponsiveTable } from '../../../_components/ResponsiveTable';
import {
  DateRange,
  ProgressSortAndFilterHeader,
} from '../../../_components/progress-sort-and-filter-header';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../shared/store/store';
import { setDateRange } from './_store/dependent-progress.store';
import { ProgressTable } from '../../../_components/ProgressTable';
import { MultipleRoutinesProgressHeader } from '../../../_components/multiple-routines-progress-header';
import useRoutines from '../../../../shared/hooks/useRoutines';
import { useState } from 'react';
import { useSort } from './_hooks/use-sort';
import { MobileProgressTable } from '../../../_components/MobileProgressTable';

export function DependentProgressPage() {
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const dateRange = useAppSelector((state) => state.dependentProgress);
  const [presentAction] = useIonActionSheet();
  const { profile } = useProfile();
  const { routines } = useRoutines(profile?.dependent?.id as string, true);
  const [selectedRoutines, setSelectedRoutines] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedFrequency, setSelectedFrequency] = useState<string[]>([]);
  const handleActionSheet = () => {
    presentAction([
      {
        text: 'Log out',
        handler: () => {
          logout({ returnTo: window.location.origin });
        },
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  const { data } = useDependentReportingControllerGetRoutinesReportQuery(
    {
      dependentId: profile?.dependent?.id as string,
      caretakerId: '',
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      routineIds: selectedRoutines || [],
      completedStatus: selectedStatus || [],
      frequency: selectedFrequency || [],
    },
    {
      skip: !profile?.dependent?.id,
    },
  );

  const { sortedData, changeSort, sort } = useSort({
    data: data || [],
    columns: [
      'name',
      'frequency',
      'assigned',
      'completed',
      'incomplete',
      'percentComplete',
    ],
  });

  const filteredData = sortedData.filter((sorted) => sorted.assigned !== 0);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="end">
            <IonButton onClick={handleActionSheet}>
              <IonIcon icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>
          <IonTitle>Progress</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {filteredData && (
          <ResponsiveTable
            desktopComponent={
              <div className="flex justify-center">
                <div className="flex flex-col">
                  <ProgressSortAndFilterHeader
                    dateRange={dateRange}
                    setDateRange={(dateRange: DateRange) =>
                      dispatch(setDateRange(dateRange))
                    }
                    routines={routines}
                    selectedRoutines={selectedRoutines}
                    setSelectedRoutines={setSelectedRoutines}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                    selectedFrequency={selectedFrequency}
                    setSelectedFrequency={setSelectedFrequency}
                    sort={sort}
                    changeSort={changeSort}
                  />
                  <div className="flex flex-col">
                    <MultipleRoutinesProgressHeader data={filteredData} />
                    <ProgressTable
                      data={filteredData}
                      changeSort={changeSort}
                      sort={sort}
                    />
                  </div>
                </div>
              </div>
            }
            mobileComponent={
              <IonGrid fixed>
                <div className="flex flex-col gap-4">
                  <ProgressSortAndFilterHeader
                    dateRange={dateRange}
                    setDateRange={(dateRange: DateRange) =>
                      dispatch(setDateRange(dateRange))
                    }
                    isMobile
                    routines={routines}
                    selectedRoutines={selectedRoutines}
                    setSelectedRoutines={setSelectedRoutines}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                    selectedFrequency={selectedFrequency}
                    setSelectedFrequency={setSelectedFrequency}
                    sort={sort}
                    changeSort={changeSort}
                  />
                  <div className="flex flex-col">
                    <span className="text-center font-semibold uppercase">
                      SUMMARY
                    </span>
                    <MultipleRoutinesProgressHeader
                      data={filteredData}
                      isMobile
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-center font-semibold uppercase">
                      DETAILS
                    </span>
                    <MobileProgressTable data={filteredData} />
                  </div>
                </div>
              </IonGrid>
            }
          />
        )}
      </IonContent>
    </IonPage>
  );
}

import { UserCircleIcon } from '@heroicons/react/24/solid';
import Image from '../../../shared/components/Image';
const ProfileDetails = ({ profile }: { profile: any }) => {
  return (
    <>
      <section className="relative flex items-start justify-between bg-neutral-100 px-8 pt-6 pb-4">
        <div className="absolute top-0 left-0 right-0 z-0 h-20 w-full bg-neutral-200" />
        <div className="z-10">
          {profile.photo ? (
            <Image
              src={profile.photo}
              alt="profile"
              className="mx-auto h-[100px] w-[100px] rounded-full shadow-md drop-shadow-xl"
            />
          ) : (
            <UserCircleIcon className="h-[80px] w-[80px] rounded-full bg-neutral-100 text-gray-400 ring-4 ring-primary-navy ring-offset-4" />
          )}
        </div>
      </section>

      <section className="bg-neutral-100 px-6 pt-2">
        <h1 className="text-3xl font-bold text-gray-700">
          {profile.firstName} {profile.lastName}
        </h1>
        <p className="mt-1 text-gray-600">{profile.phone}</p>
      </section>
    </>
  );
};

export default ProfileDetails;

import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { RoutinesReport } from '../../../../../shared/store/api/endpoints';

export interface ProgressSortButtonProps {
  changeSort: (column: keyof RoutinesReport) => void;
  sort: { direction: 'asc' | 'desc' | undefined; column: keyof RoutinesReport };
}

export function ProgressSortButton({
  changeSort,
  sort: { direction, column },
}: ProgressSortButtonProps) {
  const sortMenu = useRef<any>(null);

  function renderSortIcon(
    selected: boolean,
    direction: 'asc' | 'desc' | undefined,
  ) {
    if (selected) {
      if (direction === 'asc') {
        return 'pi pi-arrow-up';
      } else if (direction === 'desc') {
        return 'pi pi-arrow-down';
      }
    }
    return 'pi pi-sort-alt';
  }
  const sortOptions = [
    {
      label: `Routine`,
      icon: renderSortIcon(column === 'name', direction),
      command: () => changeSort('name'),
      className: classNames({
        'font-bold bg-primary-teal bg-opacity-20': column === 'name',
      }),
    },
    {
      label: `Frequency`,
      icon: renderSortIcon(column === 'frequency', direction),
      command: () => changeSort('frequency'),
      className: classNames({
        'font-bold bg-primary-teal bg-opacity-20': column === 'frequency',
      }),
    },
    {
      label: `Assigned`,
      icon: renderSortIcon(column === 'assigned', direction),
      command: () => changeSort('assigned'),
      className: classNames({
        'font-bold bg-primary-teal bg-opacity-20': column === 'assigned',
      }),
    },
    {
      label: `Completed`,
      icon: renderSortIcon(column === 'completed', direction),
      command: () => changeSort('completed'),
      className: classNames({
        'font-bold bg-primary-teal bg-opacity-20': column === 'completed',
      }),
    },
    {
      label: `Incomplete`,
      icon: renderSortIcon(column === 'incomplete', direction),
      command: () => changeSort('incomplete'),
      className: classNames({
        'font-bold bg-primary-teal bg-opacity-20': column === 'incomplete',
      }),
    },
    {
      label: `Completion %`,
      icon: renderSortIcon(column === 'percentComplete', direction),
      command: () => changeSort('percentComplete'),
      className: classNames({
        'font-bold bg-primary-teal bg-opacity-20': column === 'percentComplete',
      }),
    },
  ];
  return (
    <>
      {' '}
      <Button
        label="Sort"
        icon="pi pi-sort-alt"
        onClick={(event) => sortMenu.current?.toggle(event)}
      />
      <Menu model={sortOptions} popup ref={sortMenu} />
    </>
  );
}

import { StarIcon } from '@heroicons/react/24/solid';
import { IonModal } from '@ionic/react';
import { motion } from 'framer-motion';
import { ProgressBar } from 'primereact/progressbar';
import { useRef, useState } from 'react';
import Button from '../../../../shared/components/Button';
import { fadeIn } from '../../../../shared/helpers/animation';
import { Profile } from '../../../../shared/store/api/endpoints';
import CreateGoalModal from '../CreateGoalModal';

const RewardEmptyState = ({
  rootRef,
  dependentProfile,
}: {
  rootRef: any;
  dependentProfile: Profile;
}) => {
  const [showGoalModal, setShowGoalModal] = useState(false);
  const goalModalRef = useRef<HTMLIonModalElement>(null);

  const progressBarTemplate = (value: any) => {
    return (
      <div
        className={`flex items-center bg-gradient-to-tr ${
          value === 0 ? 'text-gray-800' : 'text-white'
        }`}
      >
        <StarIcon className="h-4 w-8" />
        {/* TODO: Pull this number from the number of stars achieved */}
        <p>{value}</p>
      </div>
    );
  };

  return (
    <motion.div
      layoutId="reward-empty-state"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <div className="pt-6">
        <p className=" mb-4 font-medium">
          You have not added a new goal for {dependentProfile.firstName} to work
          toward.
        </p>
        <ProgressBar
          value={0}
          displayValueTemplate={progressBarTemplate}
          className="my-8"
        />
        <Button
          label="Add a Reward"
          onClick={() => setShowGoalModal(true)}
          icon="pi pi-plus"
        />
      </div>
      <IonModal
        ref={goalModalRef}
        isOpen={showGoalModal}
        canDismiss={true}
        presentingElement={rootRef.current || undefined}
        onDidDismiss={() => setShowGoalModal(false)}
      >
        <CreateGoalModal
          onDismiss={() => setShowGoalModal(false)}
          parentModalRef={goalModalRef}
        />
      </IonModal>
    </motion.div>
  );
};

export default RewardEmptyState;

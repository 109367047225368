import Image from '../../../shared/components/Image';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeIn } from '../../../shared/helpers/animation';
import { Profile } from '../../../shared/store/api/endpoints';

const ProfileCard = ({
  profile,
  relationship,
}: {
  profile: Profile;
  relationship: string;
}) => {
  return (
    <div className="mt-2 flex items-center gap-x-4 rounded-md border-2 border-gray-100 bg-white py-4 px-6 shadow-lg">
      <AnimatePresence>
        {profile.photo ? (
          <motion.div
            variants={fadeIn}
            initial="hidden"
            animate="visible"
            exit="hidden"
            key="photo"
          >
            <Image
              src={profile.photo}
              alt="Dependent profile photo"
              className="w-16 rounded-full text-gray-300 shadow-md drop-shadow-lg"
            />
          </motion.div>
        ) : (
          <UserCircleIcon className="w-16 text-gray-400" />
        )}
        <motion.div
          variants={fadeIn}
          initial="hidden"
          animate="visible"
          exit="hidden"
          key="name"
        >
          <p className="text-xl font-semibold text-gray-800">
            {profile.firstName} {profile.lastName}
          </p>
          <p className="text-sm text-gray-600">{relationship}</p>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default ProfileCard;

import { Calendar } from 'primereact/calendar';
import ModalWrapper from '../../../shared/components/ModalWrapper/ModalWrapper';
import {
  Routine,
  useRoutineOccurrenceControllerModifyDateMutation,
} from '../../../shared/store/api/endpoints';
import { useState } from 'react';
import { endOfDay, format, isBefore, isSameDay, startOfDay } from 'date-fns';
import { Button } from 'primereact/button';
import { useIonToast } from '@ionic/react';
import { occurrences } from '../../../shared/utility/recurrence';

interface EditOccurrenceModalProps {
  routine: Routine;
  onDismiss: () => void;
  start: Date;
}
export function EditOccurrenceModal({
  routine,
  onDismiss,
  start,
}: EditOccurrenceModalProps) {
  const [startDate, setStartDate] = useState<Date>(new Date(start));
  const [modifyDate, { isLoading }] =
    useRoutineOccurrenceControllerModifyDateMutation();
  const [present] = useIonToast();

  async function updateRoutineOccurrence() {
    try {
      if (isBefore(startDate, startOfDay(new Date(routine.startDate)))) {
        throw new Error(
          "Cannot schedule before the original routine's start date.",
        );
      }
      const found = routine.modifiedDates.find((d) =>
        isSameDay(new Date(d.date), start),
      );
      // returns void.  if there's an error, it throws it.
      routineAlreadyScheduledForDate(startDate);
      await modifyDate({
        routineId: routine.id.toString(),
        routineOccurrencePayload: {
          date: startDate.toISOString(),
          originalDate: found ? found.originalDate : start.toISOString(),
        },
      }).unwrap();
      present('Routine successfully updated', 2500);
      onDismiss();
    } catch (error: any) {
      present(`Error updating routine.  ${error?.message}`, 2500);
    }
  }

  // check to see if the routine is already scheduled for the date in question
  function routineAlreadyScheduledForDate(date: Date): void {
    // if the date is the same as the start date, then we don't need to check, since they are just changing the time
    if (isSameDay(date, start)) return;
    const routineOccurrences = occurrences({
      routine,
      endDate: endOfDay(date),
    });
    // check to see if the routine has an occurrence on the date in question
    if (routineOccurrences?.find((occurrence) => isSameDay(occurrence, date))) {
      throw new Error(
        'Another instance of this routine is already scheduled for this date.',
      );
    }
    // check to see if we already have a modified date that day
    if (routine.modifiedDates.find((d) => isSameDay(new Date(d.date), date))) {
      throw new Error(
        'Another instance of this routine is already scheduled for this date.',
      );
    }
  }

  return (
    <ModalWrapper title="Edit Occurrence" onDismiss={onDismiss}>
      <main className="px-4 pt-6">
        <div className="flex flex-col gap-6">
          <span className="text-center text-xl font-bold">{routine.name}</span>
          <div className="mt-4 flex w-full flex-col">
            <label htmlFor="startDate" className="mb-2">
              Change the date & time of this occurrence:
            </label>
            <Calendar
              id="startDate"
              value={startDate}
              onChange={(e) => e.value && setStartDate(e.value as Date)}
              showTime
              hourFormat="12"
              inline
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex w-full justify-between px-4">
              <span className="font-semibold">Old date and time:</span>
              <span>{format(start, 'Pp')}</span>
            </div>
            <div className="flex w-full justify-between px-4">
              <span className="font-semibold">New date and time:</span>
              <span>{format(startDate, 'Pp')}</span>
            </div>
          </div>
          <div className="flex">
            <Button
              label="Save changes"
              loading={isLoading}
              onClick={updateRoutineOccurrence}
              disabled={isLoading}
            />
          </div>
        </div>
      </main>
    </ModalWrapper>
  );
}

import { IonModal } from '@ionic/react';
import {
  Eventcalendar as EventCalendar,
  MbscEventcalendarView,
} from '@mobiscroll/react';
import { motion } from 'framer-motion';
import { useRef, useState } from 'react';
import { useParams } from 'react-router';
import Button from '../../../../../shared/components/Button';
import { fadeIn } from '../../../../../shared/helpers/animation';
import useDependent from '../../../../../shared/hooks/useDependent';
import { Dependent, Profile } from '../../../../../shared/store/api/endpoints';
import { CaretakerCreateRoutineModal } from '../../CaretakerCreateRoutineModal';

const RoutineEmptyState = ({
  rootRef,
  showCalendar = false,
}: {
  rootRef: any;
  showCalendar?: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams() as { id: string };
  const dependent = useDependent(id) as Dependent;

  //Calendar view
  const events: any[] = [];
  const view: MbscEventcalendarView = {
    calendar: { type: 'week' },
    agenda: { type: 'day' },
  };

  const routineModalRef = useRef<HTMLIonModalElement>(null);

  return dependent ? (
    <motion.div
      layoutId="routine-empty-state"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      {showCalendar && (
        <EventCalendar
          theme="ios"
          themeVariant="light"
          data={events}
          view={view}
        />
      )}
      <div className="p-4 pt-6">
        <p className=" mb-4 font-medium">
          You have not added a routine for{' '}
          {(dependent.profile as Profile).firstName} yet.
        </p>
        <Button
          label="Add a Routine"
          onClick={() => setShowModal(true)}
          icon="pi pi-plus"
        />
      </div>
      <IonModal
        ref={routineModalRef}
        isOpen={showModal}
        canDismiss={true}
        presentingElement={rootRef.current || undefined}
        onDidDismiss={() => setShowModal(false)}
      >
        {showModal && (
          <CaretakerCreateRoutineModal
            onDismiss={() => setShowModal(false)}
            parentModalRef={routineModalRef}
          />
        )}
      </IonModal>
    </motion.div>
  ) : (
    <></>
  );
};

export default RoutineEmptyState;

import { format } from 'date-fns';
import Button from '../../../../shared/components/Button';
import { useHistory, useParams } from 'react-router-dom';
import PageWrapper from '../../../../shared/components/PageWrapper';
import { useCompleteRoutineControllerGetCompletedRoutineQuery } from '../../../../shared/store/api/endpoints';
import useProfile from '../../../../shared/hooks/useProfile';
import { useEffect } from 'react';
import FullPageLoading from '../../../../shared/components/FullPageLoading/FullPageLoading';

export function CompletedFeedbackPage() {
  const { completedRoutineId } = useParams() as { completedRoutineId: string };
  const { data: completed } =
    useCompleteRoutineControllerGetCompletedRoutineQuery({
      id: completedRoutineId,
    });
  const { profile } = useProfile();
  const history = useHistory();

  useEffect(() => {
    if (profile && completed) {
      // only dependents can access this page
      if (!profile.dependent) {
        history.push('/');
      }
      // only the dependent who this routine belongs to can access this page
      if (completed.routine.dependentId !== profile.dependent?.id) {
        history.push('/');
      }
    }
  }, [completed, profile, history]);
  return (
    <PageWrapper
      title="Routine Feedback"
      dismissType="back"
      defaultHref="/tabs/routines"
    >
      <div className="py-8">
        <div className="flex flex-col">
          <span className="px-4 pb-6 text-center text-lg font-semibold">
            Looks like you need to redo something
          </span>
          {completed && completed.id === parseInt(completedRoutineId) ? (
            <>
              <div className="flex flex-col bg-white px-4 pt-4 shadow-lg">
                <div className="mb-6 flex justify-between border-l-2 border-primary-navy px-4">
                  <span className="text-xl font-semibold">
                    {completed.routine.name}
                  </span>
                  <span className="text-xl">
                    {format(new Date(completed.routine.startDate), 'p')}
                  </span>
                </div>
                {completed.feedback
                  .filter((feedback) => feedback.text.length > 0)
                  .map((feedback) => (
                    <span className="px-4 pb-6 font-light" key={feedback.id}>
                      "{feedback.text}" -{' '}
                      {feedback.caretaker?.profile?.firstName}
                    </span>
                  ))}

                <div className="flex justify-center pb-4">
                  <div className="w-4/5">
                    <Button
                      label="Start the redo"
                      onClick={() =>
                        history.push(
                          `/complete-routine/${completed.routineId}/${completed.id}/redo`,
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <FullPageLoading />
          )}
        </div>
      </div>
    </PageWrapper>
  );
}

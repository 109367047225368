import { isArray } from 'lodash';
import { useState } from 'react';
import useStorage from '../../../../shared/hooks/useStorage';
import {
  useRoutineControllerCreateRoutineMutation,
  useRoutineControllerUpdateRoutineMutation,
} from '../../../../shared/store/api/endpoint-with-tags';
import { Routine } from '../../../../shared/store/api/endpoints';
import useProfile from '../../../../shared/hooks/useProfile';
import useDependent from '../../../../shared/hooks/useDependent';
import { useParams } from 'react-router';
import { RoutineForm } from '../../../_components/CreateRoutineModal/create-routine-modal.types';
import { getHours, getMinutes, setHours, setMinutes, subDays } from 'date-fns';

const useCaretakerCreateRoutineModal = () => {
  const { profile } = useProfile();
  const { id } = useParams() as { id: string };
  const dependent = useDependent(id);
  const [fileUploading, setFileUploading] = useState(false);
  const [
    createRoutine,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
    },
  ] = useRoutineControllerCreateRoutineMutation();
  const [
    updateRoutine,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useRoutineControllerUpdateRoutineMutation();
  const { uploadFile } = useStorage();

  const saveRoutine = async (form: RoutineForm, routine?: Routine) => {
    if (!profile) return;
    const { dependents, ...routineForm } = form;
    // if there is no dependent or dependents selected, shouldn't run.
    if (dependents.length === 0 && !dependent) return;
    try {
      let path;
      if (
        (routine &&
          routineForm.examplePhoto &&
          routine.examplePhoto !== routineForm.examplePhoto) ||
        (!routine && routineForm.examplePhoto)
      ) {
        // uploading file state just in case things are slow.
        setFileUploading(true);
        const date = new Date().getTime();
        path = await uploadFile(
          routineForm.examplePhoto,
          `${date}.jpeg`,
          'image/jpeg',
        );
        setFileUploading(false);
        // todo add cleanup for old files
      }

      let body: any = {
        ...routineForm,
        startDate: routineForm.startDate.toISOString(),
        repeatOptions: routineForm.repeatOptions
          ? isArray(routineForm.repeatOptions)
            ? routineForm.repeatOptions.join(',')
            : routineForm.repeatOptions
          : null,
      };
      // handle update/editing
      if (routine) {
        // end date would be today with the same hours/minutes as the start date
        let endDate = new Date();
        endDate = setHours(endDate, getHours(new Date(routine.startDate)));
        endDate = setMinutes(endDate, getMinutes(new Date(routine.startDate)));
        endDate = subDays(endDate, 1);
        body = {
          ...routine,
          ...body,
          examplePhoto: path || routine.examplePhoto,
          endDate, // passing end date as a precaution in case recurrence has changed
        };
        return updateRoutine({
          updateRoutinePayload: body,
          id: routine.id,
        }).unwrap();
      } else {
        // create new routine
        body = {
          ...body,
          caretakerId: profile.caretaker?.id,
          dependentId: dependent?.id as string, // this won't populate if no dependent is selected
          examplePhoto: path || null,
          repeatInterval: body.repeats ? body.repeatInterval : null,
        };
        // handle multiple dependents from dependents page
        if (dependents.length > 0) {
          for (let i = 0; i < dependents.length; i++) {
            const dependentId = dependents[i];
            body.dependentId = dependentId;
            return createRoutine({ createRoutinePayload: body }).unwrap();
          }
        } else {
          // handle single dependent from routine page
          return createRoutine({
            createRoutinePayload: body,
          }).unwrap();
        }
      }
    } finally {
      setFileUploading(false);
    }
  };

  return {
    isLoading: createLoading || fileUploading || updateLoading,
    createSuccess,
    createError,
    updateSuccess,
    updateError,
    saveRoutine,
  };
};

export default useCaretakerCreateRoutineModal;

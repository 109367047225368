import { FC } from 'react';
import StatusPill from '../StatusPill';

interface CompletedStatusProps {
  status: 'attempted' | 'failed' | 'passed' | 'notAttempted';
}

const CompletedStatus: FC<CompletedStatusProps> = ({ status }) => {
  switch (status) {
    case 'attempted':
      return <StatusPill label="Attempted" color="yellow" />;
    case 'failed':
      return <StatusPill label="Incomplete" color="red" />;
    case 'passed':
      return <StatusPill label="Passed" color="green" />;
    default:
      return (
        <span className="flex h-[25px]  w-[90px] items-center justify-center rounded-full text-gray-500">
          Not Attempted
        </span>
      );
  }
};

export default CompletedStatus;

import { motion } from 'framer-motion';
import { InputText } from 'primereact/inputtext';
import { fadeIn } from '../../../../../shared/helpers/animation';
import { GoalForm } from '../CreateGoalModal';
import RewardSlider from '../../RewardSlider';

const GoalDetails: React.FC<{
  goalForm: GoalForm;
  handleGoalChange: (val: any, key: string) => void;
}> = ({ goalForm: { name, starsRequired }, handleGoalChange }) => {
  return (
    <motion.div
      layout
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <div className="flex flex-col pb-4">
        <label className="mb-1" htmlFor="reward-name">
          Reward name
        </label>
        <InputText
          id="reward-name"
          className="w-full"
          value={name}
          onChange={(e) => handleGoalChange(e.target.value, 'name')}
        />
      </div>
      <RewardSlider
        stars={starsRequired}
        onChange={(stars) => handleGoalChange(stars, 'starsRequired')}
      />
    </motion.div>
  );
};

export default GoalDetails;

import { CompletedTask } from '../../../../../../shared/store/api/endpoints';
import PreviousFeedback from '../../../../../caretaker/pages/CaretakerRoutinesPage/PreviousFeedback';

export interface DependentPassedTaskProps {
  task: CompletedTask;
  index: number;
}

export default function DependentPassedTask({
  task,
  index,
}: DependentPassedTaskProps) {
  return (
    <div className="my-2 flex flex-col bg-white p-4 shadow-sm">
      <div className="flex items-center">
        <div>
          {/* Added a parent div to avoid distortion of circle when task name is two lines */}
          <div className="flex h-8 w-8 min-w-max items-center justify-center rounded-full bg-primary-navy/50">
            <p className="text-sm font-bold text-white">{index + 1}</p>
          </div>
        </div>
        <p className="ml-4 text-xl font-bold leading-5 text-primary-navy">
          {task.name}
        </p>
      </div>
      {task.feedback && task.feedback.length > 0 && (
        <div className="pt-4">
          <PreviousFeedback feedback={task.feedback.filter((f) => f.id)} />
        </div>
      )}
    </div>
  );
}

import {
  CompletedTaskFeedback,
  Feedback,
} from '../../../../../shared/store/api/endpoints';

export interface PreviousFeedbackProps {
  feedback: Feedback[] | CompletedTaskFeedback[];
}

export default function PreviousFeedback({ feedback }: PreviousFeedbackProps) {
  return (
    <div className="flex flex-col gap-2 px-4 pb-4">
      {feedback.map((feedback) => (
        <div className="rounded-lg bg-white" key={feedback.id}>
          <span>
            "{feedback.text}" - {feedback.caretaker?.profile?.firstName}
          </span>
        </div>
      ))}{' '}
    </div>
  );
}

import { Checkbox } from 'primereact/checkbox';
import AddPhoto from '../../../caretaker/components/AddPhoto';
import { RoutineForm } from '../create-routine-modal.types';

const AddCompletionPhoto: React.FC<{
  routineForm: RoutineForm;
  handleRoutineChange: (val: any, key: string) => void;
  parentModalRef: any;
}> = ({
  routineForm: { examplePhoto: photo, requirePhoto },
  handleRoutineChange,
  parentModalRef,
}) => {
  const handlePhoto = async (image: string) => {
    handleRoutineChange(image, 'examplePhoto');
  };

  return (
    <div className="py-4">
      <div className="mt-4 flex flex-col">
        <p className="pb-2">(Optional) Add a completion example photo</p>
        <AddPhoto
          photo={photo}
          addPhoto={handlePhoto}
          removePhoto={() => handleRoutineChange(null, 'examplePhoto')}
          alt="completion example"
          parentModalRef={parentModalRef}
        />
      </div>
      <div className="flex w-full items-center justify-start gap-2 pt-4">
        <Checkbox
          inputId="require-photo"
          value={requirePhoto}
          onChange={() => handleRoutineChange(!requirePhoto, 'requirePhoto')}
          checked={requirePhoto}
        />
        <label htmlFor="require-photo">
          Require a routine completion photo
        </label>
      </div>
    </div>
  );
};

export default AddCompletionPhoto;

import { useMemo } from 'react';
import { useGoalControllerGetGoalsByDependantQuery } from '../store/api/endpoint-with-tags';

const useGoals = (dependentId: string) => {
  const { data: goalResponse, isLoading: goalsLoading } =
    useGoalControllerGetGoalsByDependantQuery(
      { dependentId },
      {
        skip: !dependentId,
      },
    );

  const { nextGoal, upcomingGoals, finishedGoals, starsAchieved } =
    useMemo(() => {
      if (!goalResponse)
        return {
          nextGoal: null,
          upcomingGoals: [],
          finishedGoals: [],
          starsAchieved: 0,
        };
      const { goals, totalStars, starsAwarded } = goalResponse;
      let total = totalStars - starsAwarded || 0;
      const upcoming = [];
      const finished = [];
      for (const goal of goals) {
        // goal has been awarded
        if (goal.awarded) {
          finished.push(goal);
        } else {
          // goal has not been awarded, figure out completed
          if (total > goal.starsRequired) {
            finished.push(goal);
            total = total - goal.starsRequired;
          } else {
            upcoming.push(goal);
          }
        }
      }
      const next = upcoming.shift();
      return {
        nextGoal: next || null,
        upcomingGoals: upcoming,
        finishedGoals: finished,
        starsAchieved: total,
      };
    }, [goalResponse]);

  return {
    nextGoal,
    goalsLoading: goalsLoading,
    starsAchieved: starsAchieved,
    goals: goalResponse?.goals,
    upcomingGoals,
    finishedGoals,
  };
};

export default useGoals;

import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { SelectButton } from 'primereact/selectbutton';
import { fadeIn } from '../../../../shared/helpers/animation';
import MonthOptions from './MonthOptions';
import WeekOptions from './WeekOptions';
import { RoutineForm } from '../create-routine-modal.types';

const RepeatRoutine: React.FC<{
  routineForm: RoutineForm;
  handleRoutineChange: (val: any, key: string) => void;
  disabled?: boolean;
}> = ({ routineForm, handleRoutineChange, disabled }) => {
  const { repeatInterval } = routineForm;

  const selectOptions = [
    { name: 'Day', value: 'day' },
    { name: 'Week', value: 'week' },
    { name: 'Month', value: 'month' },
  ];

  return (
    <>
      <AnimatePresence exitBeforeEnter initial={false}>
        <LayoutGroup>
          <motion.div
            variants={fadeIn}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="flex flex-col py-4"
            layout
          >
            <p className="mb-1">Repeat every</p>
            <SelectButton
              disabled={disabled}
              options={selectOptions}
              value={repeatInterval}
              onChange={(e) => {
                handleRoutineChange(e.value, 'repeatInterval');
              }}
              optionLabel="name"
              className="flex"
            />
          </motion.div>
          {repeatInterval === 'week' && (
            <WeekOptions
              key="week-options"
              disabled={disabled}
              routineForm={routineForm}
              handleRoutineChange={handleRoutineChange}
            />
          )}
          {repeatInterval === 'month' && (
            <MonthOptions
              key="month-options"
              disabled={disabled}
              routineForm={routineForm}
              handleRoutineChange={handleRoutineChange}
            />
          )}
        </LayoutGroup>
      </AnimatePresence>
    </>
  );
};

export default RepeatRoutine;

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonGrid,
} from '@ionic/react';
import { useParams } from 'react-router-dom';
import {
  CompletedRoutine,
  Routine,
  useDependentReportingControllerGetSingleRoutineReportQuery,
} from '../../../shared/store/api/endpoints';
import { ResponsiveTable } from '../../_components/ResponsiveTable';
import { MobileRoutineDetailTable } from './_components/mobile-routine-detail-table';
import { RoutineDetailTable } from './_components/routine-detail-table';
import { RoutineDetailHeader } from './_components/routine-detail-header/routine-detail-header';
import { useAppSelector } from '../../../shared/store/store';
import useProfile from '../../../shared/hooks/useProfile';
import { RoutineDetailProgressHeader } from './_components/routine-detail-progress-header';

export function RoutineDetailPage() {
  const { routineId } = useParams() as { routineId: string };
  const dateRange = useAppSelector((state) => state.dependentProgress);
  const { profile } = useProfile();
  const { data } = useDependentReportingControllerGetSingleRoutineReportQuery(
    {
      routineId: routineId as unknown as number,
      dependentId: profile.dependent?.id || '',
      caretakerId: profile.caretaker?.id || '',
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      completedStatus: [],
    },
    {
      skip: !routineId,
    },
  );

  const completedRoutines = data
    ? [
        ...(data?.routine.completedRoutines as CompletedRoutine[]),
        ...(data?.routine.modifiedRoutines
          ?.map(
            (modifiedRoutine) =>
              (modifiedRoutine as any).completedRoutines as CompletedRoutine[],
          )
          ?.flat() || []),
        ...(((data?.routine.originalRoutine as any)
          ?.completedRoutines as CompletedRoutine[]) || []),
      ]
    : [];
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/routines" />
          </IonButtons>
          <IonTitle>Routine Detail</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {data && (
          <ResponsiveTable
            desktopComponent={
              <>
                <div className="flex justify-center pt-8">
                  <div className="flex flex-col">
                    <RoutineDetailHeader routine={data.routine as Routine} />
                    <RoutineDetailProgressHeader
                      routines={data.assigned}
                      percentComplete={data.percentComplete}
                      numberCompleted={data.completed}
                      numberIncomplete={data.incomplete}
                    />
                    <RoutineDetailTable data={completedRoutines} />
                  </div>
                </div>
              </>
            }
            mobileComponent={
              <IonGrid fixed>
                <div className="flex flex-col gap-4 pt-8">
                  <RoutineDetailHeader routine={data.routine as Routine} />

                  <div className="flex flex-col">
                    <span className="text-center font-semibold uppercase">
                      SUMMARY
                    </span>
                    <RoutineDetailProgressHeader
                      isMobile
                      routines={data.assigned}
                      percentComplete={data.percentComplete}
                      numberCompleted={data.completed}
                      numberIncomplete={data.incomplete}
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-center font-semibold uppercase">
                      DETAILS
                    </span>

                    {data.routine.completedRoutines.length > 0 ? (
                      <MobileRoutineDetailTable data={completedRoutines} />
                    ) : (
                      <span className="pt-4 text-center font-semibold">
                        There are no attempts during this time-frame.
                      </span>
                    )}
                  </div>
                </div>
              </IonGrid>
            }
          />
        )}
      </IonContent>
    </IonPage>
  );
}

import { Steps } from 'primereact/steps';
import './RegisterHeader.css';
const RegisterHeader = ({
  steps,
  activeIndex,
  setActiveIndex,
}: {
  steps: any;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}) => {
  return (
    <div className="mt-8">
      <Steps
        model={steps}
        activeIndex={activeIndex}
        onSelect={(e: any) => setActiveIndex(e.index)}
        readOnly={false}
        className="w-full"
      />
      <header className="mt-6 text-center">
        <h2 className="mx-2 mt-4 mb-8 w-full text-3xl font-bold">
          {steps[activeIndex].header}
        </h2>
      </header>
    </div>
  );
};

export default RegisterHeader;

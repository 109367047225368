import { useAuth0 } from '@auth0/auth0-react';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useStorage from '../../../../shared/hooks/useStorage';
import { useProfileControllerCreateDependentProfileMutation } from '../../../../shared/store/api/endpoints';
import RegisterHeader from '../../components/RegisterHeader';
import DependentName from '../components/DependentName';
import DependentPhoto from '../components/DependentPhoto';
import { DependentFormType } from '../RegisterDependent';
import DependentFinish from './DependentFinish';

const CreateProfile = () => {
  const rootRef = useRef<any>(null);
  const [createProfile, { isLoading }] =
    useProfileControllerCreateDependentProfileMutation();
  const { user } = useAuth0();
  const history = useHistory();
  const { uploadFile } = useStorage();

  const steps = [
    { label: 'Welcome', header: 'Welcome to Routine Success!' },
    { label: 'Photo', header: `Let's add a picture to your profile` },
    {
      label: 'Finish',
      header: 'Wait for your caretaker to join the app',
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const [dependentFormState, setDependentFormState] =
    useState<DependentFormType>({
      firstName: '',
      lastName: '',
      smsNotifications: false,
    });

  const [photo, setPhoto] = useState<string | null>(null);
  const [fileUploading, setFileUploading] = useState(false);

  const handleRegister = async () => {
    const { firstName, lastName, smsNotifications } = dependentFormState;
    if (!user || !firstName || !lastName) return;
    else if (photo) {
      let path;
      setFileUploading(true);
      const date = new Date().getTime();
      path = await uploadFile(photo, `${date}.jpeg`, 'image/jpeg');
      setFileUploading(false);
      const body = {
        firstName,
        lastName,
        photo: path,
        phone: user.name as string,
        permissions: ['3'], // from seed data, 3 is dependent
        smsNotifications,
      };
      await createProfile({
        createProfile: body,
      }).unwrap();
    } else {
      const body = {
        firstName,
        lastName,
        phone: user.name as string,
        permissions: ['3'], // from seed data, 3 is dependent
        smsNotifications,
      };
      await createProfile({
        createProfile: body,
      }).unwrap();
    }
    history.push('/');
  };

  return (
    <>
      <RegisterHeader
        steps={steps}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      {activeIndex === 0 && (
        <DependentName
          handleNext={() => setActiveIndex(1)}
          setDependentFormState={setDependentFormState}
        />
      )}
      {activeIndex === 1 && (
        <DependentPhoto
          handleNext={() => setActiveIndex(2)}
          setDependentFormState={setDependentFormState}
          photo={photo}
          setPhoto={setPhoto}
          parentRef={rootRef}
          loading={fileUploading || isLoading}
        />
      )}
      {activeIndex === 2 && <DependentFinish handleNext={handleRegister} />}
    </>
  );
};

export default CreateProfile;

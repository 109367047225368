import { useState } from 'react';
import { CompletedTask } from '../../../shared/store/api/endpoints';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { SelectButton } from 'primereact/selectbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import PreviousFeedback from '../../caretaker/pages/CaretakerRoutinesPage/PreviousFeedback';
interface PassedTaskProps {
  task: CompletedTask;
  onComplete?: (index: number) => void;
  onFail?: (index: number) => void;
  onTaskFeedbackChange?: (task: CompletedTask, feedbackText: string) => void;
  index: number;
}

export default function PassedTask({
  task,
  onComplete,
  onFail,
  onTaskFeedbackChange,
  index,
}: PassedTaskProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectOptions = [
    { name: 'Not done', value: false },
    { name: 'Done', value: true },
  ];
  const handleCompleteChange = (value: boolean) => {
    if (value && onComplete) {
      onComplete(task.id);
      return;
    }
    if (!value && onFail) {
      onFail(task.id);
      return;
    }
  };
  return (
    <div className="my-4 flex flex-col bg-white p-4 shadow-sm">
      <div className="flex items-center justify-between py-2 pb-4">
        <div>
          {/* Added a parent div to avoid distortion of circle when task name is two lines */}
          <div className="flex h-8 w-8 min-w-max items-center justify-center rounded-full bg-primary-navy/50">
            <p className="text-sm font-bold text-white">{index + 1}</p>
          </div>
        </div>
        <p className="text-xl font-bold leading-5 text-primary-navy">
          {task.name}
        </p>
        {isOpen ? (
          <ChevronDownIcon
            className="h-8 w-8 text-primary-navy"
            onClick={() => setIsOpen(!isOpen)}
          />
        ) : (
          <ChevronUpIcon
            className="h-8 w-8 text-primary-navy"
            onClick={() => setIsOpen(!isOpen)}
          />
        )}
      </div>
      {isOpen && (
        <>
          {task.feedback && task.feedback.length > 0 && (
            <PreviousFeedback feedback={task.feedback.filter((f) => f.id)} />
          )}
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <label htmlFor="feedback-input" className="mb-1">
                Feedback
              </label>
              <InputTextarea
                id="feedback-input"
                onChange={(e) =>
                  onTaskFeedbackChange &&
                  onTaskFeedbackChange(task as CompletedTask, e.target.value)
                }
                rows={1}
              />
            </div>
            <div className="flex w-full justify-center px-4">
              <SelectButton
                options={selectOptions}
                onChange={(e) => handleCompleteChange(e.value)}
                optionLabel="name"
                className="flex w-full"
                value={task.status === 'passed' ? true : false}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

import { FC, useEffect, useState } from 'react';
import useStorage from '../../hooks/useStorage';
import { motion } from 'framer-motion';
import { fadeIn } from '../../helpers/animation';
interface ImageProps {
  src: string;
  alt: string;
  className?: string;
  skipGetUrl?: boolean;
}

const Image: FC<ImageProps> = ({ src, alt, className, skipGetUrl }) => {
  const { getReadUrl } = useStorage();
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    const getUrl = async (source: string) => {
      try {
        const path = await getReadUrl(source);
        setUrl(path);
      } catch (error) {
        console.error(error);
      }
    };
    if (skipGetUrl) {
      setUrl(src);
    } else {
      getUrl(src);
    }
  }, [src, getReadUrl, skipGetUrl]);

  return url ? (
    <motion.img
      src={url}
      alt={alt}
      className={className}
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      exit="hidden"
    />
  ) : (
    <></>
  );
};

export default Image;

import { Menu } from '@headlessui/react';
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

interface PaginationProps {
  pages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  itemsPerPage?: number;
  onItemsPerPageChange?: (itemsPerPage: number) => void;
  itemsPerPageOptions?: number[];
}
export function Pagination({
  pages,
  currentPage,
  onPageChange,
  itemsPerPage = 10,
  onItemsPerPageChange,
  itemsPerPageOptions = [10, 25, 50],
}: PaginationProps) {
  const buttons = Array.from(Array(pages).keys());
  return (
    <div className="flex gap-4">
      <nav aria-label="Page navigation">
        <div className="inline-flex items-center -space-x-px">
          <PaginationButton isActive={false} onClick={() => onPageChange(1)}>
            {' '}
            <span className="sr-only">Previous</span>
            <ChevronDoubleLeftIcon className="h-5 w-5" />
          </PaginationButton>

          <PaginationButton
            isActive={false}
            onClick={() =>
              currentPage > 1 ? onPageChange(currentPage - 1) : 1
            }
          >
            {' '}
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" />
          </PaginationButton>

          {buttons.map((pageButton) => (
            <PaginationButton
              key={`page-button-${pageButton}`}
              isActive={pageButton === currentPage - 1}
              onClick={() => onPageChange(pageButton + 1)}
            >
              {pageButton + 1}
            </PaginationButton>
          ))}

          <PaginationButton
            isActive={false}
            onClick={() =>
              currentPage < pages ? onPageChange(currentPage + 1) : currentPage
            }
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" />
          </PaginationButton>

          <PaginationButton
            isActive={false}
            onClick={() => onPageChange(pages)}
          >
            {' '}
            <span className="sr-only">Next</span>
            <ChevronDoubleRightIcon className="h-5 w-5" />
          </PaginationButton>
        </div>
      </nav>
      <PaginationQuantityMenu
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={onItemsPerPageChange}
        itemsPerPageOptions={itemsPerPageOptions}
      />
    </div>
  );
}

function PaginationButton({
  children,
  isActive,
  ...props
}: {
  children: React.ReactNode;
  isActive: boolean;
} & React.HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className={classNames(
        'border border-gray-300 bg-white px-3 py-2 leading-tight first:rounded-l-lg  last:rounded-r-lg hover:bg-gray-100 hover:text-gray-700',
        {
          'bg-primary-teal text-white hover:bg-primary-teal hover:text-white':
            isActive,
        },
      )}
      {...props}
    >
      {children}
    </button>
  );
}

function PaginationQuantityMenu({
  itemsPerPage,
  onItemsPerPageChange,
  itemsPerPageOptions,
}: {
  itemsPerPage: number;
  onItemsPerPageChange?: (itemsPerPage: number) => void;
  itemsPerPageOptions: number[];
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="rounded-lg border border-neutral-200 bg-white px-3 py-2  leading-tight hover:bg-gray-100 hover:text-gray-700">
        <div className="flex gap-2">
          {itemsPerPage}
          <ChevronDownIcon className="h-5 w-5" />
        </div>
      </Menu.Button>
      <Menu.Items className="absolute right-0 bottom-[50px] mt-2 origin-top-right divide-y divide-gray-100 rounded-md border-[1px] border-solid border-primary-teal border-opacity-20 bg-neutral-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {itemsPerPageOptions.map((option) => (
          <Menu.Item key={`menu-item-${option}`}>
            {({ active }) => (
              <button
                className={classNames(
                  'w-full px-4 py-2 text-left text-sm text-gray-700 hover:text-white',
                  {
                    'rounded bg-primary-teal text-white': active,
                  },
                )}
                onClick={() => onItemsPerPageChange?.(option)}
              >
                {option}
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
}

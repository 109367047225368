import { IonIcon } from '@ionic/react';
import { FC } from 'react';
import { CompletedRoutine } from '../../../../../shared/store/api/endpoints';
import ReviewCompletedRoutine from '../../../../_components/ReviewCompletedRoutine';
import './RoutinesReviewList.css';

interface RoutinesReviewListProps {
  completedRoutines: CompletedRoutine[];
  title: string;
  icon?: string;
  handleActionSheet?: (completedRoutine: CompletedRoutine) => void;
}

const RoutinesReviewList: FC<RoutinesReviewListProps> = ({
  completedRoutines,
  title,
  icon,
  handleActionSheet,
}: RoutinesReviewListProps) => {
  function renderRoutines() {
    if (completedRoutines?.length > 0) {
      return (
        <>
          <h2 className="title-container mt-2 p-6 text-2xl font-bold">
            {icon && <IonIcon icon={icon} />}
            <span>{title}</span>
          </h2>
          <div className="routine-list-container">
            {completedRoutines.map((completedRoutine) => (
              <ReviewCompletedRoutine
                key={completedRoutine.id}
                completedRoutine={completedRoutine}
                handleActionSheet={
                  handleActionSheet
                    ? () => handleActionSheet(completedRoutine)
                    : undefined
                }
              />
            ))}
          </div>
        </>
      );
    }
  }

  return <>{renderRoutines()}</>;
};

export default RoutinesReviewList;

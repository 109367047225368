import { useEffect, useState } from 'react';

export function useSort<T>({
  data,
  columns,
}: {
  data: T[];
  columns: (keyof T)[];
}) {
  const [sortedData, setSortedData] = useState(data || []);

  const [sort, setSort] = useState<{
    column: keyof T;
    direction: 'asc' | 'desc' | undefined;
  }>({ column: columns[0], direction: undefined });

  useEffect(() => {
    if (JSON.stringify(data) === JSON.stringify(sortedData)) {
      return;
    }
    setSortedData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function changeSort(column: keyof T) {
    let sortDirection: 'asc' | 'desc' | undefined = 'asc';
    if (sort.column === column && sort.direction === 'asc') {
      sortDirection = 'desc';
    }
    if (sort.column === column && sort.direction === 'desc') {
      sortDirection = undefined;
    }
    if (sortDirection === undefined) {
      setSortedData(data);
      setSort({ column, direction: sortDirection });
      return;
    }
    const sorted = [...data].sort((a, b) => {
      const aColumn =
        typeof a[column] === 'string'
          ? (a[column] as unknown as string).toLowerCase()
          : a[column];
      const bColumn =
        typeof b[column] === 'string'
          ? (b[column] as unknown as string).toLowerCase()
          : b[column];
      return aColumn < bColumn
        ? sortDirection === 'asc'
          ? -1
          : 1
        : aColumn > bColumn
        ? sortDirection === 'asc'
          ? 1
          : -1
        : 0;
    });
    setSortedData(sorted);
    setSort({ column, direction: sortDirection });
    return;
  }
  return {
    sortedData,
    sort,
    changeSort,
  };
}

import { motion } from 'framer-motion';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { FC } from 'react';
import { slideUpFromBottom } from '../../../../../shared/helpers/animation';
import { useCaretakerControllerUpdateMutation } from '../../../../../shared/store/api/endpoint-with-tags';
import { Caretaker } from '../../../../../shared/store/api/endpoints';

interface NotificationsTabProps {
  caretaker: Caretaker;
}

const NotificationsTab: FC<NotificationsTabProps> = ({ caretaker }) => {
  // hooks
  const [updateCaretaker] = useCaretakerControllerUpdateMutation();

  // methods
  const handleNotificationChange = (key: string, value: boolean) => {
    updateCaretaker({
      id: caretaker.id,
      caretaker: {
        ...caretaker,
        [key]: value,
      },
    });
  };

  return (
    <motion.div
      variants={slideUpFromBottom}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="px-6 pt-4"
    >
      <p className="font-semibold">Receive notifications when:</p>
      <div className="mt-4 flex items-center gap-x-2 rounded-t-md border border-gray-200 bg-white py-4 px-6 font-semibold">
        <Checkbox
          onChange={(e) =>
            handleNotificationChange('routineCompletedNotification', e.checked)
          }
          checked={caretaker.routineCompletedNotification}
          inputId="notifyStart"
        />
        <label htmlFor="notifyStart" className="p-checkbox-label">
          A Routine is completed
        </label>
      </div>
      <div className="flex items-center gap-x-2 rounded-b-md border border-t-0 border-gray-200 bg-white py-4 px-6 font-semibold">
        <Checkbox
          onChange={(e) =>
            handleNotificationChange('missedRoutineNotification', e.checked)
          }
          checked={caretaker.missedRoutineNotification}
          inputId="notifyRedone"
        />
        <label htmlFor="notifyRedone" className="p-checkbox-label">
          A routine has been missed
        </label>
      </div>
      <div className="mt-8">
        <p className="font-semibold">Select how you receive notifications</p>
        <div className="mt-4 flex items-center justify-between rounded-t-md border border-gray-200 bg-white py-4 px-6 font-semibold">
          <label htmlFor="notifyViaText" className="p-checkbox-label">
            Text message
          </label>
          <InputSwitch
            checked={caretaker.smsNotifications}
            onChange={(e) =>
              handleNotificationChange('smsNotifications', e.value)
            }
            id="notifyViaText"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default NotificationsTab;

import { motion } from 'framer-motion';
import { fadeIn } from '../../../../../shared/helpers/animation';
import Button from '../../../../../shared/components/Button';
import AddProfilePhoto from '../../../../../shared/components/AddProfilePhoto';
import { DependentFormType } from '../../RegisterDependent';

const DependentPhoto = ({
  handleNext,
  setDependentFormState,
  photo,
  setPhoto,
  parentRef,
  loading,
}: {
  handleNext: () => void;
  setDependentFormState: (dependentFormState: DependentFormType) => void;
  parentRef: any;
  photo: string | null | undefined;
  setPhoto: (photo: string | null) => void;
  loading?: boolean;
}) => {
  //Methods
  const handleNewImage = (image: string) => {
    setPhoto(image);
  };

  return (
    <motion.main
      className="mt-4 flex h-full flex-col justify-between"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <section className="flex h-full items-center justify-center px-12">
        <AddProfilePhoto
          photo={photo || null}
          addPhoto={handleNewImage}
          removePhoto={() => setPhoto(null)}
          alt="Profile photo"
          parentModalRef={parentRef}
        />
      </section>
      <section className="mt-8 flex gap-x-4">
        <Button
          className="grow"
          onClick={() => handleNext()}
          label="Continue"
          loading={loading}
          disabled={loading}
        />
      </section>
    </motion.main>
  );
};

export default DependentPhoto;

import { useAuth0 } from '@auth0/auth0-react';
import {
  IonModal,
  useIonActionSheet,
  useIonAlert,
  useIonToast,
} from '@ionic/react';
import { motion } from 'framer-motion';
import { ellipsisVertical } from 'ionicons/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageWrapper from '../../../../shared/components/PageWrapper';
import {
  fadeIn,
  slideUpFromBottom,
} from '../../../../shared/helpers/animation';
import useRoutines from '../../../../shared/hooks/useRoutines';
import useGoals from '../../../../shared/hooks/useGoals';
import useProfile from '../../../../shared/hooks/useProfile';
import { useProfileControllerChangeCaretakerStatusMutation } from '../../../../shared/store/api/endpoint-with-tags';
import RewardPreview from '../../../_components/RewardPreview';
import RoutineCalendar from '../../../_components/RoutineCalendar';
import ApproveCaretakerModal from './ApproveCaretakerModal';
import { Routine } from '../../../../shared/store/api/endpoints';
import DependentCreateRoutineModal from '../../components/DependentCreateRoutineModal';
import { EditOccurrenceModal } from '../../../_components/edit-occurrence-modal';

export default function DependentSchedulePage() {
  // Hooks
  const { profile } = useProfile();
  const history = useHistory();
  const {
    routines,
    rangeCompleted,
    setRangeStart,
    setRangeEnd,
    lastCompleted,
  } = useRoutines(profile?.dependent?.id as string, true);
  const { nextGoal, goalsLoading, starsAchieved } = useGoals(
    profile?.dependent?.id as string,
  );
  const [changeCaretakerStatus] =
    useProfileControllerChangeCaretakerStatusMutation();
  const [present] = useIonToast();
  const [presentAction] = useIonActionSheet();
  const { logout } = useAuth0();
  const [showPendingCaretaker, setShowPendingCaretaker] = useState(false);
  const [showAlert] = useIonAlert();

  // refs
  const rootRef = useRef<any>(null);
  const modalRef = useRef<HTMLIonModalElement>(null);
  const routineModalRef = useRef<HTMLIonModalElement>(null);
  const editOccurrenceModalRef = useRef<HTMLIonModalElement>(null);

  // edit routine
  const [showCreateRoutineModal, setShowCreateRoutineModal] = useState(false);
  const [selectedRoutineToEdit, setSelectedRoutineToEdit] = useState<Routine>();
  const [showEditOccurrenceModal, setShowEditOccurrenceModal] = useState(false);
  const [selectedOccurrenceToEdit, setSelectedOccurrenceToEdit] = useState<{
    routine: Routine;
    start: Date;
  }>();

  const { pendingCaretaker, pendingRelationshipId } = useMemo(() => {
    if (profile && profile.dependent) {
      const { caretakers } = profile.dependent;
      if (caretakers?.length && caretakers.length > 0) {
        const caretakerDependent = caretakers.find(
          (c) => c.inviteStatus === 'pending',
        );
        if (caretakerDependent) {
          return {
            pendingCaretaker: caretakerDependent.caretaker,
            pendingRelationshipId: caretakerDependent.relationshipTypeId,
          };
        }
      }
    }
    return {
      pendingCaretaker: undefined,
      pendingRelationshipId: undefined,
    };
  }, [profile]);

  useEffect(() => {
    if (pendingCaretaker) {
      setShowPendingCaretaker(true);
    } else {
      setShowPendingCaretaker(false);
    }
  }, [pendingCaretaker]);

  // methods
  const handleRangeChange = (start: Date, end: Date) => {
    // timeout needed to avoid fishy setState behavior
    setTimeout(() => {
      setRangeStart(start);
      setRangeEnd(end);
    });
  };

  const handleApproveCaretaker = async (isApproved: boolean) => {
    if (!pendingCaretaker) return;
    try {
      await changeCaretakerStatus({
        changeCaretakerStatusBody: {
          caretakerId: pendingCaretaker.id,
          dependentId: profile?.dependent?.id as string,
          status: isApproved ? 'accepted' : 'declined',
        },
      }).unwrap();
      present({
        message: 'Caretaker approved!',
        duration: 3000,
      });
      setShowPendingCaretaker(false);
    } catch (error) {
      present({
        message: 'Error approving caretaker',
        duration: 3000,
      });
    }
  };

  const handleActionSheet = () => {
    presentAction([
      {
        text: 'Log out',
        handler: () => {
          logout({ returnTo: window.location.origin });
        },
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  return (
    <PageWrapper
      title="Schedule"
      ref={rootRef}
      onAction={handleActionSheet}
      actionIcon={ellipsisVertical}
    >
      <motion.div
        layout
        variants={fadeIn}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <RoutineCalendar
          routines={routines}
          rangeChange={handleRangeChange}
          completed={rangeCompleted}
          editRoutine={(routine) => {
            // @ts-ignore - last completed is the wrong type
            if (
              !routine.repeatInterval &&
              // @ts-ignore - last completed is the wrong type
              lastCompleted?.find((r) => r.routine_id === routine.id)
            ) {
              showAlert({
                header: 'Cannot edit routine',
                message:
                  'This routine does not repeat and has been previously completed so it cannot be edited. Please create a new routine.',
                buttons: ['OK'],
              });
              return;
            }
            setSelectedRoutineToEdit(routine);
            setShowCreateRoutineModal(true);
          }}
          editOccurrence={({ routine, start }) => {
            setSelectedOccurrenceToEdit({ routine, start });
            setShowEditOccurrenceModal(true);
          }}
        />
      </motion.div>
      <div>
        {nextGoal && !goalsLoading ? (
          <>
            <div className="px-6">
              <RewardPreview goal={nextGoal} starsAchieved={starsAchieved} />
            </div>
          </>
        ) : (
          <motion.div
            className="mx-6 mt-12 mb-6 flex flex-col gap-y-4 rounded-lg bg-slate-200 p-6 text-gray-700"
            variants={slideUpFromBottom}
            initial="hidden"
            animate="visible"
          >
            <p className="text-2xl font-bold">Welcome {profile.firstName} 👋</p>
            <p>
              This is where your next reward will show up.{' '}
              <strong>
                Check back in after your mentor has added a goal.{' '}
              </strong>
              For now, head over to your{' '}
              <span
                onClick={() => history.push('profile')}
                className="text-primary-400 underline"
              >
                profile page
              </span>{' '}
              and make sure everything looks good.
            </p>
          </motion.div>
        )}
      </div>
      <IonModal
        ref={modalRef}
        isOpen={showPendingCaretaker}
        onDidDismiss={() => setShowPendingCaretaker(false)}
        presentingElement={rootRef.current || undefined}
      >
        {pendingCaretaker && (
          <ApproveCaretakerModal
            caretaker={pendingCaretaker}
            onDismiss={() => setShowPendingCaretaker(false)}
            relationshipId={pendingRelationshipId || 1}
            handleApprove={handleApproveCaretaker}
          />
        )}
      </IonModal>
      <IonModal
        ref={routineModalRef}
        isOpen={showCreateRoutineModal}
        canDismiss={true}
        onDidDismiss={() => {
          setSelectedRoutineToEdit(undefined);
          setShowCreateRoutineModal(false);
        }}
      >
        {showCreateRoutineModal && (
          <DependentCreateRoutineModal
            onDismiss={() => {
              setSelectedRoutineToEdit(undefined);
              setShowCreateRoutineModal(false);
            }}
            parentModalRef={rootRef}
            routine={selectedRoutineToEdit}
          />
        )}
      </IonModal>

      <IonModal
        ref={editOccurrenceModalRef}
        isOpen={showEditOccurrenceModal}
        canDismiss={true}
        onDidDismiss={() => {
          setShowEditOccurrenceModal(false);
          setSelectedRoutineToEdit(undefined);
        }}
      >
        {showEditOccurrenceModal && selectedOccurrenceToEdit && (
          <EditOccurrenceModal
            routine={selectedOccurrenceToEdit.routine}
            start={selectedOccurrenceToEdit.start}
            onDismiss={() => {
              setShowEditOccurrenceModal(false);
              setSelectedRoutineToEdit(undefined);
            }}
          />
        )}
      </IonModal>
    </PageWrapper>
  );
}

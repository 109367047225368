export function TableWrapper({
  header,
  body,
}: {
  header: React.ReactNode;
  body: React.ReactNode;
}) {
  return (
    <div className="m-4 max-w-[1200px] overflow-x-auto rounded-lg border-b-[3px] border-[#274693] shadow-lg">
      <table className="w-full">
        <thead className="whitespace-nowrap bg-primary-teal bg-opacity-30 text-left font-bold text-primary-teal">
          <tr>{header}</tr>
        </thead>
        <tbody>{body}</tbody>
      </table>
    </div>
  );
}

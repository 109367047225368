import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, RouteProps } from 'react-router-dom';
import { useProfileControllerGetByEmailQuery } from '../../store/api/endpoints';
import FullPageLoading from '../FullPageLoading';

const AdminGuard: React.FC<RouteProps> = ({ children }) => {
  const { user } = useAuth0();
  const { data: profile, isLoading: profileLoading } =
    useProfileControllerGetByEmailQuery(
      { email: user?.email || '' },
      {
        skip: !user,
      },
    );

  // trying it this way, if it re-renders too many times, might need to switch to useEffect
  if (!profileLoading) {
    if (
      profile &&
      (profile as any).permissions.find((p: any) => p.role === 'admin')
    ) {
      return <>{children}</>;
    } else {
      return <Redirect to="/" />;
    }
  } else {
    return <FullPageLoading />;
  }
};

export default AdminGuard;

import { useEffect, useState } from 'react';

export function usePagination<T>({ data }: { data: T[] }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [paginatedData, setPaginatedData] = useState<T[]>([]);

  function handlePageChange(page: number) {
    setCurrentPage(page);
  }

  function handleItemsPerPageChange(items: number) {
    setItemsPerPage(items);
    setCurrentPage(1);
  }

  useEffect(() => {
    const paginated = data.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );
    setPaginatedData(paginated);
  }, [data, currentPage, itemsPerPage]);

  return {
    currentPage,
    itemsPerPage,
    handlePageChange,
    handleItemsPerPageChange,
    paginatedData,
  };
}

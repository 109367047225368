import { motion } from 'framer-motion';
import Button from '../../../../../shared/components/Button';
import { fadeIn } from '../../../../../shared/helpers/animation';

const DependentFinish = ({ handleNext }: { handleNext: () => void }) => {
  return (
    <motion.main
      className="mt-4 flex h-full flex-col justify-between"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <section>
        <img
          src={'../assets/LinkUsersIcon.png'}
          alt="Link Users Icon"
          className="mx-auto w-2/4 opacity-50"
        />
        <div className="mt-2 flex flex-col gap-y-4">
          <p>
            Now that you have created your account, you need to wait for your
            mentor to register for Routine Success.
          </p>
          <p>
            In order for your mentor to be able to assign routines to you and
            give out rewards they will need to link their account to yours using
            your phone number. They will do this via their registration
            workflow.
          </p>
        </div>
      </section>
      <section className="mt-8 flex gap-x-4">
        <Button
          className="grow"
          onClick={() => handleNext()}
          label="Continue"
        />
      </section>
    </motion.main>
  );
};

export default DependentFinish;

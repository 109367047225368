import { useAuth0 } from '@auth0/auth0-react';

import {
  useProfileControllerGetByEmailQuery,
  useProfileControllerGetByPhoneQuery,
} from '../store/api/endpoint-with-tags';
import { Profile } from '../store/api/endpoints';

const useProfile = (skip = false) => {
  const { user } = useAuth0();
  const {
    data: caretakerProfile,
    isLoading: caretakerProfileLoading,
    isUninitialized: caretakerProfileUninitialized,
    isFetching: caretakerProfileFetching,
    refetch: caretakerProfileRefetch,
  } = useProfileControllerGetByEmailQuery(
    { email: user?.email as string },
    { skip: skip || !user || !user.email },
  );

  const {
    data: dependentProfile,
    isLoading: dependentProfileLoading,
    isUninitialized: dependentProfileUninitialized,
    isFetching: dependentProfileFetching,
    refetch: dependentProfileRefetch,
  } = useProfileControllerGetByPhoneQuery(
    // user.name is where the phone number lives in auth0
    { phone: user?.name as string },
    {
      skip:
        skip || !user ? true : !user.name ? true : user.email ? true : false,
    },
  );

  // building this to handle both cases.  This is a possible weak point.
  return {
    user,
    profile: (caretakerProfile || dependentProfile) as Profile,
    profileLoading: caretakerProfileLoading || dependentProfileLoading,
    profileUninitialized:
      caretakerProfileUninitialized === false
        ? false
        : dependentProfileUninitialized === false
        ? false
        : true,
    profileFetching: caretakerProfileFetching || dependentProfileFetching,
    refetch: caretakerProfileRefetch || dependentProfileRefetch,
  };
};

export default useProfile;

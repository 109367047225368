import { useEffect, useState } from 'react';

interface ResponsiveTableProps {
  mobileComponent: React.ReactNode;
  desktopComponent: React.ReactNode;
}
export function ResponsiveTable({
  mobileComponent,
  desktopComponent,
}: ResponsiveTableProps): JSX.Element {
  const responsiveWidthCutoff = 1040;
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < responsiveWidthCutoff,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < responsiveWidthCutoff);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile ? <>{mobileComponent}</> : <>{desktopComponent}</>;
}

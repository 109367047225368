import { Checkbox } from 'primereact/checkbox';

interface FrequencyFilterProps {
  selectedFrequency: string[];
  setSelectedFrequency: (selectedFrequency: string[]) => void;
}

export const frequencyOptions = [
  {
    name: 'One Time',
    value: 'single',
  },
  {
    name: 'Daily',
    value: 'day',
  },
  {
    name: 'Weekly',
    value: 'week',
  },
  {
    name: 'Monthly',
    value: 'month',
  },
];

export function FrequencyFilter({
  selectedFrequency,
  setSelectedFrequency,
}: FrequencyFilterProps) {
  function handleCheckboxChange(frequency: string) {
    if (selectedFrequency.includes(frequency)) {
      setSelectedFrequency(selectedFrequency.filter((f) => f !== frequency));
    } else {
      setSelectedFrequency([...selectedFrequency, frequency]);
    }
  }
  return (
    <div className="flex flex-col px-4">
      {frequencyOptions.map((option) => (
        <div
          key={option.value}
          className="flex items-center justify-between py-2"
        >
          <label htmlFor={`status-${option.value}`} className="mr-2">
            {option.name}
          </label>
          <Checkbox
            inputId={`status-${option.value}`}
            className="mr-2"
            value={selectedFrequency.includes(option.name)}
            checked={selectedFrequency.includes(option.value)}
            onChange={() => handleCheckboxChange(option.value)}
          />
        </div>
      ))}
    </div>
  );
}

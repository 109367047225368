import { useEffect } from 'react';
import FullPageLoading from '../../../shared/components/FullPageLoading';
import useAuth0Lock from '../../../shared/hooks/useAuth0Lock';

const Callback = () => {
  const { verifyLogin } = useAuth0Lock();

  // if we ended up here, we need to make sure we're logged in
  useEffect(() => {
    verifyLogin();
    // the popup doesn't trigger, then we need to refresh
    const timeout = setTimeout(() => {
      window.location.reload();
    }, 5000);
    return () => clearTimeout(timeout);
  }, [verifyLogin]);

  return <FullPageLoading />;
};

export default Callback;

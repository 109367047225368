import { useIonActionSheet } from '@ionic/react';
import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useDependent from '../../../../../shared/hooks/useDependent';
import useRoutines from '../../../../../shared/hooks/useRoutines';
import {
  CompletedRoutine,
  Dependent,
  Profile,
} from '../../../../../shared/store/api/endpoints';
import ReviewCompletedRoutine from '../../../../_components/ReviewCompletedRoutine';
import useProfile from '../../../../../shared/hooks/useProfile';

interface ReviewCompletedProps {
  rootRef: any;
}

const ReviewCompleted: FC<ReviewCompletedProps> = ({ rootRef }) => {
  const { id } = useParams() as { id: string };
  const history = useHistory();
  const dependent = useDependent(id) as Dependent;
  const { profile } = useProfile();
  const { attempted } = useRoutines(
    dependent.id,
    false,
    profile?.caretaker?.id,
  );

  const [present] = useIonActionSheet();

  const presentActionSheet = (routine: CompletedRoutine) => {
    present([
      {
        text: 'Review',
        handler: () => {
          history.push(`/review-completed/${routine.id}`);
        },
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  return (
    <div>
      <div>
        <div className="py-2 px-4 text-sm font-semibold text-gray-600">
          The following routines need review
        </div>
        {attempted && attempted.length > 0 ? (
          attempted?.map((routine: any) => (
            <div
              key={routine.id}
              className="w-full border-t border-solid border-gray-200 bg-white"
            >
              <ReviewCompletedRoutine
                completedRoutine={routine}
                handleActionSheet={() => presentActionSheet(routine)}
              />
            </div>
          ))
        ) : (
          <p className="w-full border-t border-solid border-gray-200 bg-white p-8 font-light text-gray-500">
            <strong className="font-bold">No routines to review.</strong> You
            will receive a text when {(dependent.profile as Profile).firstName}{' '}
            {(dependent.profile as Profile).lastName} completes a routine.
          </p>
        )}
      </div>
    </div>
  );
};

export default ReviewCompleted;

import {
  MbscEventcalendarView,
  Eventcalendar as EventCalendar,
} from '@mobiscroll/react';
import { motion } from 'framer-motion';
import { createRef, useCallback, useMemo } from 'react';
import { fadeIn } from '../../../shared/helpers/animation';
import {
  CompletedRoutine,
  Routine,
  useRoutineOccurrenceControllerCancelOccurrenceMutation,
} from '../../../shared/store/api/endpoints';
import { useHistory, useParams } from 'react-router';
import { format, isSameDay, setDay } from 'date-fns';
import classNames from 'classnames';
import {
  CheckCircleIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/solid';
import useDependent from '../../../shared/hooks/useDependent';
import { Menu } from 'primereact/menu';
import useProfile from '../../../shared/hooks/useProfile';
import { useIonAlert, useIonToast } from '@ionic/react';
import { useGoogleCalendar } from '../../../shared/hooks/use-google-calendar';
import { useRoutineControllerUpdateRoutineMutation } from '../../../shared/store/api/endpoint-with-tags';

//TODO: Change type to TransformedEvents
const RoutineCalendar = ({
  routines,
  completed,
  rangeChange,
  editRoutine,
  editOccurrence,
}: {
  routines: Routine[] | undefined;
  completed: CompletedRoutine[] | undefined;
  rangeChange: (start: Date, end: Date) => void;
  editRoutine: (routine: Routine) => void;
  editOccurrence: ({
    routine,
    start,
  }: {
    routine: Routine;
    start: Date;
  }) => void;
}) => {
  //Calendar view
  const history = useHistory();
  const { id } = useParams() as { id: string };
  const { profile } = useProfile();
  const [present] = useIonAlert();
  const [presentToast] = useIonToast();

  const { submitCalendarEvent } = useGoogleCalendar();
  const [updateRoutine] = useRoutineControllerUpdateRoutineMutation();

  const dependent = useDependent(id);
  const view = useMemo<MbscEventcalendarView>(() => {
    return {
      calendar: { type: 'week' },
      agenda: { type: 'day' },
    };
  }, []);

  const [cancelOccurrence] =
    useRoutineOccurrenceControllerCancelOccurrenceMutation();

  const generateRecurringEvent = useCallback((routine: Routine) => {
    let recurring;
    if (routine.repeats) {
      switch (routine.repeatInterval) {
        case 'day':
          recurring = {
            repeat: 'daily',
            interval: 1,
          };
          break;
        case 'week':
          recurring = {
            repeat: 'weekly',
            interval: 1,
            weekDays: routine.repeatOptions
              ? routine.repeatOptions
                  .split(',')
                  .map((day) =>
                    format(setDay(new Date(), parseInt(day)), 'eeeeee'),
                  )
                  .join(',')
              : '',
          };
          break;
        case 'month':
          recurring = {
            repeat: 'monthly',
            interval: 1,
            day: parseInt(routine.repeatOptions as string),
          };
          break;
        default:
          recurring = undefined;
      }
    }
    if (routine.endDate) {
      recurring = {
        ...recurring,
        until: new Date(routine.endDate),
      };
    }
    return recurring;
  }, []);

  function handleExceptions(routine: Routine) {
    const excluded = routine.excludedDates.map((excluded) =>
      format(new Date(excluded.date), 'yyyy-MM-dd'),
    );
    // exclude modified dates from the schedule, because we are putting an individual instance in
    const modified = routine.modifiedDates.map((modified) =>
      format(new Date(modified.originalDate), 'yyyy-MM-dd'),
    );
    return [...excluded, ...modified];
  }

  // using any here because the type is not exported from mobiscroll
  const events: any[] = useMemo(() => {
    if (routines) {
      return routines
        .map((routine: Routine) => {
          const completedRoutines: CompletedRoutine[] = [];
          if (completed) {
            for (const complete of completed) {
              if (complete.routineId === routine.id) {
                completedRoutines.push(complete);
              }
            }
          }
          const modifiedRoutine = routine.modifiedRoutines?.find(
            (r) => !r.endDate,
          );
          let exceptions = handleExceptions(routine);
          if (routine.modifiedRoutines) {
            for (const modified of routine.modifiedRoutines) {
              const fullModified = routines.find((m) => m.id === modified.id);
              if (fullModified) {
                exceptions = [...exceptions, ...handleExceptions(fullModified)];
              }
            }
          }
          return [
            {
              start: new Date(routine.startDate),
              // the modified routine is the source of truth for the name of the routine
              title: modifiedRoutine ? modifiedRoutine.name : routine.name,
              recurring: generateRecurringEvent(routine),
              recurringException: exceptions,
              routine,
              completedRoutines,
            },
            ...routine.modifiedDates.map((modified) => ({
              start: new Date(modified.date),
              title: routine.name,
              routine,
              completedRoutines,
            })),
          ];
        })
        .flat();
    }
    return [];
  }, [routines, completed, generateRecurringEvent]);

  //Templates
  const renderEventContent = useCallback(
    (data: any) => {
      const { routine, completedRoutines } = data.original;
      const eventRef = createRef<any>();
      let done = false;
      const found = completedRoutines.find((c: CompletedRoutine) =>
        isSameDay(new Date(c.createdOn), new Date(data.date)),
      );
      if (found && found.status === 'passed') done = true;
      let calendarActionItems = [
        {
          label: 'View Routine',
          command: () => {
            if (profile.caretaker) {
              history.push(`/tabs/${dependent?.id}/routines`);
            } else {
              history.push('/tabs/routines');
            }
          },
        },
      ];
      calendarActionItems = [
        ...calendarActionItems,
        {
          label: 'Sync with Google Calendar',
          command: async () => {
            try {
              console.log('routine?', routine);
              const googleCalendarEventId = await submitCalendarEvent(routine);
              // if profile is not a caretaker, then save the id of the synced event
              if (!profile.caretaker) {
                await updateRoutine({
                  id: routine.id,
                  updateRoutinePayload: {
                    googleCalendarEventId,
                  },
                }).unwrap();
              }
              presentToast(`${routine.name} synced with Google Calendar`, 2500);
            } catch (error) {
              presentToast('Error syncing with Google Calendar', 2500);
            }
          },
        },
      ];
      /**
       * if profile is a caretaker, or if there isn't a caretakerId (user is mentee and is owner) for the routine
       * that means that the user can edit the routine
       */
      if (profile.caretaker || !routine.caretakerId) {
        if (routine.repeatInterval && !done) {
          calendarActionItems = [
            ...calendarActionItems,
            {
              label: 'Edit this occurrence',
              command: () =>
                editOccurrence({
                  routine: data.original.routine,
                  start: new Date(data.original.start),
                }),
            },
            {
              label: 'Delete this occurrence',
              command: () => {
                present({
                  header: 'Delete Occurrence',
                  message: `Are you sure you want to delete this occurrence of ${routine.name}?  This is not reversible.`,
                  buttons: [
                    {
                      text: 'Cancel',
                      role: 'cancel',
                    },
                    {
                      text: 'Yes',
                      handler: async () => {
                        await cancelOccurrence({
                          routineId: data.original.routine.id,
                          routineOccurrencePayload: {
                            date: data.startDate,
                          },
                        }).unwrap();
                        presentToast('Occurrence deleted', 2500);
                      },
                    },
                  ],
                });
              },
            },
          ];
        }
      }

      /**
       * We have to parse done from the original data,
       * not the transformed data from Mobiscroll
       */
      return (
        <>
          <div
            className="my-2 flex w-full px-4"
            onClick={(event) => eventRef.current?.toggle(event)}
          >
            <div className="flex w-[75px] justify-end border-r-2 border-tertiary pr-2 pb-4 text-sm">
              {done ? (
                <CheckCircleIcon className="h-8 w-8 text-green-500" />
              ) : (
                <span>{format(new Date(data.startDate), 'p')}</span>
              )}
            </div>
            <div
              className={classNames('flex w-[75%] pl-2 text-sm font-semibold', {
                'line-through': done,
              })}
            >
              {data.title}
            </div>
            <EllipsisHorizontalIcon className="h-5 w-5" />
          </div>
          <Menu model={calendarActionItems} ref={eventRef} popup />
        </>
      );
    },
    [
      profile.caretaker,
      history,
      dependent?.id,
      submitCalendarEvent,
      updateRoutine,
      presentToast,
      editOccurrence,
      present,
      cancelOccurrence,
    ],
  );

  const handlePageLoading = (event: any, inst: any) => {
    rangeChange(event.firstDay, event.lastDay);
  };

  return (
    <motion.div
      layout
      className="shadow-md"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <EventCalendar
        theme="ios"
        themeVariant="light"
        data={events}
        view={view}
        renderEvent={renderEventContent}
        onPageLoading={handlePageLoading}
      />
    </motion.div>
  );
};

export default RoutineCalendar;

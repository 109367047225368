import './App.css';

import { IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { Suspense } from 'react';
import { Route, useHistory } from 'react-router-dom';
import Admin from './admin';
import GetStarted from './auth/pages/GetStarted';
import AdminGuard from './shared/components/AdminGuard';
import AuthGuard from './shared/components/AuthGuard';
import FullPageLoading from './shared/components/FullPageLoading';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import './theme/variables.css';

// Prime Styles
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';

import Callback from './auth/pages/Callback';
import MainApp from './main-app';
import RegisterCaretaker from './auth/pages/RegisterCaretaker';
import RegisterDependent from './auth/pages/RegisterDependent';

setupIonicReact();

const App: React.FC = () => {
  const history = useHistory();
  // redirecting prod to app.routine-success.com because sub domain forwarding on google domains isn't straightforward
  if (
    window.origin === 'https://routine-success-dev-app.cloudburststudio.com'
  ) {
    window.location.replace(
      'https://app.routine-success.com/' + history.location.pathname,
    );
  }
  return (
    <Suspense fallback={<FullPageLoading />}>
      <IonRouterOutlet>
        <AuthGuard>
          <Route exact path="/admin">
            <AdminGuard>
              <Admin />
            </AdminGuard>
          </Route>
          <Route exact path="/register/dependent">
            <RegisterDependent />
          </Route>
          <Route exact path="/register">
            <RegisterCaretaker />
          </Route>
          <MainApp />
          {/* <Route path="/" render={() => <Redirect to="/tabs" />} /> */}
        </AuthGuard>
        <Route path="/get-started">
          <GetStarted />
        </Route>
        <Route path="/callback">
          <Callback />
        </Route>
      </IonRouterOutlet>
    </Suspense>
  );
};

export default App;

import { Route } from 'react-router-dom';
import SidebarLayout from './components/SidebarLayout';
import About from './pages/About';
import { IonPage, IonRouterOutlet } from '@ionic/react';

const Admin: React.FC = () => {
  const links = [
    {
      to: 'about',
      label: 'About',
    },
  ];

  return (
    <IonPage>
      <SidebarLayout links={links}>
        <IonRouterOutlet>
          <Route path="about" component={About} />
        </IonRouterOutlet>
      </SidebarLayout>
    </IonPage>
  );
};

export default Admin;

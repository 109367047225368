import { useHistory } from 'react-router-dom';
import {
  Dependent,
  useDependentControllerGetDependentQuery,
} from '../store/api/endpoints';
import useProfile from './useProfile';

const useDependent = (id?: string) => {
  // hooks
  const { data: dependent, isLoading } =
    useDependentControllerGetDependentQuery(
      {
        id: id as string,
      },
      {
        skip: !id,
      },
    );
  const history = useHistory();
  const { profile } = useProfile();
  // find dependent, ensure that the dependent is in the caretaker's list of dependents
  const { caretaker } = profile;
  const careTakerDependent = caretaker?.dependents?.find(
    (d) => d.dependentId === id,
  );
  if (careTakerDependent && !isLoading) {
    return (dependent || careTakerDependent) as Dependent;
  } else if (id && !isLoading) {
    // if no caretaker/dependent redirect to home;
    history.push('/');
  }
};

export default useDependent;

import { ProgressHeaderItem } from '../../../../_components/ProgressHeaderItem';

interface RoutineDetailProgressHeaderProps {
  routines: number;
  percentComplete: number;
  numberCompleted: number;
  numberIncomplete: number;
  isMobile?: boolean;
}

export function RoutineDetailProgressHeader({
  routines,
  percentComplete,
  numberCompleted,
  numberIncomplete,
  isMobile = false,
}: RoutineDetailProgressHeaderProps) {
  return isMobile ? (
    <div className="m-4 rounded-lg border-b-[3px] border-[#274693] bg-white p-4 py-5 shadow-lg">
      <div className="grid grid-cols-2 grid-rows-2">
        <ProgressHeaderItem
          dataPoint={routines}
          label="Routine Opportunities"
          bottomBorder
        />
        <ProgressHeaderItem
          dataPoint={`${percentComplete}%`}
          label="Attempted"
          bottomBorder
        />
        <ProgressHeaderItem dataPoint={numberCompleted} label="# Attempted" />
        <ProgressHeaderItem
          dataPoint={numberIncomplete}
          label="# Missed"
          textColor="#B50831"
        />
      </div>
    </div>
  ) : (
    <div className="m-4 flex items-center justify-around rounded-lg border-b-[3px] border-[#274693] bg-white py-5 shadow-lg">
      <ProgressHeaderItem dataPoint={routines} label="Routine Opportunities" />
      <ProgressHeaderItem dataPoint={numberCompleted} label="# Attempted" />
      <ProgressHeaderItem
        dataPoint={numberIncomplete}
        label="# Missed"
        textColor="#B50831"
      />
      <ProgressHeaderItem dataPoint={`${percentComplete}%`} label="Attempted" />
    </div>
  );
}

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const SidebarListItem = ({ to, label }: { to: string; label: string }) => {
  const [active, setActive] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    if (path.includes(to)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location, to]);
  return (
    <li
      className={classNames('flex w-full p-4', {
        'bg-slate-400 text-black': active,
      })}
    >
      <NavLink
        to={to}
        className={(isActive) => {
          return isActive
            ? ' w-full font-bold text-black'
            : 'w-full font-bold text-white';
        }}
      >
        {label}
      </NavLink>
    </li>
  );
};

export default SidebarListItem;

interface TDProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  children?: React.ReactNode;
}

export function TD({ children, ...props }: TDProps) {
  const { className, ...rest } = props;
  return (
    <td className={`p-4 ${props?.className || ''}`} {...rest}>
      {children}
    </td>
  );
}

import { motion } from 'framer-motion';
import FormInput from '../../../../shared/components/FormInput';
import { fadeIn } from '../../../../shared/helpers/animation';
import Button from '../../../../shared/components/Button';
import { useForm } from 'react-hook-form';
import FormInputWithMask from '../../../../shared/components/FormInputWithMask';
import { CaretakerFormType } from '../RegisterCaretaker';
import { useIonToast } from '@ionic/react';
import AddProfilePhoto from '../../../../shared/components/AddProfilePhoto';
import useFormValidators from '../../../../shared/hooks/useFormValidators';
import { FormCheckbox } from '../../../../shared/components/form-checkbox/form-checkbox';

const RegisterCaretakerStep1 = ({
  setActiveIndex,
  caretakerFormState,
  setCaretakerFormState,
  registerFormRef,
  photo,
  setPhoto,
}: {
  setActiveIndex: (index: number) => void;
  caretakerFormState: CaretakerFormType;
  setCaretakerFormState: (caretakerFormState: CaretakerFormType) => void;
  registerFormRef: any;
  photo: string | null;
  setPhoto: (photo: string | null) => void;
}) => {
  const { validatePhone } = useFormValidators();
  const [present] = useIonToast();
  //Form state
  const {
    control,
    getValues,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: caretakerFormState.firstName,
      lastName: caretakerFormState.lastName,
      phone: caretakerFormState.phone,
      smsNotifications: false,
    },
  });

  //Methods
  const handleNext = async () => {
    const phone = getValues().phone;
    // check to see if phone is available. not able to do this in the form because it means we have to click continue twice.
    const available = await validatePhone(phone);
    if (available !== true) {
      const errorMessage =
        available === false ? 'Phone number invalid' : (available as string);
      present(errorMessage, 2000);
      setError('phone', { type: 'custom', message: errorMessage });
      return;
    }
    setCaretakerFormState({
      firstName: getValues().firstName,
      lastName: getValues().lastName,
      phone: getValues().phone,
      smsNotifications: getValues().smsNotifications,
    });
    setActiveIndex(1);
  };

  const handleNewImage = (image: string) => {
    setPhoto(image);
  };

  return (
    <motion.main
      className="mt-8 flex h-full flex-col justify-between"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <section>
        <AddProfilePhoto
          photo={photo || null}
          addPhoto={handleNewImage}
          removePhoto={() => setPhoto(null)}
          alt="Profile photo"
          parentModalRef={registerFormRef}
          width="200px"
        />
      </section>
      <form onSubmit={handleSubmit(() => handleNext())}>
        <FormInput
          label="First name"
          name="firstName"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'First name is required',
            },
          }}
          errors={errors}
        />
        <FormInput
          label="Last name"
          name="lastName"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Last name is required',
            },
          }}
          errors={errors}
        />
        <FormInputWithMask
          label="Phone"
          name="phone"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Phone is required',
            },
          }}
          errors={errors}
          mask="(999) 999-9999"
        />
        <FormCheckbox
          label="I consent to receive SMS notifications from Routine Success"
          name="smsNotifications"
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: 'SMS notification consent is required',
            },
          }}
        />
        <div className="mt-8 flex gap-x-4">
          <Button
            className="grow"
            onClick={handleSubmit(() => handleNext())}
            disabled={!isValid && !isDirty}
            label="Continue"
          />
        </div>
      </form>
    </motion.main>
  );
};

export default RegisterCaretakerStep1;

import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import PageWrapper from '../../../../shared/components/PageWrapper';
import useProfile from '../../../../shared/hooks/useProfile';
import {
  CaretakerDependent,
  Profile,
} from '../../../../shared/store/api/endpoints';
import ProfileCard from '../../../_components/ProfileCard';
import { ellipsisVertical } from 'ionicons/icons';
import { IonModal, useIonActionSheet } from '@ionic/react';
import { useAuth0 } from '@auth0/auth0-react';
import AddDependent from '../../components/AddDependent';
import { getDependentTitle } from '../../../../shared/helpers/relationship-title';
import { CaretakerCreateRoutineModal } from '../../components/CaretakerCreateRoutineModal';

export default function CaretakerSelectDependentPage() {
  // hooks
  const [present] = useIonActionSheet();
  const { profile } = useProfile();
  const { caretaker } = profile;
  const history = useHistory();
  const { logout } = useAuth0();

  // state
  const [showAddDependent, setShowAddDependent] = useState(false);
  const [showAssignRoutine, setShowAssignRoutine] = useState(false);
  const [acceptedDependents, setAcceptedDependents] = useState<
    CaretakerDependent[]
  >([]);
  const [pendingDependents, setPendingDependents] = useState<
    CaretakerDependent[]
  >([]);

  //Ref
  const rootRef = useRef<any>(null);
  const addDependentRef = useRef<HTMLIonModalElement>(null);
  const assignRoutineRef = useRef<HTMLIonModalElement>(null);
  // effects
  useEffect(() => {
    if (caretaker?.dependents) {
      sortCaretakerDependents(caretaker?.dependents);
    }
  }, [caretaker]);

  // methods
  const sortCaretakerDependents = (
    caretakerDependents: CaretakerDependent[],
  ) => {
    const accepted = [];
    const pending = [];
    for (let index = 0; index < caretakerDependents.length; index++) {
      const caretakerDependent = caretakerDependents[index];
      if (caretakerDependent.inviteStatus === 'accepted') {
        accepted.push(caretakerDependent);
      } else {
        pending.push(caretakerDependent);
      }
    }
    setAcceptedDependents(accepted);
    setPendingDependents(pending);
  };

  const handleActionSheet = () => {
    present([
      {
        text: 'Add Mentee',
        handler: () => {
          setShowAddDependent(true);
        },
      },
      {
        text: 'Assign Routine',
        handler: () => {
          setShowAssignRoutine(true);
        },
      },
      {
        text: 'Log out',
        handler: () => {
          handleSignOut();
        },
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  const handleSignOut = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <PageWrapper
      title="Mentees"
      onAction={handleActionSheet}
      actionIcon={ellipsisVertical}
      ref={rootRef}
    >
      <div className="mx-6 mt-8 flex flex-col gap-8">
        <div>
          {acceptedDependents.length > 0 && (
            <span className="text-xl font-bold">
              Select a mentee below to get started
            </span>
          )}

          <div className="flex flex-col gap-4">
            {acceptedDependents.map(({ dependent, relationshipTypeId }) => (
              <div
                key={dependent.id}
                onClick={() => history.push(`/tabs/${dependent.id}/schedule`)}
              >
                <ProfileCard
                  profile={dependent.profile as Profile}
                  relationship={getDependentTitle(relationshipTypeId, true)}
                />
              </div>
            ))}
          </div>
        </div>
        {pendingDependents.length > 0 && (
          <div>
            <span className="text-lg font-semibold text-gray-700">
              Pending invites
            </span>
            {pendingDependents.map(({ dependent, relationshipTypeId }) => (
              <ProfileCard
                key={dependent.id}
                profile={dependent.profile as Profile}
                relationship={getDependentTitle(relationshipTypeId, true)}
              />
            ))}
          </div>
        )}
        <p className="pt-4">
          If your mentee does not receive an invite to the app via SMS text
          message, they can log on automatically at {document.location.host}.
        </p>
      </div>
      <IonModal
        ref={addDependentRef}
        isOpen={showAddDependent}
        canDismiss={true}
        presentingElement={rootRef.current}
        onDidDismiss={() => setShowAddDependent(false)}
      >
        <AddDependent onDismiss={() => setShowAddDependent(false)} />
      </IonModal>
      <IonModal
        ref={assignRoutineRef}
        isOpen={showAssignRoutine}
        canDismiss={true}
        presentingElement={rootRef.current}
        onDidDismiss={() => setShowAssignRoutine(false)}
      >
        <CaretakerCreateRoutineModal
          parentModalRef={assignRoutineRef}
          onDismiss={() => setShowAssignRoutine(false)}
          multipleDependents={acceptedDependents.map(
            ({ dependent }) => dependent,
          )}
        />
      </IonModal>
    </PageWrapper>
  );
}

import { IonIcon } from '@ionic/react';
import { FC } from 'react';
import { Routine } from '../../../../../shared/store/api/endpoints';
import RoutineDetailItem from '../RoutineDetailItem';
import './RoutineActiveList.css';

interface RoutinesActiveListProps {
  routines: Routine[];
  title: string;
  icon?: string;
  startRoutine?: (routine: Routine) => void;
}

const RoutinesActiveList: FC<RoutinesActiveListProps> = ({
  routines,
  title,
  icon,
  startRoutine,
}) => {
  return routines && routines.length > 0 ? (
    <>
      <h2 className="title-container mt-2 p-6 text-2xl font-bold">
        {icon && <IonIcon icon={icon} />}
        <span>{title}</span>
      </h2>

      <div className="routine-list-container">
        {routines.map((routine) => (
          <RoutineDetailItem
            key={routine.id}
            routine={routine}
            startRoutine={() => {
              startRoutine && startRoutine(routine);
            }}
          />
        ))}
      </div>
    </>
  ) : (
    <></>
  );
};

export default RoutinesActiveList;

import axios from 'axios';

const useStorage = () => {
  const getReadUrl = async (fileName: string) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/storage/read-url/${fileName}`,
      );
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const uploadFile = async (
    file: string,
    fileName: string,
    fileType: string,
  ) => {
    // get signed url to upload file
    const { data: url } = await axios.get(
      `${process.env.REACT_APP_API_URL}/storage/write-url/`,
      {
        params: {
          fileName,
          fileType,
        },
      },
    );
    // convert file url to blob
    const res = await fetch(file);
    const blob = await res.blob();
    const newFile = new File([blob], fileName, { type: fileType });
    await fetch(url, {
      method: 'PUT',
      body: newFile,
    });
    return fileName;
  };

  return {
    uploadFile,
    getReadUrl,
  };
};

export default useStorage;

import { useAuth0 } from '@auth0/auth0-react';
import { useIonActionSheet } from '@ionic/react';
import { motion } from 'framer-motion';
import { ellipsisVertical } from 'ionicons/icons';
import PageWrapper from '../../../../shared/components/PageWrapper';
import { slideUpFromBottom } from '../../../../shared/helpers/animation';
import useGoals from '../../../../shared/hooks/useGoals';
import useProfile from '../../../../shared/hooks/useProfile';
import CompletedReward from '../../../_components/CompletedReward';
import RewardPreview from '../../../_components/RewardPreview';

export default function DependentRewardsPage() {
  const { profile } = useProfile();
  const { nextGoal, starsAchieved, finishedGoals, upcomingGoals } = useGoals(
    profile?.dependent?.id as string,
  );
  const { logout } = useAuth0();
  const [presentAction] = useIonActionSheet();

  const handleActionSheet = () => {
    presentAction([
      {
        text: 'Log out',
        handler: () => {
          logout({ returnTo: window.location.origin });
        },
        role: 'destructive',
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ]);
  };

  return (
    <PageWrapper
      title="Rewards"
      onAction={handleActionSheet}
      actionIcon={ellipsisVertical}
    >
      <main>
        <div className="px-4 pb-4 pt-4">
          {nextGoal ? (
            <RewardPreview goal={nextGoal} starsAchieved={starsAchieved} />
          ) : (
            <motion.div
              className="mb-6 flex flex-col gap-y-4 rounded-lg bg-slate-200 p-6 text-gray-700"
              variants={slideUpFromBottom}
              initial="hidden"
              animate="visible"
            >
              <p className="text-2xl font-bold">Hey {profile.firstName} 👋</p>
              <p>
                This is where your <strong>rewards</strong> 🎁 will show up.
                Check back in once your mentor has added a reward.
              </p>
            </motion.div>
          )}
        </div>
        {upcomingGoals.length ? (
          <div className="p-4">
            <div className="pt-4 pb-2">
              <span>Upcoming Rewards</span>
            </div>
            <div className="flex flex-col gap-1">
              {upcomingGoals.map((goal) => (
                <CompletedReward
                  goal={goal}
                  key={goal.id}
                  notStarted={true}
                ></CompletedReward>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
        {finishedGoals.length ? (
          <div className="p-4">
            <div className="pt-4 pb-2">
              <span>Reward History</span>
            </div>
            <div className="flex flex-col gap-1">
              {finishedGoals.map((goal) => {
                return <CompletedReward goal={goal} key={goal.id} />;
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
      </main>
    </PageWrapper>
  );
}

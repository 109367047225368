import { ErrorMessage } from '@hookform/error-message';
import { Checkbox } from 'primereact/checkbox';
import { Controller } from 'react-hook-form';

export function FormCheckbox({
  control,
  errors,
  name,
  label,
  rules,
  type = 'text',
  inputRef,
  className,
}: {
  control: any;
  errors: any;
  name: string;
  label: string;
  rules?: any;
  type?: any;
  inputRef?: any;
  className?: string;
}) {
  return (
    <div>
      <div className="flex w-full items-center">
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => {
            return (
              <Checkbox
                id={name + '-input'}
                className={className}
                {...field}
                ref={inputRef}
                type={type}
                checked={field.value}
              />
            );
          }}
        />
        <label htmlFor={name + '-input'} className="ml-2">
          {label}
        </label>
      </div>

      <div className="m-2 flex h-4 w-full items-baseline justify-start pr-4">
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <small className="p-error p-d-block">{message}</small>
          )}
        />
      </div>
    </div>
  );
}

import { endpoints as api, Routine } from './endpoints';

function providesList<R extends { id: string | number }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T,
) {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...resultsWithIds.map(({ id }) => ({
          type: tagType,
          id: id.toString(),
        })),
      ]
    : [{ type: tagType, id: 'LIST' }];
}

export const enhancedApi = api.enhanceEndpoints({
  addTagTypes: [
    'Profile',
    'Routines',
    'Goals',
    'CompletedRoutines',
    'AttemptedRoutines',
    'FailedRoutines',
    'LastCompletedRoutines',
  ],
  endpoints: {
    // might be an issue having get by email and get by phone for this tag.  might need to replace with getUserProfile
    profileControllerGetByEmail: {
      providesTags: ['Profile'],
    },
    profileControllerGetByPhone: {
      providesTags: ['Profile'],
    },
    profileControllerCreateProfile: {
      invalidatesTags: ['Profile'],
    },
    profileControllerCreateDependentProfile: {
      invalidatesTags: ['Profile'],
    },
    profileControllerUpdateProfile: {
      invalidatesTags: ['Profile'],
    },
    profileControllerCreateCaretakerProfile: {
      invalidatesTags: ['Profile'],
    },
    profileControllerChangeCaretakerStatus: {
      invalidatesTags: ['Profile'],
    },
    profileControllerAddDependentToCaretaker: {
      invalidatesTags: ['Profile'],
    },
    dependentControllerUpdate: {
      invalidatesTags: ['Profile'],
    },
    caretakerControllerUpdate: {
      invalidatesTags: ['Profile'],
    },
    routineControllerCreateRoutine: {
      invalidatesTags: ['Routines'],
    },
    routineControllerUpdateRoutine: {
      invalidatesTags: (result, error, arg) => [
        { type: 'Routines', id: arg.id },
      ],
    },
    routineOccurrenceControllerModifyDate: {
      invalidatesTags: ['Routines'],
    },
    routineOccurrenceControllerCancelOccurrence: {
      invalidatesTags: ['Routines'],
    },
    routineControllerGetAllRoutinesForDependent: {
      providesTags: (result) => providesList(result as Routine[], 'Routines'),
    },
    routineControllerDeleteRoutine: {
      invalidatesTags: (result, error, arg) => [
        { type: 'Routines', id: arg.id },
      ],
    },
    completeRoutineControllerGetDateCompleted: {
      providesTags: (result) =>
        providesList(result as any, 'CompletedRoutines'),
    },
    completeRoutineControllerGetLastCompleted: {
      providesTags: (result) =>
        providesList(result as any, 'LastCompletedRoutines'),
    },
    completeRoutineControllerGetAttempted: {
      providesTags: (result) =>
        providesList(result as any, 'AttemptedRoutines'),
    },
    completeRoutineControllerCreateCompleteRoutine: {
      invalidatesTags: [
        'CompletedRoutines',
        'AttemptedRoutines',
        'FailedRoutines',
        'Routines',
        'LastCompletedRoutines',
      ],
    },
    completeRoutineControllerGetRangeCompleted: {
      providesTags: (result) =>
        providesList(result as any, 'CompletedRoutines'),
    },

    completeRoutineControllerUpdateCompleteRoutine: {
      invalidatesTags: (result, error, arg) => [
        { type: 'Routines', id: result?.routineId },
        { type: 'CompletedRoutines', id: arg.id },
        'FailedRoutines',
        'AttemptedRoutines',
        'LastCompletedRoutines',
        'Goals',
      ],
    },
    completeRoutineControllerGetFailed: {
      providesTags: (result) => providesList(result as any, 'FailedRoutines'),
    },
    goalControllerCreateGoal: {
      invalidatesTags: ['Goals'],
    },
    goalControllerUpdateGoal: {
      invalidatesTags: (result, error, arg) => [{ type: 'Goals', id: arg.id }],
    },
    goalControllerGetGoalsByDependant: {
      providesTags: (result) =>
        result?.goals ? providesList(result.goals, 'Goals') : [],
    },
    goalControllerAwardGoal: {
      invalidatesTags: (result, error, arg) => [{ type: 'Goals', id: arg.id }],
    },
  },
});

export const {
  useProfileControllerGetByEmailQuery,
  useProfileControllerCreateProfileMutation,
  useProfileControllerCreateDependentProfileMutation,
  useProfileControllerUpdateProfileMutation,
  useProfileControllerGetByPhoneQuery,
  useProfileControllerCreateCaretakerProfileMutation,
  useProfileControllerAddDependentToCaretakerMutation,
  useDependentControllerUpdateMutation,
  useCaretakerControllerUpdateMutation,
  useRoutineControllerCreateRoutineMutation,
  useRoutineControllerUpdateRoutineMutation,
  useRoutineControllerDeleteRoutineMutation,
  useRoutineControllerGetAllRoutinesForDependentQuery,
  useCompleteRoutineControllerGetDateCompletedQuery,
  useCompleteRoutineControllerCreateCompleteRoutineMutation,
  useCompleteRoutineControllerUpdateCompleteRoutineMutation,
  useCompleteRoutineControllerGetFailedQuery,
  useGoalControllerCreateGoalMutation,
  useGoalControllerGetGoalsByDependantQuery,
  useCompleteRoutineControllerGetRangeCompletedQuery,
  useCompleteRoutineControllerGetAttemptedQuery,
  useCompleteRoutineControllerGetCompletedQuery,
  useGoalControllerUpdateGoalMutation,
  useProfileControllerChangeCaretakerStatusMutation,
  useCompleteRoutineControllerGetLastCompletedQuery,
  useRoutineOccurrenceControllerModifyDateMutation,
  useRoutineOccurrenceControllerCancelOccurrenceMutation,
} = enhancedApi;

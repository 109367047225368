import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { DateRange } from '../../progress-sort-and-filter-header';
import { useRef } from 'react';
import classNames from 'classnames';
import { startOfWeek, startOfMonth, startOfYear, format } from 'date-fns';

interface ProgressTimeFrameButtonProps {
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
}

export function ProgressTimeFrameButton({
  dateRange,
  setDateRange,
}: ProgressTimeFrameButtonProps) {
  const timeFrameMenu = useRef<any>(null);

  const timeFrameOptions = [
    {
      label: 'Week',
      command: () => {
        setDateRange({
          timeFrame: 'Week',
          startDate: format(startOfWeek(new Date()), 'yyyy-MM-dd'),
          endDate: format(new Date(), 'yyyy-MM-dd'),
        });
      },
      className: classNames({
        'font-bold bg-primary-teal bg-opacity-20':
          dateRange.timeFrame === 'Week',
      }),
    },
    {
      label: 'Month',
      command: () => {
        setDateRange({
          timeFrame: 'Month',
          startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
          endDate: format(new Date(), 'yyyy-MM-dd'),
        });
      },
      className: classNames({
        'font-bold bg-primary-teal bg-opacity-20':
          dateRange.timeFrame === 'Month',
      }),
    },
    {
      label: 'Year',
      command: () => {
        setDateRange({
          timeFrame: 'Year',
          startDate: format(startOfYear(new Date()), 'yyyy-MM-dd'),
          endDate: format(new Date(), 'yyyy-MM-dd'),
        });
      },
      className: classNames({
        'font-bold bg-primary-teal bg-opacity-20':
          dateRange.timeFrame === 'Year',
      }),
    },
  ];

  return (
    <>
      <Menu model={timeFrameOptions} popup ref={timeFrameMenu} />
      <Button
        label="Date"
        icon="pi pi-clock"
        onClick={(event) => timeFrameMenu.current?.toggle(event)}
      />
    </>
  );
}

import { Button } from 'primereact/button';
import { useRef, useState } from 'react';
import { Routine } from '../../../../../shared/store/api/endpoints';
import { OverlayPanel } from 'primereact/overlaypanel';
import { FilterOption } from './_components/filter-option';
import { DateRangeFilter } from './_components/date-range-filter';
import { RoutineFilter } from './_components/routine-filter';
import { DateRange } from '../../progress-sort-and-filter-header';
import { statusOptions } from './_components/status-filter/status-filter';
import {
  FrequencyFilter,
  frequencyOptions,
} from './_components/frequency-filter/frequency-filter';
import { parseDate } from '../../../../../shared/utility/dates';
import { format } from 'date-fns';

interface ProgressFilterButtonProps {
  routines: Routine[];
  selectedRoutines: string[];
  setSelectedRoutines: (selectedRoutines: string[]) => void;
  dateRange: { startDate: string; endDate: string };
  setDateRange: (dateRange: DateRange) => void;
  selectedStatus: string[];
  setSelectedStatus: (selectedStatus: string[]) => void;
  selectedFrequency: string[];
  setSelectedFrequency: (selectedFrequency: string[]) => void;
}

export function ProgressFilterButton({
  routines,
  selectedRoutines,
  setSelectedRoutines,
  dateRange,
  setDateRange,
  selectedStatus,
  setSelectedStatus,
  selectedFrequency,
  setSelectedFrequency,
}: ProgressFilterButtonProps) {
  const overlayRef = useRef<any>(null);
  const [selectedFilter, setSelectedFilter] = useState<string>();

  const [status, setStatus] = useState<string[]>([]);

  const filterOptions = [
    {
      name: 'Date Range',
      value: 'dateRange',
    },
    { name: 'Routines', value: 'routines' },
    // { name: 'Status', value: 'status' },
    { name: 'Frequency', value: 'frequency' },
  ];

  function renderBack() {
    return (
      <div className="w-[100px]">
        <Button
          className="p-button-text "
          label="Back"
          icon="pi pi-angle-left"
          onClick={() => setSelectedFilter(undefined)}
        />
      </div>
    );
  }

  function handleDateRangeChange({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) {
    setDateRange({ startDate, endDate, timeFrame: undefined });
  }

  function handleStatusChange(status: string[]) {
    setSelectedStatus(status);
  }

  function renderSelectedText(option: string) {
    switch (option) {
      case 'routines':
        return selectedRoutines.length > 0
          ? selectedRoutines
              .map(
                (routineId) =>
                  routines.find((routine) => routine.id === parseInt(routineId))
                    ?.name || '',
              )
              .join(', ')
          : 'All routines';
      case 'dateRange':
        return dateRange
          ? `${format(parseDate(dateRange.startDate), 'P')} - ${format(
              parseDate(dateRange.endDate),
              'P',
            )}`
          : 'All time';
      case 'status':
        return selectedStatus.length > 0
          ? selectedStatus
              .map(
                (status) => statusOptions.find((s) => s.value === status)?.name,
              )
              .join(', ')
          : 'All';
      case 'frequency':
        return selectedFrequency.length > 0
          ? selectedFrequency
              .map(
                (frequency) =>
                  frequencyOptions.find((f) => f.value === frequency)?.name,
              )
              .join(', ')
          : 'All';
      default:
        return '';
    }
  }
  return (
    <>
      <Button
        label="Filter"
        icon="pi pi-filter"
        onClick={(e) => overlayRef.current?.toggle(e)}
      />
      <OverlayPanel
        ref={overlayRef}
        className="ml-8 w-[80%] max-w-[400px] !rounded-none lg:ml-0"
      >
        <div className="flex w-full flex-col pb-2">
          {!selectedFilter && (
            <>
              {filterOptions.map((option) => (
                <FilterOption
                  key={option.value}
                  name={option.name}
                  value={option.value}
                  selectedText={renderSelectedText(option.value)}
                  setSelectedFilter={setSelectedFilter}
                />
              ))}
            </>
          )}

          {selectedFilter === 'routines' && (
            <>
              {renderBack()}
              <RoutineFilter
                routines={routines}
                selectedRoutines={selectedRoutines}
                setSelectedRoutines={setSelectedRoutines}
              />
            </>
          )}
          {selectedFilter === 'dateRange' && (
            <>
              {renderBack()}
              <DateRangeFilter
                setDateRange={handleDateRangeChange}
                dateRange={dateRange}
              />
            </>
          )}
          {/* Removing status for now because it doesn't make sense to have it on this filter */}
          {/* {selectedFilter === 'status' && (
            <>
              {renderBack()}
              <StatusFilter
                selectedStatus={selectedStatus}
                setSelectedStatus={handleStatusChange}
              />
            </>
          )} */}
          {selectedFilter === 'frequency' && (
            <>
              {renderBack()}
              <FrequencyFilter
                selectedFrequency={selectedFrequency}
                setSelectedFrequency={setSelectedFrequency}
              />
            </>
          )}
        </div>
      </OverlayPanel>
    </>
  );
}

import { Checkbox } from 'primereact/checkbox';
import { Routine } from '../../../../../../../shared/store/api/endpoints';

interface RoutineFilterProps {
  routines: Routine[];
  selectedRoutines: string[];
  setSelectedRoutines: (selectedRoutines: string[]) => void;
}
export function RoutineFilter({
  routines,
  selectedRoutines,
  setSelectedRoutines,
}: RoutineFilterProps) {
  function handleCheckboxChange(routineId: string) {
    if (selectedRoutines.includes(routineId)) {
      setSelectedRoutines(selectedRoutines.filter((s) => s !== routineId));
    } else {
      setSelectedRoutines([...selectedRoutines, routineId]);
    }
  }
  const routineSelectOptions = routines.map((routine) => ({
    name: routine.name,
    value: routine.id,
  }));
  return (
    <div className="flex max-h-[400px] flex-col overflow-y-scroll px-4">
      {routineSelectOptions.map((option) => (
        <div
          key={option.value}
          className="flex items-center justify-between py-2"
        >
          <label htmlFor={`status-${option.value}`} className="mr-2">
            {option.name}
          </label>
          <Checkbox
            inputId={`status-${option.value}`}
            className="mr-2"
            value={selectedRoutines.includes(option.value.toString())}
            checked={selectedRoutines.includes(option.value.toString())}
            onChange={() => handleCheckboxChange(option.value.toString())}
          />
        </div>
      ))}
    </div>
  );
}
